import api from "./instance";

/**
 * create lecture
 */
const create = (lecture) =>
  api.post(`/admin/lectures`, lecture).then((response) => response.data);

/**
 * update lecture
 */
const update = (lecture) =>
  api
    .put(`/admin/lectures/${lecture.id}`, lecture)
    .then((response) => response.data);

/**
 * update row order
 */
const updateRowOrder = (lecture) =>
  api
    .post(`/admin/lectures/update_row_order`, lecture)
    .then((response) => response.data);

/**
 * remove lecture
 */
const destroy = (lecture) =>
  api.delete(`/admin/lectures/${lecture.id}`).then((response) => response.data);

const show = () =>
  api.get(`/serializers/lectures`).then((response) => response.data);

const updateVideoProgress = (content_id, progress_time, block_forward) =>
  api
    .post(`/video_progresses`, {
      content_id,
      progress_time,
      block_forward,
    })
    .then((response) => response.data);

export default {
  create,
  update,
  updateRowOrder,
  destroy,
  show,
  updateVideoProgress,
};

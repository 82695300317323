<template>
  <div>
    <h1 class="text-xl">
      <a class="Btn Btn--Primary Btn--Icon mb-2 mr-4" href="/users/edit">
        <svg height="16" width="16" class="icon-arrow-left fill-current">
          <use xlink:href="#icon-arrow-left"></use>
        </svg>
      </a> {{t('pages.gamification.leaderboards.title.p')}}
    </h1>

    <div v-if="loading" class="LoadingBox mt-4 text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>

    <div v-else class="Form--Boxed mt-5">
      <div v-if="activeLeaderboards.length === 0" class="text-center">
        <h2 class="text-lg p-4">{{t('pages.gamification.leaderboards.no_active_leaderboards')}}</h2>
      </div>
      <div v-else class="mt-4 p-3">
        <ul class="List--Boxed">
          <li v-for="leaderboard in activeLeaderboards" :key="leaderboard.id" class="">
            <a :href="`/leaderboards/${leaderboard.id}`" class="flex justify-between items-center">
              <strong>{{ getTranslatedName(leaderboard) }}</strong>
              <button class="Btn Btn--Primary Btn--Icon">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: Number,
    leaderboards: Array,
    locale: String,
  },
  data() {
    return {
      loading: false,
      activeLeaderboards: [],
    };
  },
  mounted() {
    console.log('userId:', this.userId);
    console.log('leaderboards:', this.leaderboards);
    this.activeLeaderboards = this.leaderboards?.leaderboards?.filter(leaderboard => leaderboard.active);
    if (this.activeLeaderboards.length === 0) {
      console.log('Nessuna leaderboard attiva trovata');
    } else {
      console.log('activeLeaderboards:', JSON.stringify(this.activeLeaderboards, null, 2));
    }
  },
  methods: {
    getTranslatedName(leaderboard) {
      const translation = leaderboard.translations.find(t => t.language === this.locale);
      return translation && translation.name ? translation.name : leaderboard.name;
    }
  },
};
</script>
<template>
  <div class="PdfViewer">
    <div class="PdfViewer__Controls">
      <div class="PdfViewer__Controls__Inner inline-flex items-center p-1 sm:text-xl">
        <button v-if="page > 1" @click="page--" class="mr-6 fon font-bold flex items-center">
           <svg height="16" width="16" class="fill-current mr-2"> 
              <use xlink:href="#icon-arrow-left"></use>
            </svg>
            {{ t('pages.lecture.previous') }} 
          </button>
          <span class="font-bold">{{page}} / {{numPages}}</span>
        <button v-if="page < numPages" @click="page++" class="ml-6 font-bold flex items-center">
            {{ t('pages.lecture.next') }}  
            <svg height="16" width="16" class="fill-current ml-2"> 
              <use xlink:href="#icon-arrow-right"></use>
            </svg>
          </button>
      </div>
    </div>
    <pdf 
      :src="content.url"
      :page="page"
      @num-pages="numPages = $event, checkCompleted()" 
      style="width:100%"
    />

     <modal name="quiz-modal" class="v--modal--prevent-close">
      <div class="Modal__Body flex items-center justify-center h-full text-center p-5">
        <quiz :lectureQuiz="quiz" :course="course" :next="next" />
      </div>
    </modal>

    
  </div>
  
</template>

<script>
import pdf from 'vue-pdf'
import quiz from '../quiz/show'
import { mapState, mapActions, mapGetters } from 'vuex'
import { global as EventBus } from '../../js/utils/eventBus';
import api from '../../api'

export default {
  props: {
    content: Object,
    course: Object,
    next: String,
    quiz: Object,
    lecture: Object
  },
  data() {
    return {
      page: 1,
      numPages: 0,
    }
  },
  components: {
    pdf,
    quiz
  },
  mounted() {
    document.addEventListener("keyup", this.changeSlide);
    this.updateDuration()
  },
   computed: {
    ...mapGetters({
      quizResult: "quiz",
    })
  },
  methods: {
  	changeSlide () {
    	if (event.keyCode == 39 && (this.page < this.numPages) ) {
      	this.page++
      } else if (event.keyCode == 37 && this.page > 1 ) {
      	this.page--
      }
    },
    checkCompleted(){
      // if there is only 1 slide complete the lecture
      if (this.page === this.numPages) {
        this.$parent.completedLecture().then(res => {
          EventBus.emit('lecture:completed', {lectureId: this.next?.id})
        })
      }
    },
    updateDuration() {
     //every 5 seconds update the duration
      setInterval(() => {
       api.UserActivity.updateDuration(this.lecture.id, 5)
      }, 5000)
      

    }
  },
  watch: {
    page: function () {
      if (this.page === this.numPages) {
         if (!this.quiz || (this.quiz && this.quiz.questions.length === 0) ) {
           this.$parent.completedLecture().then(res => {
             EventBus.emit('lecture:completed', {lectureId: this.next?.id})
           })
         } else {
            this.$modal.show('quiz-modal', { width: '100%',maxWidth: '600px' });
         }
        
      }
    },
    quizResult: {
      handler(newVal, oldVal) {
        console.log(`Quiz passed: ${newVal.passed}. Old val: ${oldVal.passed}`)
        if (newVal.passed) {
          this.$parent.completedLecture()
        }
      },
      deep: true  
    }
  },
}
</script>
<template>
  <div>
    <h1 class="text-xl">
      <a class="Btn Btn--Primary Btn--Icon mb-2 mr-4" href="/users/edit">
        <svg height="16" width="16" class="icon-arrow-left fill-current">
          <use xlink:href="#icon-arrow-left"></use>
        </svg>
      </a>{{ t('pages.home.your_badge') }}
    </h1>
    <div v-if="loading" class="LoadingBox mt-4 text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-else class="Form--Boxed mt-5">
      <div v-if="badges.length === 0" class="text-center">
        <h2 class="text-lg p-4">{{ t('pages.gamification.badges.no_badge') }}</h2>
      </div>
      <div v-else class="mt-4 p-3">
        <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          <li v-for="badge in badges" :key="badge.id" class="flex flex-col items-center text-center mb-5">
            <div class="w-24 h-24 flex items-center justify-center">
              <img :src="badge.image || 'default-image.png'" alt="Badge Image" class="w-full h-full object-cover">
            </div>
            <div class="mt-5 w-24 h-8 flex items-center justify-center flex-col">
              <span class="mt-2"><strong>{{ badge.name || 'Nome non disponibileeeee' }}</strong></span>
              <span class="mt-1">{{ t('pages.prizes.points') }}: {{ badge.points || 'Punti non disponibili' }}</span>
            </div>
          </li>
        </ul>
        <div class="text-center mt-6">
          <paginate
            v-model="currentPage"
            :page-count="pagy.pages"
            :click-handler="onChangePage"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    initialPage: Number,
    userId: Number,
    badges: Array,
  },
  data() {
    return {
      badges: [],
      pagy: {},
      currentPage: this.initialPage,
      loading: false,
    };
  },
  mounted() {
    console.log('Component mounted');
    console.log('Props received:', this.$props);
    this.getBadges(this.initialPage);
  },
  methods: {
    getBadges(page) {
      this.loading = true;
      api.Badge.getBadges(this.userId, { page })
        .then(response => {
          console.log('API response:', response);
          this.badges = response.badges;
          this.pagy = response.pagy;
          console.log('Badges:', this.badges);
          console.log('Badges length:', this.badges.length);
          console.log('Pagy:', this.pagy);
          this.loading = false;
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
          this.loading = false;
        });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.getBadges(page);
    },
  },
};
</script>
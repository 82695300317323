<template>
  <div class="w-100">
    <div v-if="quiz.currentAttempt" class="QuizLecture">
      <div v-if="randomQuestion" class="QuizLecture__Item">
        <div class="QuizLecture__Question Box Box--Clear mb-4">
          <h2 class="mb-2">{{ t('pages.quiz.question') }}  </h2>
          <h3 class="text-2xl" v-html="randomQuestion.body"></h3>
        </div>
        <div class="QuizLecture__Answers Box Box--Clear">
          <h2>{{ t('pages.quiz.select_correct_answer') }}</h2>
          <ul class="mt-4 mb-4">
            <li v-for="(answer, index) in shuffleAnswers" :key="answer.id" >
              <button @click="selectAnswer(answer)" :class="{'QuizLecture__Answer': true, 'selected bg-primary': selectedAnswer === answer }">
                <span class="QuizLecture__Answer__Index">{{index+1}}</span>
                <span class="QuizLecture__Answer__Text"> {{ answer.body }}</span>
              </button>
            </li>
          </ul>
           <button @click="submitAnswer()" class="Btn Btn--Save">{{ t('pages.quiz.confirm_answer') }}</button>
        </div>
      </div>
    </div>

    <div v-else-if="quiz.completed || quizCompleted || quiz.passed" class="Box Box--Clear text-center">
      <p class="text-xl" v-if="quizCompleted.score >= 0">{{ t('pages.quiz.score') }}: 
        <span class="font-bold">
          {{ quizCompleted.score }}
        </span> 
      %</p>
      <div v-if="quiz.passed || (quizCompleted.passed) ">
        <template v-if="quiz.min_pass_score != '0'">
          <img src="../../images/success.png" class="m-auto" />
          <p class="text-xl font-bold  mt-8 mb-8">{{ t('pages.quiz.quiz_passed') }}</p>
        </template>
         <div v-if="completedCourse && completedCourse.unique_identifier && course.certification" class="mb-6">
           <h4 class="mb-2">{{ t('pages.lecture.course_completed') }}</h4>
           <a :href='`/certifications/${completedCourse.unique_identifier}`' class="Btn Btn--Primary">
            {{ t('pages.lecture.download_certification')}}
           </a>
         </div>
         <button v-if="quiz.repeatable" @click="restartQuiz()" class="Btn Btn--Save">{{ t('pages.quiz.restart') }}</button>
         <a v-if="next" :href="next.id" class="Btn Btn--Primary">{{ t('pages.lecture.go_to_next_lecture') }}</a>
      </div>
      <div v-else-if="quiz.completed || (!quizCompleted.passed) ">
        <img src="../../images/blocker.png" class="m-auto" />
        <p class="text-xl font-bold mt-8 mb-8">{{ t('pages.quiz.quiz_not_passed') }}</p>
        <button v-if="quiz.repeatable" @click="restartQuiz()" class="Btn Btn--Save">{{ t('pages.quiz.restart') }}</button>
        <a v-if="next && quiz.go_forward" :href="next.id" class="Btn Btn--Primary">{{ t('pages.lecture.go_to_next_lecture') }}</a>
      </div>
      <div class="text-left max-w-lg mx-auto mt-4" v-if="quiz.feedback">
        <p class="text-lg font-bold mb-2">{{ t('pages.quiz.correct_answers') }}</p>
        <ul>
          <li v-for="(question, index) in lectureQuiz.questions" :key="question.id">
           <div class="flex gap-1" v-html="`${index+1}. ${getQuestion(question).text}`"></div>
           <div class="pl-4 font-bold">{{ getQuestion(question).correctAnswer}} </div>
          </li>
        </ul>
      </div>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '../../api'

export default {
  props: {
    course: Object,
    lectureQuiz: Object,
    next: Object
  },

  data() {
    return {
      selectedAnswer: null,
      quizCompleted: {},
      completedCourse: {}
    }
  },

  mounted() {
    

    this.getAttempt(this.lectureQuiz)

    if (!this.lectureQuiz.currentAttempt && !this.lectureQuiz.completed) {
      this.createAttempt()
    }

    this.completedCourse = this.course.completedCourse

  },

  computed: {
    ...mapGetters({
      quiz: "quiz",
      questionsToBeAnswered: "questionsToBeAnswered",
    }),
    randomQuestion() {
      if (this.questionsToBeAnswered.length > 0) {
        return this.questionsToBeAnswered[Math.floor(Math.random() * this.questionsToBeAnswered.length)];
      }
    },
    shuffleAnswers(){
      if (this.randomQuestion) {
        return this.randomQuestion.answers.map(a => [Math.random(), a])
          .sort((a, b) => a[0] - b[0])
          .map(a => a[1]);
      }
    },
    
  },

  methods: {
    ...mapActions({
      getAttempt: 'getAttempt',
      updateCurrentAttempt: 'updateCurrentAttempt',
      updateCompletedAnswer: 'updateCompletedAnswer',
      resetCompletedQuestions: "resetCompletedQuestions",
      updateCompletedQuiz: "updateCompletedQuiz"
    }),

    getQuestion(question){
      return {
        text: question.body,
        correctAnswer: question.answers.find(a => a.correct).body
      }
    },
    createAttempt(){
      api.QuizAttempt.create( this.lectureQuiz )
      .then((res) => { 
        this.updateCurrentAttempt(res)
      })
    },
    selectAnswer(answer){
      this.selectedAnswer = answer
    },
    submitAnswer(){
      if (this.selectedAnswer) {
        api.QuizAttempt.createCompletedAnswer( this.selectedAnswer )
        .then((res) => { 
          console.log(res.quizComplete)
          if (res.quizComplete) {
            this.updateCurrentAttempt(false)
            this.updateCompletedQuiz(res.quizComplete.passed)
            this.quizCompleted = res.quizComplete
            this.completedCourse = res.completedCourse
          }
          this.updateCompletedAnswer(res)
        })
      }
    },
    restartQuiz(){
      this.createAttempt()
      this.resetCompletedQuestions()
      this.selectedAnswer = null
      this.quizCompleted = {}
    }
  }

}
</script>
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

global.toastr = require("toastr");

toastr.options = {
  closeButton: true,
  debug: false,
  positionClass: "toast-top-right",
  onclick: null,
  showDuration: "300",
  hideDuration: "1500",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

import "../stylesheets/application.scss";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

/* eslint no-console:0 */

import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue";
// import VueI18n from 'vue-i18n'

import "vue-progress-path/dist/vue-progress-path.css";
import VueProgress from "vue-progress-path";

import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VModal from "vue-js-modal";
// Import the store
import store from "../store";
// Import all the macro vue components of the application
import * as instances from "../vue/instances";

//Internationalization
import I18n from "i18n-js";
I18n.locale = window.locale;
I18n.translations = JSON.parse(
  document.getElementById("translations").dataset.translations
);

// Import js files
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import ActionCableVue from "actioncable-vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueTippy, { TippyComponent } from "vue-tippy";
import Paginate from "vuejs-paginate";
import vSelect from "vue-select";
import VueCookies from "vue-cookies";
import VueRouter from "vue-router";
import routes from "../vue/routes";
import JwPagination from "jw-vue-pagination";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import it from "vee-validate/dist/locale/it.json";
import * as rules from "vee-validate/dist/rules";
import { VueEditor, Quill } from "vue2-editor";
const VueUploadComponent = require("vue-upload-component");
import InstantSearch from "vue-instantsearch";
import VueToastr from "vue-toastr";

Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueProgress);
Vue.use(Chartkick.use(Chart));
Vue.use(VueToastr);

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: "/cable",
  connectImmediately: true,
});
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dialog: true,
});
Vue.use(TurbolinksAdapter);
// Vue.use(VueI18n)
Vue.use(Datetime);
Vue.component("datetime", Datetime);
Vue.use(require("vue-moment"));
Vue.use(VueTippy);
Vue.use(VueCookies);

Vue.use(InstantSearch);

Vue.component("tippy", TippyComponent);
Vue.component("paginate", Paginate);
Vue.component("jw-pagination", JwPagination);
Vue.component("v-select", vSelect);

//Validator
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
localize("it", it);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component("file-upload", VueUploadComponent);

Vue.config.silent = true;

Vue.mixin({
  methods: {
    t: (...args) => I18n.t(...args),
    currentLocale: () => I18n.currentLocale(),
  },
});

document.addEventListener("turbolinks:load", () => {
  // Initialize available instances
  Object.keys(instances).forEach((instanceName) => {
    const instance = instances[instanceName];
    const elements = document.querySelectorAll(instance.el);

    elements.forEach((el) => {
      const props = JSON.parse(el.getAttribute("data-props"));
      if (el.className === "vue-admin-academy-settings-index") {
        Vue.use(VueRouter);
        const router = new VueRouter({
          routes,
        });
        new Vue({
          el,
          store,
          router,
          render: (h) => h(instance.component, { props }),
        });
      } else {
        new Vue({ el, store, render: (h) => h(instance.component, { props }) });
      }
    });
  });
});

import "../js/index";

import api from './instance'

/**
* update style
*/
const update = (page, body) => (
  api.put(`/admin/pages/${page.id}`, { body: body })
    .then(response => response.data)
)

export default {
  update
}
<template>
  <div class="CoursePathThumbVertical" :id="`course_${course.id}`">
    <div :class="['CoursePathThumbVertical__Top flex justify-between items-center', {'CoursePathThumbVertical__Top--Locked': !course.canShow}]" :style="courseImage"></div>
    <div class="CoursePathThumbVertical__Bottom p-3 bg-black text-white">
      <h4 class="text-lg text-left mb-5">{{course.name}}</h4>
      <div class="flex items-center justify-between">
        <div class="CoursePathThumbVertical__Progress">
          <template v-if="course.progress_status">
            <span :class="['font-bold text-lg mr-2', {'text-success' : course.percentage_value> 0}]">{{ t(`pages.course.${course.progress_status}`)}}</span>
          </template>
          <template v-else>
            <span :class="['font-bold text-lg mr-2 text-sm', {'text-success' : course.percentage_value> 0}]" v-if="course.show_percentage">{{course.percentageCompleted}}</span>
          </template>
        </div>
        <a  v-if="!notEnrolled && course.canShow" :href="`${course.nextLecture}`" class="Btn Btn--Success Btn--Arrow js-cookie-helper" data-cookie-name="related_path"  :data-cookie-value="pathId">
            {{ t(courseCta()) }}
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-arrow-right"></use>
            </svg>
        </a>
        <button v-else class="cannot-view Btn Btn--Disabled Btn--Arrow" :content="notEnrolled ? t('pages.course.enroll_to_view') : t('pages.course.lecture_blocked')" v-tippy>{{ t(courseCta()) }}  
          <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-arrow-right"></use>
          </svg>
        </button>
        <a :href="`/certifications/${course.completedCourse.unique_identifier}`" v-if="course.completedCourse && course.certification && course.percentage_value === 100" class="Btn Btn--Text" target="_blank">
          <span class="hidden sm:block">{{ t('pages.home.certification')}}</span>
          <svg height="16" width="16" class="fill-current ml-2"> 
            <use xlink:href="#icon-download"></use>
          </svg>
        </a>
        <a v-if="course.show_comments" :href="`/courses/${course.id}/forum`" class="Btn Btn--Text">Forum</a>
      </div>
    </div>
  </div>
   

</template>

<script>

import progressBar from './progressBar'

export default {

  components: {
    progressBar
  },

  props: {
    course: Object,
    pathId: String,
    notEnrolled: Boolean
  },

  data() {
    return {
      thumbOpen: true,
      
    }
  },


  computed: {
    courseDuration() {
      let totalMinutes = this.course.duration
      const minutes = totalMinutes % 60;
      const hours = Math.floor(totalMinutes / 60);
      return `${hours}h:${minutes}m`
    },

    courseImage() {
      //make a purple like gradient
      return this.course.image_url ? `background-image: url(${this.course.image_url})` : 'background: linear-gradient(90deg, rgb(108, 93, 211) 0%, rgb(162 156 203) 100%)' 
    },
  },


  methods: {
    shortDescription(description) {
      if (description) {
        if (description.length > 50) {
          return `${description.substr(0, 50)}...`
        } else {
          return description
        }
      } 
      return ''
    },

    courseCta() {
      switch(this.course.percentage_value) {
          case 0:
            return 'actions.start';
            break;
          case 100:
            return 'actions.restart';
            break;
          default:
            return 'general.continue'
      }
        
    },
  }
}
</script>

<style lang="scss" scoped>

  .ProgressBar {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background: var(--gray-light);
    overflow: hidden;
    &__Completed {
      background-color: #2AC76F;
      height: 100%;
    }
  }


</style>
<template>
<div :class="['LectureAdmin', {'flex flex-col': onlyQuiz}]">
  <div class="flex items-center justify-between mb-4">
    <BackButton :href="`/admin/courses/${course.id}/edit`" :cssClass="'mr-4'" />
    <h4 class="text-xl">{{ course.name }}</h4>
    <a class="Btn Btn--Primary" :href="`/courses/${course.id}/lectures/${lecture.id}?preview=true`" target="_blank">Preview</a>
  </div>
  <div class="Form--Boxed mb-4">
    <div class="field">
       <label>{{t('global.name')}}</label>
        <input 
        v-model="lecture.name"
        class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
        type="text" 
        placeholder="Lecture Name" 
      />
    </div>
    <div class="field" v-if="lecture.live">
      <datetime 
        type="datetime"
        v-model="lecture.start_date">
      </datetime>
    </div>
    <div class="field" v-if="!onlyQuiz && !lecture.header">
      <label>{{t('pages.lecture.notes')}}</label>
      <vue-editor v-model="lecture.description" :editorToolbar="customToolbar"></vue-editor>
    </div>
    <div class="field" v-if="lecture.live">
      <div class="flex items-center gap-2 mb-2">
        <label for="perc_completion" class="mb-0">{{t('pages.lecture.perc_completion')}}</label>
        <button :content="t('pages.lecture.perc_completion_hint')" v-tippy class="Btn--Tooltip">
          <svg height="18" width="18" class="fill-current"> 
            <use xlink:href="#icon-information-outline"></use>
          </svg>
        </button>
      </div>
      <input 
        v-model="lecture.perc_completion"
        id="perc_completion"
        class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
        type="number" 
        placeholder="i.e. 80" 
      />
    </div>
    <button 
      class="Btn Btn--Save mb-4"
      @click="updateLecture()" >
      {{ t(`actions.${cta}`) }}
    </button> 
  </div>

    <template v-if="!scormLecture">
      
      <div :class="onlyQuiz ? 'order-1' : ''">
        <editLecture 
          v-if="!lecture.header" 
          :course="course" 
          :lecture="lecture" 
          :room="room" 
          :recordings="recordings" 
          :multipleVideosEnabled="multipleVideosEnabled" 
        />
      </div>

      <quiz v-if="lectureQuiz && lectureQuiz.status !== 'no-quiz-found'" :quiz="lectureQuiz" />

      <div class="Form--Boxed" v-if="(!lectureQuiz || lectureQuiz.status === 'no-quiz-found') && !lecture.header">
        <div class="Form--InnerSection mt-6">
          <div class="field">
            <label class="mb-2">{{t('pages.course.create_quiz')}}</label>
            <input 
              v-model="newQuiz.name"
              @keyup.enter="createQuiz(newQuiz)"
              class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
              type="text" 
              :placeholder="t('pages.course.quiz_name')" 
            />
            </div>
            <div class="field field--checkbox flex items-center">
              <input type="checkbox" v-model="newQuiz.repeatable"> 
              <label class="ml-3">{{t('pages.course.quiz_repeatable')}}</label>
            </div>
            <div class="field">
              <label>{{t('pages.course.min_quiz_score')}}</label>
              <input v-model="newQuiz.min_pass_score" type="number" @input="validatePercentageNumber()" > 
            </div>
          <button 
            class="Btn Btn--Save mt-4"
            @click="createQuiz(newQuiz)" >
            {{t('actions.save')}}
          </button>  
        </div>
      </div>

    </template>

   
   

</div>

</template>

<script>
import api from '../../../api'
import editLecture from '../lecture/editLecture'
import quiz from '../quiz/show'
import BackButton from '../../commons/backButton'
import { mapState, mapActions, mapGetters } from 'vuex'
import { global as EventBus } from '../../../js/utils/eventBus';


import { VueEditor } from "vue2-editor";


export default {
  props: {
    course: Object,
    lecture: Object,
    quiz: Object,
    room: Object,
    recordings: Object,
    multipleVideosEnabled: Boolean
  },
  components: {
    editLecture,
    quiz,
    BackButton,
    VueEditor
  },
  data() {
    return {
      cta: "save",
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
      newQuiz: {
        name: '',
        repeatable: true,
        min_pass_score: 70,
        lecture_id: this.lecture.id
      }
    }
  },
  computed: {
     ...mapState({
      lectureQuiz: state => state.lecture.lectureQuiz
    }),
    onlyQuiz() {
      return this.lecture.contents.length === 0 && !!this.quiz
    },
    scormLecture() {
      return this.lecture.lecture_type_id === 4
    }
  },
  mounted() {
    this.getQuiz()
  },
  methods: {
    ...mapActions({
      createLectureQuiz: 'createLectureQuiz',
      renderQuiz: 'renderQuiz'
    }),

    getQuiz() {
      console.log(this.lecture.id)
      api.Quiz.getQuiz(this.lecture.id)
        .then(res => {
          this.renderQuiz(res)
        })
    },

    updateLecture(){
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Lecture.update(this.lecture)
        .then((res) => {
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
          this.cta = "saved"
          setTimeout(function () {
            this.cta = "save"
          }, 3000);
        })
        .catch(e =>{
          this.cta = "error"
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    createQuiz(quiz){
      quiz.course_id = this.course.id
      api.Quiz.create( quiz )
      .then((res) => { 
        this.createLectureQuiz(res)
      })
    },

    validatePercentageNumber() {
      if (this.newQuiz.min_pass_score < 0 || this.newQuiz.min_pass_score > 100 ) {
        if (this.newQuiz.toString().length > 3 ) {
          this.newQuiz.min_pass_score = Math.floor( this.newQuiz.min_pass_score / 10)
        }
      }
    },
    
  }
}
</script>

import api from './instance';

const saveBadges = (id, badgeIds) =>
  api
    .post(`/admin/gamification/leaderboards/${id}/badges`, { badge_ids: badgeIds })
    .then((response) => response.data);

const getSelectedBadges = (id) => {
  console.log("ID della leaderboard:", id);
  return api
    .get(`/admin/gamification/leaderboards/${id}/selected_badges`, { params: { id } })
    .then((response) => response.data)
    .catch((error) => {
      console.error("AAA Errore durante il caricamento dei badge selezionati:", error.response ? error.response.data : error);
      throw error;
    });
};

const removeBadges = (id, badgeIds) => {
  console.log("Rimozione badge per leaderboard:", id, "Badge IDs:", badgeIds); // Log per debug
  return api
    .post(`/admin/gamification/leaderboards/${id}/remove_badges`, { badge_ids: badgeIds })
    .then((response) => response.data);
};
    
export default {
  saveBadges,
  getSelectedBadges,
  removeBadges
};
<template>
  <div>
    <p class="mb-4">
      <strong>Microsoft Teams meeting link:</strong>
      <a :href="`${content.content_code}`" target="_blank">
        {{ content.content_code }}
      </a>
    </p>
    <a :href="`${content.content_code}`" class="Btn Btn--Primary" target="_blank">Start Meeting</a>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  props: {
    lecture: Object,
    content: Object
  },
  data() {
    return {
      timeToStart: Number
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
  },
  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
  }
}
</script>
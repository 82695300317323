<template>
  <div>
    <div class="field">
      <p>
        Per abilitare il meeting con Zoom, puoi inserire il meeting ID qui sotto e lo studente verrà reindirizzato a Zoom. 
        <br/>
        Se vuoi annullare il meeting zoom ti bastera eliminare il meeeting ID qui sotto.
      </p>
      <input 
      v-model="content.content_code" 
      class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
      type="text"
      placeholder="Inserisci ZOOM Meeting ID " />
    </div>
    <button 
      class="Btn Btn--Save"
      @click="updateContent(content)" >
      Save
    </button> 

    <span v-if="content.content_code">
      <a :href="`https://us02web.zoom.us/j/${content.content_code}`" class="Btn Btn--Primary" target="_blank">Start Meeting</a>
    </span>

  </div>
</template>

<script>
import api from '../../../api'
export default {
  props: {
    content: Object
  },
  methods: {
    updateContent(){
      this.content.content_code = this.content.content_code.replace(/\s/g, '')
      api.Content.update(this.content)
      .then( () =>{
        location.reload
      })
    }
  }
}
</script>
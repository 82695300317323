import api from './instance'

/**
* invite user
*/
const update = (userField) => (
  api.put(`/user_fields/${userField.id}`, userField )
    .then(response => response.data)
)



export default {
  update
}
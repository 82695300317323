import academySetting from "./academySetting";
import course from "./course";
import lecture from "./lecture";
import quiz from "./quiz";
import quizAttempt from "./quizAttempt";
import user from "./user";
import general from "./general";
import area from "./area";
import comment from "./comment.js";

export default {
  academySetting,
  course,
  lecture,
  quiz,
  quizAttempt,
  user,
  general,
  area,
  comment,
};

<template>
  <div>
    <div class="flex mb-4">
      <BackButton :href="`javascript:history.go(-1)`" :cssClass="'mr-4'" />
      <h4 class="text-xl">{{course.name}} - {{ user.firstName}} {{ user.lastName }}</h4>
    </div>

    <ul class="List--Boxed mt-4">
      <li class="grid grid-cols-4	">
        <div class="UserList__Item__Name col-span-2">
          <b>{{t('global.lectures.s')}}</b>
        </div>
        <div>
          <b>{{t('global.completed.f')}}</b>
        </div>
        <div class="text-right">
          <b>{{t('global.actions')}}</b>
        </div>
      </li>

    <li v-for="lecture in lectures" :key="lecture.id" :class="['grid grid-cols-4', {'bg-grayScale-light': lecture.header}]">
      <div class="col-span-2">
        <strong v-if="lecture.header"> {{ lecture.name }}</strong>
        <span v-else> {{ lecture.name }} </span>
      </div>
      <span v-if="!lecture.header">{{ lecture.completed  ? '✔' : 'No' }}</span>
      <div class="text-right" v-if="!lecture.header">
        <!-- TODO: add activity -->
        <a 
          @click="completeLecture(user, lecture)" 
          class="Btn Btn--Primary Btn--Icon">
          {{t('actions.mark_as_completed')}}
        </a>
      </div>
    </li>
  </ul>


  </div>
</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import { global as EventBus } from '../../../js/utils/eventBus'; 


export default {
  props: {
    lectures: Array,
    user: Object,
    course: Object
  },
  components: {
    BackButton,
  },
  data(){
    return {
    }
  },

  mounted() {
    
    console.log(this.lectures)
  },
  computed: {
  
  },
  methods: {
   

    totDuration(totSecs) {
      let totalSeconds = totSecs || 0
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },

    completeLecture(user, lecture) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      console.log(this.user, lecture)
      api.CompletedLecture.markAsCompleted( lecture.id, this.user.id )
      .then((res) => { 
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        setTimeout(() => {
          location.reload()
        }, 800); 
      }).catch(err => {
        EventBus.emit('loader:local:end', { elem: target })
        this.$toastr.e(this.t('actions.error'));
      })
    }

  }
}
</script>
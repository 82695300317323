<template>
  <div>
    <div class="flex flex-wrap gap-2 justify-between mb-4">
      <h2 class="text-2xl">{{t('global.stats')}} {{t('pages.groups.title.p')}}</h2>
    </div>

    <div class="Table__Container">
      <table class="Table table-auto">
        <thead>
          <tr>
            <th class="text-left">{{t('pages.groups.title.s')}}</th>
          </tr>
        </thead>
        <tbody v-if="!loading.list">
          <tr v-for="group in paginatedGroups" :key="group.id">
            <td class="">
              <a :href="`/stats/general_info?group_id=${group.id}`" class="block w-full">
                {{ group.name }}
              </a>
              </td>
            <td class="text-right">
              <a :href="`/stats/general_info?group_id=${group.id}`" class="Btn Btn--Primary">
                {{t('pages.groups.go_to_stats')}}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center mt-4">
      <paginate
        v-model="currentPage"
        :page-count="pageCount"
        :click-handler="onChangePage"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'PaginationList'">
      </paginate>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: this.initialPage,
      loading: false,
    };
  },
  computed: {
    paginatedGroups() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.groups.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.groups.length / this.itemsPerPage);
    },
  },
  methods: {
    onChangePage(page) {
      this.currentPage = page;
    },
  },
};
</script>
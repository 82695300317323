<template>
  <div :class="[{'Comments__Header--New': reply }, {'Box Box--Clear mb-4': !reply },'Comments__Header ']">
    <textarea type="text" v-model="comment.body" ></textarea>
    <div>
      <div class="file-upload mt-2 mb-2">
        <input type="file" ref="fileInput" @change="handleFileUpload" multiple style="display: none;">
        <button class="flex items-center gap-2" @click="$refs.fileInput.click()" :disabled="files.length >= 3">
          <svg height="14" width="14" class="fill-current"> 
            <use xlink:href="#icon-attachment"></use>
          </svg>
          <strong>{{ t('pages.comments.attach') }}</strong>
        </button>
        <p class="mt-2" v-if="files.length > 0">{{ files.length }} {{ t('pages.comments.files_attached') }}</p>
      </div>
      <div v-if="files.length > 0" class="file-preview mt-2 mb-2">
        <div v-for="(file, index) in files" :key="index" class="file-item">
          <span>{{ file.name || file.filename }}</span>
          <button class="Btn Btn--Icon Btn--Delete ml-2" @click="removeFile(index)">
            <svg height="14" width="14" class="fill-current"> 
                <use xlink:href="#icon-close"></use>
              </svg>
          </button>
        </div>
      </div>
    </div>
    <button  class="Btn Btn--Save" @click="updateComment()">{{t('actions.save')}}</button>
    <button class="Btn Btn--Delete" @click="$parent.closeEditComment()">{{t('actions.undo')}}</button>
  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    comment: Object,
  },

  data() {
    return {
      loading: false,
      files: [],
      deletedFileIds: [], // New array to track deleted file IDs
    }
  },

  mounted() {
    console.log(this.comment);
    this.files = this.comment.attachments.map(attachment => ({
      ...attachment,
      id: attachment.id, // Ensure each file has an id if it's from the backend
    }));
  },

  methods: {
    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      const availableSlots = 3 - this.files.length;
      const filesToAdd = newFiles.slice(0, availableSlots);
      this.files = [...this.files, ...filesToAdd];
      
      if (newFiles.length > availableSlots) {
        this.$toastr.w(`Only ${availableSlots} file(s) added. Max limit reached.`);
      }
    },

    removeFile(index) {
      const removedFile = this.files[index];
      if (removedFile.id) {
        // If the file has an ID, it's from the backend, so we track it for deletion
        this.deletedFileIds.push(removedFile.id);
      }
      this.files.splice(index, 1);
    },

    updateComment() {
      const formData = new FormData();
      formData.append('comment[id]', this.comment.id);
      formData.append('comment[body]', this.comment.body);
      
      // Append the IDs of files to be deleted
      this.deletedFileIds.forEach(id => {
        formData.append('comment[deleted_attachment_ids][]', id);
      });

      // Append only new files or files without IDs
      this.files.forEach((file, index) => {
        if (!file.id) {
          formData.append(`comment[attachments][]`, file);
        }
      });

      api.Comment.update(this.comment.id, formData)
        .then(res => {
          console.log(res);
          this.$parent.getComments();
          this.$parent.closeEditComment();
          this.files = [];
          this.deletedFileIds = []; // Reset deleted file IDs
        })
        .catch(err => {
          this.$toastr.e(err);
        });
    }
  }
}
</script>


<style scoped>
.file-preview {
  display: flex;
  flex-wrap: wrap;
}

.file-item {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

</style>
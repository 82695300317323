  <template>
    <div class="Path">
      <div class="Path__Hero mb-6">
        <div class="Path__Hero__Main">
          <h1 class="text-xl  mb-4">{{course.name}}</h1>
          <p v-if="course.subtitle" class="mb-6" v-html="course.subtitle" />
          <template v-if="course.live_code">
            <p>Il corso si terrà il  
              <span><strong>{{course.start_date | moment("DD/MM/YYYY ") }} alle {{course.start_date | moment("kk:mm") }}</strong></span>
            </p>
          </template>
          <template v-else>
            <h2 class="mb-1 text-lg">{{t('pages.course.your_progress')}}:</h2>
            <div class="Path__Hero__Progress">
              <div class="mr-6">
                <template v-if="course.progress_status">
                  <span>{{ t(`pages.course.${course.progress_status}`)}}</span><span></span>
                </template>
                <template v-else>
                  <span> {{course.percentageCompleted}}</span><span>{{t('pages.course.perc_completed')}}</span>
                </template>
              </div>
              <div v-if="!course.progress_status">
                <span>{{course.completedLectures.length}}/{{course.totLectures.length}}</span><span>{{t('pages.course.lectures_completed')}}</span>
              </div>
            </div>
            <div class="mt-4">
              <a v-if="commentsEnabled" :href="`/courses/${course.id}/forum`" class="Btn Btn--Text">{{t('pages.course.go_to_forum')}}</a>
            </div>
          </template>
        </div>
        <div :class="['Path__Hero__Secondary sm:pl-4', {'bg-image-sq': !course.image_url, 'course-image': course.image_url}]" :style="`background-image: url(${course.image_url})`">
          <div v-if="course.duration" class="Box__BgTitle mb-4">
           <h4 class="pr-4">{{courseDuration}}</h4>
           <svg height="16" width="16" class="fill-current Boxed"> 
              <use xlink:href="#icon-time"></use>
           </svg>
          </div>
          <div v-if="!course.live_code && course.totLectures.length > 1" class="Box__BgTitle mb-4">
            <h4 class="pr-4">{{course.totLectures.length}} {{t('pages.course.total_lectures')}}</h4>
            <svg height="16" width="16" class="fill-current Boxed"> 
                <use xlink:href="#icon-play-outline"></use>
            </svg>
          </div>
          <template v-if="notEnrolled && !courseNotAvailable">
            <a :href="`${startCourseUrl}?success_message=true`" class="Btn Btn--Primary Path__Hero__Secondary__Go">
            {{ t(`actions.enroll`) }}
            </a>
          </template>
          <template v-else>
            <a v-if="course.live_code && !webinarEnded" class="Btn Btn--Primary Path__Hero__Secondary__Go" :href="`/zoom/join-lac-live?webinar_id=${course.live_code}`">{{t('actions.join')}}</a>
            <a v-if="!course.live_code && !courseNotAvailable" :href="startCourseUrl" class="Btn Btn--Primary Path__Hero__Secondary__Go">
            {{ t(`actions.${course.percentage_value > 0  || course.progress_status == 'in_progress' ? 'continue' : 'start'}`) }}
            </a>
          </template>
        </div>
      </div>
      <div class="Box Box--Clear mb-8"  v-if="course.description">
        <button class="flex items-center Btn Btn--Text" @click="descriptionOpen = !descriptionOpen">
          <span>{{t('pages.course.about_course')}}</span>
          <svg height="24" width="24" class="fill-current Boxed"> 
              <use :xlink:href="`#icon-cheveron-${descriptionOpen ? 'up' : 'down'}`"></use>
          </svg>
        </button>
       <transition name="slide">
          <div v-if="descriptionOpen">
            <div class="mb-6 RichText mt-4" v-html="course.description" />
          </div>
         </transition>
      </div>
      <div v-if="course.live_code" >
        <countdown  :time="new Date(course.start_date).getTime() - new Date().getTime()" >
          <template slot-scope="props">
            <div class="CountDown">
            <div id="timer">
              <div class="days"> <div class="numbers">{{ props.days }}</div>D</div> 
              <div class="hours"> <div class="numbers">{{ props.hours }}</div>H</div> 
              <div class="minutes"><div class="numbers"> {{ props.minutes }}</div>M</div> 
              <div class="seconds"><div class="numbers">{{ props.seconds }}</div>S</div> </div>
              <h3 class="text-2xl mt-8">LIVE: {{course.start_date | moment("DD/MM/YYYY  -  kk:mm") }}</h3>
            </div>
          </template>
        </countdown>
       
        <div class="flex justify-center mt-3"  v-if="timeToStart > 43200">
          <add-to-calendar-button
            :name="course.name"
            :description="course.subtitle"
            :startDate="dates.startDate"
            :endDate="dates.endDate"
            :startTime="dates.startTime"
            :endTime="dates.endTime"
            :label="`${t('actions.add_to_calendar')}!`"
            :location="`${location}${course.url}`"
            options="['Google','Outlook.com', 'MicrosoftTeams']"
            timeZone="Europe/Rome"
            trigger="hover"
            inline
            listStyle="dropdown"
            language="it"
          >
          </add-to-calendar-button>
        </div>

        <div class="mt-4" v-if="notEnrolled">
          <a :href="`${startCourseUrl}?success_message=true`" class="Btn Btn--Primary Path__Hero__Secondary__Go mx-auto">
          {{ t(`actions.enroll`) }}
          </a>
        </div>
        <div class="text-center mt-4 Box Box--Clear max-w-xl mx-auto" v-else-if="webinarEnded">
          <p class="text-xl"><strong>Il webinar è terminato</strong></p>
          <p class="text-lg"><strong>A breve troverai la registrazione all'interno del catalogo!</strong></p>
        </div>
        <div class="text-center mt-4" v-else>
          <h4 v-if="timeToStart < 0" class="text-2xl mb-4">{{t('pages.lecture.webinar_running')}}</h4>
          <h4 v-else-if=" timeToStart < 3600" class="text-2xl mb-4">{{t('pages.lecture.webinar_beginning')}}</h4>
          <p class="mb-4">{{t('pages.lecture.join_instructions')}}</p>
          <a :href="`/zoom/join-lac-live?webinar_id=${course.live_code}`" class="Btn Btn--Primary">{{t('actions.join')}}</a>
        </div>

      </div>
      <div v-else>
        <div v-if="courseNotAvailable">
          <div class="Path__Courses pb-8 ml-auto mr-auto">
            <div class="Box Box--Clear CourseItem">
              <p v-if="courseNotStarted" class="font-bold mb-4 text-center text-lg">{{t('pages.course.course_will_be_available')}}  {{course.start_date | moment("DD/MM/YY - k:mm") }}</p>
              <p v-else-if="courseEnded" class="font-bold mb-4 text-center text-lg">{{t('pages.course.course_not_available_anymore')}}</p>
              <img src="../../images/illustrations/not-available.png" class="w-full max-w-sm mx-auto"/>
              <!-- <p class="text-center">Potrai iniziarlo il {{course.start_date | moment("DD/MM/YY - k:mm") }}</p> -->
            </div>
          </div>
        </div>
        <ul v-else class="Path__Courses pb-8 ml-auto mr-auto">
          <li v-for="item in course.lectures" :key="item.id" :class="{Lecture: !item.header, LectureHeader: item.header}">
            <p  class="font-bold mb-4 text-center text-lg" v-if="item.header">{{item.name}}</p>
            <button v-else-if="!item.canShow" class="cannot-view" :content="t('pages.course.lecture_blocked')" v-tippy>{{item.name}}</button>
            <template  v-else>
              <p v-if="notEnrolled"> {{item.name}} </p>
              <a v-else :href="`lectures/${item.id}`" :class="[{completed: item.completed || item.percentage_value === 100}]">
                <span>{{item.name}}</span>
                <div>
                  <b  v-if="item.live && item.start_date">{{item.start_date | moment("D/M/YY k:mm") }}</b>
                  <span class="Btn Btn--Primary Btn--Icon ml-4">
                    <svg height="16" width="16" class="fill-current"> 
                      <use xlink:href="#icon-arrow-right"></use>
                    </svg>
                  </span>
                </div>
              </a> 
            </template>
          </li>
        </ul>
        
      </div>

      
    </div>

</template>

<script>


import sidebar from '../commons/sidebar'
import coursePathThumbnail from '../commons/coursePathThumbnail'
import 'add-to-calendar-button'
import moment from "moment";



export default {
  name: 'CoursePage',

  components: { 
    sidebar,
    coursePathThumbnail
  },

  data() {
    return {
      isOpen: window.innerWidth < 768 ? false : true,
      descriptionOpen: false,
      location: window.location.origin,
      timeToStart: String
    }
  },

  props: {
    currentUser: Object,
    course: Object,
    logo: String,
    tenant_name: String,
    notEnrolled: Boolean,
    commentsEnabled: Boolean
  },

  channels: {
    
  },

  computed: {

    dates() {
      return {
        startDate:  moment(this.course.start_date).format('YYYY-MM-DD'),
        startTime:  moment(this.course.start_date).format('HH:mm'),
        endDate:  moment(this.course.start_date).format('YYYY-MM-DD'),
        endTime:  moment(this.course.start_date).add(60, 'minutes').format('HH:mm')
      }
    },

    webinarEnded() {
      return this.timeToStart < -7200
    },
    

    end_date() {
      const start_date = new Date(this.course.start_date)
      return new Date(start_date.getTime() + 60*60000);
    },

    courseDuration() {
      const totSeconds = this.course.duration;
      let seconds = totSeconds % 60;
      let minutes = Math.floor(totSeconds / 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60; // get the remainder of minutes
      if (seconds >= 30) {
        minutes += 1;
      }
      return `${hours}h: ${minutes}m`
    },

    startCourseUrl() {
      if (this.notEnrolled) {
        return `/enrolls/${this.course.uuid}`
      } else {
        return this.course.nextLecture
      }
    },

    courseNotAvailable() {
      return !this.course.live_code && ((this.course.start_date && new Date(this.course.start_date) > new Date()) || ( this.course.end_date && new Date(this.course.end_date) < new Date()))
    },
    

    courseNotStarted() {
      return !this.course.live_code && (this.course.start_date && new Date(this.course.start_date) > new Date())
    },

    courseEnded() {
      return !this.course.live_code && (this.course.end_date && new Date(this.course.end_date) < new Date())
    },


    

  },

  mounted() {
    this.updateTimeToStart();
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);

  },
  

  methods: {

    isCourse(el) {
      return el.course
    },

    updateTimeToStart(){
      this.timeToStart = ((new Date(this.course.start_date) - Date.now()) / 1000)
    },
    

  }
}

</script>
<style lang="scss" scoped>
  .LecturePage__Toggle {
    right: -38px;
    .open & {
      right: 20px;
    }
  }

  .atcb-button {
    font-family: "Nunito", sans-serif;
  }

  
</style>

<template>
  <div>
    <pie-chart :donut="true" :data="chartData" legend="right" height="200px" :colors="[styleData.color_primary, styleData.color_thumbnail]" ></pie-chart>
  </div>
</template>

<script>
export default {
  props: {
    chartData: Array,
    styleData: Array
  },
}
</script>

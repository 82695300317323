<template>
  <div class="LecturePage__Description" ref="description">
    <div v-if="html" v-html="html"></div>
    <div v-if="text"> {{text}} </div>
  </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    text: String,
    html: String,
  }
}
</script>

<template>
  <div>
    <countdown 
      v-if="timeToStart > 0 && !room.meeting_info.end_datetime"
     :time="new Date(lecture.start_date).getTime() - new Date().getTime()">
    <template slot-scope="props">
      <div class="CountDown">
      <div id="timer">
        <div class="days"> <div class="numbers">{{ props.days }}</div>D</div> 
        <div class="hours"> <div class="numbers">{{ props.hours }}</div>H</div> 
        <div class="minutes"><div class="numbers"> {{ props.minutes }}</div>M</div> 
        <div class="seconds"><div class="numbers">{{ props.seconds }}</div>S</div> </div>
        <h3 class="text-2xl mt-8">LIVE: {{lecture.start_date | moment("D/M/YY k:mm") }}</h3>
      </div>
    </template>
    </countdown>

    <div v-if=" timeToStart < 300 && !room.meeting_info.end_datetime" class="text-center">
      <h4 v-if="timeToStart < 0" class="text-2xl mb-4">Meeting in corso</h4>
      <h4 v-else class="text-2xl mb-4">Il Meeting sta per iniziare</h4>
      <p class="mb-4">Per partecipare clicca il bottone di seguito</p>
      <a :href="`/rooms/${room.id}`" class="Btn Btn--Primary">Partecipa</a>
    </div>


    <div v-if="recordings " class="IframeContainer">
     <iframe :src="recordings.playback.format.url" height="100%" width="100%"></iframe>
    </div>

    <div v-if="!recordings && room.meeting_info.end_datetime" class="text-center">
      <h4 class="text-2xl mb-4">Il meeting è terminato</h4>
    </div>

  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    lecture: Object,
    room: Object,
    recordings: Object
  },
  data() {
    return {
      timeToStart: Number
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
    if (this.room.meeting_info && this.room.meeting_info.end_datetime && this.recordings) {
      this.completeLecture()
    }
  },
  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
    completeLecture(){
      api.CompletedLecture.create(this.lecture.id)
    }
  }
}
</script>
<template>
  <div>
    
    <div v-if=" timeToStart < 600 && !room.meeting_info.end_datetime" class="text-center">
      <h4>The meeting is ready to be started with SkillsInCloud. The meeting will {{ room.room_settings.record ? 'BE RECORDED' : 'NOT BE RECORDED'  }}</h4>
      <a :href="`/rooms/${room.id}`" class="Btn Btn--Primary">Start Meeting</a>
    </div>


    <div v-else-if="recordings" class="IframeContainer">
     <iframe :src="recordings.playback.format.url" height="100%" width="100%"></iframe>
    </div>

    <div v-else-if="!recordings && room.meeting_info.end_datetime" class="text-center">
      <h4 class="text-2xl mb-4">Meeting ended</h4>
    </div>

    <div v-else>
      <p>You will be able to start the meeting 5m before the selected start date</p>

      <p><b>You can modify the meeting settings till 5m minutes before the meeting selected start date. </b></p>

      <div class="field flex items-center field--checkbox">
        <input type="checkbox" v-model="room.room_settings.record">  
        <label class="mr-4">Record meeting</label>
      </div>

      <a @click="saveRoom()" class="Btn Btn--Save mb-4">{{t('actions.save')}}</a>


    </div>

  </div>
</template>

<script>
import api from '../../../api'

export default {
  props: {
    lecture: Object,
    room: Object,
    recordings: Object
  },
  data() {
    return {
      timeToStart: Number
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
  },
  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
    saveRoom(){
      console.log(this.room)
      api.Room.update(this.room)
      
    }
  }
}
</script>
<template>
  <div>
    <div class="flex mb-4">
      <BackButton :href="`javascript:history.go(-1)`" :cssClass="'mr-4'" />
      <h4 class="text-xl">{{course.name}} - {{ user.firstName}} {{ user.lastName }}</h4>
    </div>

    <ul class="List--Boxed mt-4">
      <li class="grid grid-cols-4	">
        <div class="UserList__Item__Name col-span-2">
          <b>{{t('global.courses.s')}}</b>
        </div>
        <div>
          <b>{{t('global.completed.m')}}</b>
        </div>
        <div class="text-right">
          <b>{{t('global.actions')}}</b>
        </div>
      </li>

    <li v-for="related in relatedCourses.related_courses" :key="related.id" :class="['grid grid-cols-4', {'bg-grayScale-light': related.header}]">
      <div class="col-span-2">
        <strong v-if="related.header"> {{ related.name }}</strong>
        <span v-else> {{ related.name }} </span>
      </div>
      <span v-if="!related.header">{{ related.completed || related.completedCourse  ? '✔' : 'No' }}</span>
      <div class="text-right" v-if="!related.header">
        <!-- TODO: add activity -->
        <a  v-if="related.course"
          :href="`/admin/courses/${related.id}/stats`"
          class="Btn Btn--Primary Btn--Icon">
          {{t('actions.go')}}
        </a>
      </div>
    </li>
  </ul>


  </div>
</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import { global as EventBus } from '../../../js/utils/eventBus'; 


export default {
  props: {
    relatedCourses: Array,
    user: Object,
    course: Object
  },
  components: {
    BackButton,
  },
  data(){
    return {
    }
  },

  mounted() {
    
  },
  computed: {
  
  },
  methods: {
   

    

  }
}
</script>
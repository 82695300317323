<template>
  <div>
    <div class="Box Box--Clear mb-8">
      <div class="flex mb-4">
        <input  v-model="search" type="text" :placeholder="`Digita il nome di un'academy...`"  class="flex-1 mr-2" v-on:keyup.enter="getAcademiesFromSearch(true)"   />
        <button @click="getAcademiesFromSearch(true)" class="Btn Btn--Save">{{t('actions.search')}}</button>
      </div>

      <table class="Table table-auto" v-if="allAcademies.length > 0" >
        <thead>
          <tr>
            <th class="text-left">Academy</th>
            <th class="text-left">
              <button :class="[{ active: activeSort === 'created_at' }, 'flex gap-1 items-center text-left']" @click="sort('created_at')">
                <strong>Data di creazione</strong>
                <span>{{ toSortBy === 'asc' ? '↑' : '↓' }}</span>
              </button>
            </th>
            <th class="text-left">Host</th>
            <th class="text-left">
              <button :class="[{ active: activeSort === 'expiry_date' }, 'flex gap-1 items-center text-left']" @click="sort('expiry_date')">
                <strong>Scadenza</strong>
                <span>{{ toSortBy === 'asc' ? '↑' : '↓' }}</span>
              </button>
            </th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody class="List">
          <tr  v-for="academy in allAcademies" :key="academy.id">
            <td class="whitespace-no-wrap">{{ academy.name }}</td>
            <td class="whitespace-no-wrap">{{ academy.created_at | moment("D MMM YYYY") }} </td>
            <td class="whitespace-no-wrap">{{ academy.host }}</td>
            <td class="whitespace-no-wrap">
              <span :class="['rounded-md p-1 text-sm', {
                'bg-danger text-white': academy.expiry_date && (new Date(academy.expiry_date) < new Date()),
                'bg-success text-white': new Date(academy.expiry_date) > new Date(),
                'bg-warning text-white': ((new Date(academy.expiry_date) - new Date())/ (1000 * 3600 * 24) < 30 && new Date(academy.expiry_date) - new Date() > 0)
                }]">{{ academy.expiry_date  | moment("D MMM YYYY") || "n/a" }}
                </span>
            </td>
            <td>
              <a :href="`/admin/${academy.name}`" class="Btn Btn--Primary Btn--Icon">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else-if="loading" class="LoadingBox mt-4 text-center">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
      <div v-else class="mt-4 text-center">
        <p>{{t('pages.users.no_users')}}</p>
      </div>
      

      <div class="text-center mt-2">
        <paginate
          v-model="page"
          :page-count="pagy.pages"
          :click-handler="pageCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'PaginationList'">
        </paginate>
      </div>
    </div>

  
 
    </div>
    
</template>

<script>
import { mapState, mapActions , mapGetters } from 'vuex'
import api from '../../../api'


export default {

  props: {

  },

  components: {
    
  },

  data() {
    return {
      page: 1,
      loading: false,
      pagy: {},
      search: '',
      error: '',
      activeSort: '',
      toSortBy: '',
    }
  },
  
  computed: {
    ...mapGetters({ 
      allAcademies: 'allAcademies'
    })
  },
  mounted() {
   this.getAcademies()
  },

  // watch: {
  //   getAcademies() {
  // },
  // },

  methods: {

     ...mapActions({
      getAllAcademies: 'getAllAcademies'
    }),
      
    getAcademies() {

      api.General.getAcademies({
        page: this.page,
        search: this.search,
        order: this.toSortBy,
        sort: this.activeSort,
      }).then(res => {
        this.getAllAcademies(res.academies)
        this.pagy = res.pagy
        this.loading = false
      }).catch( err => {
        this.error = err.message
        this.loading = false
      })
    },

    pageCallback(pageNum){
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getAcademies({
        page: this.page
      })
    },
   
   getAcademiesFromSearch(){
      this.page = 1
      this.getAcademies()
    },

    sort(value) {
      this.toSortBy = this.toSortBy === 'asc' ? 'desc' : 'asc'
      this.activeSort = value
      this.getAcademies()
    }
  }
}
</script>
<template>
  <div>
    <ul>
      <div v-for="(file, index) in files" :key="index">
        <div class="progress" v-if="file.active">
          <progressBar :progress="1" :indeterminate="true" />
          <!-- <div :style="{width: file.progress + '%'}">{{file.progress}}%</div> -->
        </div>
      </div>
    </ul>
    <div>
      <file-upload
          ref="uploadAttachment"
          v-model="files"
          :post-action="postAction || '/admin/contents'"
          :accept="acceptFile"
          :data="{course_id: course.id, lecture_id: lecture.id, content_type: attachmentType }"
          :drop="true"
          @input-file="inputFile"
          :input-id="`upload${attachmentType}`"
        >
        {{ attachmentType === 'pdf' ? `${t('actions.upload')} PDF` : `${t('actions.upload')} ${t('pages.lecture.attachment')}` }}
      </file-upload>
    </div>
  </div>

</template>

<script>
// import Vue from 'vue/dist/vue.esm'
import { mapState, mapActions } from 'vuex'
import progressBar from '../commons/progressBar'

// const VueUploadComponent = require('vue-upload-component')
// Vue.component('file-upload', VueUploadComponent)
// Vue.config.silent = true

export default {
  props: {
    course: Object,
    lecture: Object,
    attachmentType: String,
    postAction: String
  },
  components: {
    progressBar
    // FileUpload: VueUploadComponent
  }, 
  data() {
    return {
      files: []
    }
  },
  computed: {
    acceptFile() {
      if (this.attachmentType === 'pdf'){
        return 'application/pdf'
      } else {
        return 'application/pdf, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, image/*, text/html, audio/*, application/msword, application/vnd.ms-powerpoint, .zip, .rar'
      }

    },
  },
  methods: {
    ...mapActions({
      addContent: 'addContent'
    }),

    inputFile(newFile, oldFile) {
      this.$refs.uploadAttachment.active = true
       if (newFile) { 
         if (!newFile.active && newFile.success && newFile.response) {
            this.addContent(newFile.response)  
         }
       }   
    },
  }
}
</script>
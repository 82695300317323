import api from "./instance";

/**
 * create attempt
 */
const create = (quiz) =>
  api
    .post(`/quiz_attempts`, { quiz_id: quiz.id })
    .then((response) => response.data);

const createCompletedAnswer = (answer) =>
  api
    .post(`/quiz_completed_answers`, { answer_id: answer.id })
    .then((response) => response.data);

export default {
  create,
  createCompletedAnswer,
};

<template>
  <div class="LectureList__Wrapper mt-4">
      
       <draggable 
        :list="staticArr"
        ghostClass="sortable-ghost"
        tag="ul"
        :sort="canEdit"
        :class="['Draggable rounded overflow-hidden List-SingleBoxes', {loading: loading}]"
        @end="updateRowOrder">
        <li v-for="related in staticArr" :key="`${related.ass_id}-${type(related)}`" :class="[(related.header ? 'SectionItem bg-grayScale-light' : 'bg-white')]" :id="related.ass_id">
          <span class="LectureItem__Position text-grayScale-middle text-sm mr-2">{{related.ass_position}}</span>
          <span class="Tag" v-if="!related.header">{{t(`global.${type(related)}s.s`)}}</span>
          <span class="flex-1"> {{related.name}}</span>
          <span v-if="related.live" class="mr-2">{{related.start_date | moment("D MMM YYYY, k:mm") }}</span>
          <span class="LectureItem__Position text-grayScale-middle text-sm mr-2" v-if="academyName == 'lacerba-api-fad' && type(related) === 'course'">id: {{related.id}}</span>
          <div class="LectureItem__Actions text-white">
            <span v-if="!related.header && !related.live" class="flex-1 text-grayScale-dark mr-2"> {{duration(related.duration)}}</span>
            <a :href="type(related) === 'course' ? `../${related.id}/edit` : `lectures/${related.id}`" class="Btn Btn--Edit Btn--Icon">
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-edit-pencil"></use>
            </svg>
            </a>
            <button @click="removeRelated(related)" v-if="canEdit" class="Btn Btn--Delete Btn--Icon">
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
        </li>
        <div class="lds-ellipsis lds-ellipsis--colored" v-if="loading" ><div></div><div></div><div></div><div></div></div> 
       </draggable>

       <modal name="error-modal" height="auto">
        <div class="v--modal--body body--users p-4 text-center text-danger">
          <p class="pt-8">Ops! Sembra ci sia stato un errore.</p>
        </div>
       </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import api from '../../../api'
import { global as EventBus } from '../../../js/utils/eventBus'; 



export default {
  name: 'ContentList',

  props: {
    course: Object,
    academyName: String,
    isLacerbaAdmin: Boolean
  },

  data() {
    return {
      loading: false,
      staticArr: []
    }
  },

  components: {
    draggable,
  },

  computed: {

    ...mapGetters({
      lectures: "lectures",
      relatedCourses: "relatedCourses"
    }),

    canEdit(){
      return !this.course.from_lacerba || !this.course.from_radical_hr || this.isLacerbaAdmin
    },
  },

  watch: {
    relatedCourses (newVal, oldVal) {
      this.staticArr = newVal
    }
  },

  mounted() {

    this.staticArr = [...this.course.related_courses]
    this.getLectures(this.course.lectures)
    this.getRelated(this.course.related_courses)

    EventBus.on('lecture:added', el => {
      setTimeout( () => {
        document.getElementById(el.target.id).scrollIntoView({
          behavior: 'smooth'
        });
      }, 100)
      
    })
  },

  methods: {
    ...mapActions({
      getLectures: 'getLectures',
      updateLecture: 'updateLecture',
      deleteLecture: 'deleteLecture',
      updateRelated: 'updateRelated',
      getRelated: 'getRelated'
    }),

    type(el) {
      return `${el.course ? 'course' : 'lecture'}`
    },


    duration(totSeconds) {
      if (totSeconds) {
        let seconds = totSeconds % 60;
        let minutes = Math.floor(totSeconds / 60);
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60; // get the remainder of minutes
        if (seconds >= 30) {
          minutes += 1;
        }
        return `${hours}h${minutes}m`;
      } else {
        return "";
      }
          
    },
    

   async updateRowOrder(evt){
     console.log(`New index: ${evt.newIndex}`)
     console.log(`Old index: ${evt.oldIndex}`)
      try {
        const res = await  api.Course.updateRowOrder( {course: {id: evt.item.id, row_order_position: evt.newIndex, path_id: this.course.id } } )
          this.updateRelated(res.related_courses)
      } catch(err) {
        this.staticArr = [...this.course.lectures]
        this.$modal.show('error-modal');
        setTimeout( () => {
          this.$modal.hide('error-modal');
        }, 2000)
      }
 
    },

    async removeRelated(el) {
      if(confirm( `You are deleting ${el.name}`)) {
       try {
          const res = await api.Course.destroyRelated({...this.course,  related: el.ass_id })
            this.updateRelated(res.related_courses)
            if (!el.course) {
              this.deleteLecture(el).then(resp => {
                console.log(resp)
              })
            }
            location.reload();
        } catch (err) {
          console.log(err)
        }
       
      }
      
    }

  }
  
}
</script>


<template>
  <div class="Comments" >
    <div class="Comments__Header Box Box--Clear mb-4">
        <h2 class="mb-4">Scrivi un commento</h2>
        <textarea  type="text" v-on:keyup.enter="sendMessage()" placeholder="Scrivi qualcosa..."/>
        <button  class="Btn Btn--Save" @click="sendMessage()">Invia</button>
      </div>
    <div class="Comments__Body">
      <div class="Comments__Messages p-2 ml-auto" >
        <div  class="Comments__Messages__Item">
          <p class="Comments__Messages__Title font-bold">Matteo</p>
          <p class="Comments__Messages__Text">Lorem ipsum dolor sit amet</p>
          <button class="Btn Btn--Text text-primary">Rispondi</button>
        </div>
        <div  class="Comments__Messages__Item">
          <p class="Comments__Messages__Title font-bold ">Matteo</p>
          <p class="Comments__Messages__Text ">Lorem ipsum dolor sit amet</p>
          <button class="Btn Btn--Text text-primary">Rispondi</button>
        </div>
        <div  class="Comments__Messages__Item">
          <p class="Comments__Messages__Title font-bold ">Matteo</p>
          <p class="Comments__Messages__Text ">Lorem ipsum dolor sit amet</p>
          <button class="Btn Btn--Text text-primary">Rispondi</button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
 
export default {
  props: {

  },
  data(){
    return {
    
    }
  },
   
  mounted() {

  },
  methods: {
    sendMessage() {

    }
  }

}
</script>
<template>
  <div>
    <div v-if="courseSetting.is_boolean" class="mb-4">
      <div class="field flex items-center field--checkbox">
        <input type="checkbox" :id="`setting-${courseSetting.id}`" v-model="courseSetting.return_value" @change="updateCourseSetting()">  
        <label class="ml-2" :for="`setting-${courseSetting.id}`">{{ t(`pages.course.${courseSetting.label}`) }} </label>
      </div>
    </div>
    <settingWithImage v-else-if="courseSetting.is_image" :setting="courseSetting" settingClass="courseSetting" settingType="certification_template" :name="t('pages.course.certification_template')" />
  </div>
</template>

<script>
import api from '../../api'
import settingWithImage from '../commons/settingWithImage'

export default {
  props: {
    courseSetting: Object,
  },
  data() {
    return {
      cta: "save"
    }
  },
  components: {
    settingWithImage
  },
  methods: {
    updateCourseSetting(){
      let self = this
      self.cta = "saving"
      api.CourseSetting.update(self.courseSetting)
      .then( res => {
        self.cta = "saved"
        setTimeout(function () {
          self.cta = "save"
        }, 3000);
        self.courseSetting = res
      })
      .catch(e =>{
        self.cta = "error"
      })
    }
  }
}
</script>
<template>
  <div>
    <div v-for="coach in coaches" :key="coach.id"  >
    <coachCard :currentUser="currentUser" :coach="coach" :tenant_name="tenant_name" :calendlyEnabled="calendlyEnabled" />
    </div>
  </div>
</template>

<script>
import coachCard from '../commons/coachCard'

export default {
  props: {
    currentUser: Object,
    coaches: Array,
    tenant_name: String,
    calendlyEnabled: Boolean,
  },
  components: {
    coachCard
  }
}
</script>
<template>
  <div class="">

  <div class="field flex mb-4">
    <input type="text" v-model="search" :placeholder="t('pages.users.search_user')" v-on:keyup.enter="getEnrollsFromSearch"  />
    <button class="Btn Btn--Save ml-2" @click="getEnrollsFromSearch">{{t('actions.search')}}</button>
  </div>
  <div class="Table__Container" v-if="enrolls.length > 0">
    <table class="Table table-auto" >
    <thead>
      <tr>
        <th class="text-left">{{t('global.full_name')}}</th>
        <th v-if="fiscalCodeLogin" class="text-left">Codice Fiscale</th>
        <th v-else class="text-left">Email</th>
        <th class="text-left">% {{t('global.completion')}} <button @click="sort()" class="ml-2"> {{ sortBy === 'desc' ? '↓' : '↑' }}</button></th>
        <th class="text-left"></th>
      </tr>
    </thead>
    <tbody class="List">
      <template v-if="enrolls.length > 0">
        <tr v-for="enroll in enrolls" :key="enroll.id">
          <td> {{ enroll.user.firstName }} {{ enroll.user.lastName }} </td>
          <td>
            <span v-if="fiscalCodeLogin">{{ enroll.user.fiscal_code }}</span>
            <span v-else>{{enroll.user.email }}</span>
          </td>
          <td class="flex items-center"> 
            <span v-if="enroll.progress_status"><strong>{{  t(`pages.course.${enroll.progress_status}`) }}</strong></span>
            <template v-else>
              <progressBar :progress="enroll.percentageCompleted / 100" />
              <span class="ml-2 font-bold">{{ `${ enroll.percentageCompleted }%` }}</span>
            </template>
           
          </td>
          <td>
            <a v-if="enroll.completed && enroll.course.certification" :href="`/certifications/${enroll.completed.unique_identifier}`" target="_blank">
              <svg height="16" width="16" class="fill-current ml-auto mr-auto"> 
                <use xlink:href="#icon-trophy"></use>
              </svg>
            </a>
          </td>
          <td class="text-right">
            <a :href="`/admin/enrolls/${enroll.id}`" class="Btn Btn--Primary Btn--Icon" :content="t('pages.stats.go_to_reports')" v-tippy>
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-book-reference"></use>
              </svg>
            </a>
            <a :href="`/admin/courses/${course.id}/${enroll.user.id}/user_${course.path ? 'courses' : 'lectures'}`" class="Btn Btn--Primary Btn--Icon" :content="t('pages.stats.go_to_lectures')" v-tippy>
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-list-bullet"></use>
              </svg>
            </a>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  </div>
    <template v-else-if="loading">
      <li class="LoadingBox">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </li>
    </template>
      <div v-else class="pt-8 text-center pb-8">
      <p class="text-lg">{{t('pages.users.no_users')}}</p>
    </div>


  <div class="text-center mt-2">
      <paginate
        v-model="page"
        :page-count="pagy.pages"
        :click-handler="pageCallback"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'PaginationList'">
      </paginate>
  </div>

  </div>
</template>

<script>
import BackButton from './backButton'
import progressBar from './progressBar'
import api from '../../api'

import { global as EventBus } from '../../js/utils/eventBus'; 


export default {

  props: {
    course: Object,
    fiscalCodeLogin: Boolean
  },

  components: {
    BackButton,
    progressBar,
  },

  data() {
    return {
      enrolls: [],
      page: 1,
      search: '',
      sortBy: 'desc',
      pagy: [],
      loading: false
    }
  },

  computed: {

  },

  mounted() {
    this.getEnrolls()
  },
  
  methods: {
    getEnrolls(){
      let self = this
      this.loading = true;
      api.Course.getEnrollStats(self.course, self.page, self.sortBy, true)
      .then( res => {
        console.log(res)
        // self.enrolls = [...self.enrolls, ...res]
        self.enrolls = res.enrolls
        self.pagy = res.pagy
        this.loading = false;
      })
    },
    pageCallback(pageNum){
      console.log(pageNum)
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getEnrolls()
    },
    sort(){
      if (this.sortBy === 'desc') {
        this.sortBy = 'asc'
        this.getEnrolls()
      } else {
        this.sortBy = 'desc'
        this.getEnrolls()
      }
    },
    getEnrollsFromSearch(){
      let self = this
      if (self.search.length > 0) {
      this.loading = true;
      api.Course.getStatsFromSearch(self.course, self.page, self.sortBy, self.search )
        .then( res => {
          console.log(res)
          // self.enrolls = [...self.enrolls, ...res]
          self.enrolls = res.enrolls
          self.pagy = res.pagy
          this.loading = false;
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.getEnrolls()
      }

    }
  }
}
</script>
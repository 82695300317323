<template>
  <div class="Form--Boxed mb-4" >
    <label for="">{{t('settings.zoom_integration')}}</label>
    <p class="mb-3" v-if="academySettings.zoom_token && !authError && !loading">{{t('settings.zoom_authenticated')}}</p>
    <a href="/zoom/auth" data-method="post" class="Btn Btn--Save">
      {{ academySettings.zoom_token && !authError ?  t('settings.zoom_auth_other') : t('settings.zoom_auth') }}
    </a>
    <div class="mt-6" v-if="zoomUsers.length > 0">
      <p class=""><strong>{{t('settings.available_zoom_users')}}</strong></p>
      <p class="mb-2">{{t('settings.available_zoom_users_text')}}</p>
      <ul class="List--Lined List--Lined--Thin mb-4">
        <li v-for="(zoomUser, i) in zoomMergedUsers" :key="zoomUser.id" class="flex gap-2 items-center">
        <div class="pr-2">
          <span><strong>{{i+1}}</strong></span>
        </div>
        <div class="flex-1">
          <p v-if="zoomUser.zoomEmail"><strong>Account Zoom: {{zoomUser.zoomEmail}}</strong></p>
          <p class=" text-grayScale-middle">Account: {{zoomUser.account_user_id}}, {{zoomUser.account_user_email }}</p>
        </div>
        <button
          class="Btn Btn--Icon Btn--Delete"
          @click="removeZoomUser(zoomUser)" >
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-close"></use>
            </svg>
        </button> 
        </li>
      </ul>
      <p v-if="authError" class="text-danger">{{t(authError)}}</p>
    </div>
  </div>
</template>

<script>

import api from '../../../api'

export default {
  name: 'Zoom',
  props: {
   settings: Object,
   academySettings: Object,
   zoomUsers: Array
  },
  data() {
    return {
      zoomRealUsers: [],
      authError: "",
      loading: false
    }
  },
  computed: {
    zoomMergedUsers() {
      let ar = []
      this.zoomUsers.forEach(element => {
        this.zoomRealUsers.forEach(el => {
          if (element.account_user_id == el.id) {
            element.zoomEmail = el.email
          }
        })
        ar.push(element)
      });
      return ar
      
    }
  },
  mounted() {
    this.getZoomUsers()
  },
  methods: {
    saveSetting(setting, settingType, reload) {
      this.$emit('saveSet', '', setting, settingType, reload);
    },

   

    async removeZoomUser(zoomUser) {
      console.log(zoomUser.id)
      if (confirm( `You are about to delete ${zoomUser.account_user_email}\n Are you really sure?` )){
        try {
          const res = await api.Zoom.destroy(zoomUser.id)
          location.reload()
        } catch (error) {
          console.log(error)
        }
      }
    
    },

    async getZoomUsers() {
      this.loading = true
      try {
        const res =  await api.Zoom.getUsers()
        this.zoomRealUsers = res
        this.loading = false
      } catch (error) {
        this.authError = 'settings.zoom_auth_error'
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .List--Lined {
    li {
      border-bottom: 2px solid var(--gray-light);
    }
  }
</style>
<template>
  <ul class="UsersList List--Lined" v-if="users.length > 0">
    <li v-for="user in users" :key="user.id" class="UsersList__Item UsersList__Item--4" >
      <span class="font-bold">{{ user.firstName }} {{ user.lastName }}</span>
      <span v-if="fiscalCodeLogin">{{ user.fiscalCode }}</span>
      <span v-else>{{ user.email }}</span>
      <svg height="16" width="16" :class="`fill-${user.invitationAccepted ? 'success' : 'warning'}`"> 
        <use :xlink:href="`#icon-${user.invitationAccepted ? 'checkmark' : 'close'}-outline`"></use>
      </svg>

      <a v-if="user.approved" :href="`users/${user.id}`" class="Btn Btn--Primary Btn--Icon">
        <svg height="16" width="16" class="fill-current"> 
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </a>
      <button v-else @click="approveUser(user)" class="Btn Btn--Primary">
        Approve
      </button>
    </li>
  </ul>
  <div v-else-if="loading" class="LoadingBox mt-4 text-center">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
  <div v-else class="mt-4 text-center">
    <p>{{t('pages.users.no_users')}}</p>
  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    users: Array,
    loading: Boolean,
    fiscalCodeLogin: Boolean
  },
  data() {
    return {
      search: ''
    }
  },

  methods: {
    approveUser(user){
      user.approved = true
      api.User.update(user)
      .then(res => {
        console.log(res)
        if (res.approved) {
          
          this.users = this.users.filter(function( user ) {
            return user.id !== res.id;
          });

        }
      })
    }
  }
}
</script>
<template>
  <div class="UpgradeModal text-center">
    <div class="v--modal--header p-4">
        <h2 class="text-xl w-full">{{t('upgrade.sorry')}} {{t(`global.${feature}`)}}</h2>
        <button @click="$modal.hide('upgradeModal')" class="Btn Btn--Icon">
          <svg height="12" width="12" class="fill-current"> 
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </div>
      <div class="p-4">
        <h3 class="text-3xl text-success">{{t('upgrade.get_more')}}</h3>
      </div>
      <div class="UpgradeModal__Actions sm:grid sm:grid-cols-3 p-4 gap-4">
        <div class="UpgradeModal__Actions__Item">
          <p>{{t('landing.pricing.plan2.title')}}</p>
          <p class="text-success">{{t(`upgrade.${feature}_${featureMedium}`)}}</p>
          <p class="text-xl mb-2">€80/m + iva</p>
          <a href="https://form.typeform.com/to/f6oqbrwu" target="_blank" class="Btn--Landing typeform-share">{{t('landing.cta.contact')}}</a>
        </div>
       <div class="UpgradeModal__Actions__Item">
          <p>{{t('landing.pricing.plan3.title')}}</p>
          <p class="text-success">{{t(`upgrade.${feature}_${featureLarge}`)}}</p>
          <p class="text-xl mb-2">€200/m + iva</p>
          <a href="https://form.typeform.com/to/f6oqbrwu" target="_blank" class="Btn--Landing typeform-share">{{t('landing.cta.contact')}}</a>
        </div>
        <div class="UpgradeModal__Actions__Item">
          <p>{{t('landing.pricing.plan4.title')}}</p>
          <p class="text-success">{{t(`upgrade.${feature}_${featureEnterprise}`)}}</p>
          <p class="text-xl mb-2">Custom</p>
          <a href="https://form.typeform.com/to/f6oqbrwu" target="_blank" class="Btn--Landing typeform-share">{{t('landing.cta.contact')}}</a>
        </div>

      </div>
  </div>
</template>

<script>

export default {
  name: 'UpgradeModal',
  props: {
    feature: String,
    cssClass: String
  },
  data() {
    return {

    }
  },
  mounted() {
  },

  computed: {
    
    featureMedium() {
      switch (this.feature) {
        case "lectures":
          return '200'
          break;
        case "users":
          return '250'
          break;
      }
    },

    featureLarge() {
      switch (this.feature) {
        case "lectures":
          return 'unlimited'
          break;
        case "users":
          return 'unlimited'
          break;
      }
    },

    featureEnterprise() {
      switch (this.feature) {
        case "lectures":
          return 'unlimited'
          break;
        case "users":
          return 'unlimited'
          break;
      }
    }
  }
}
</script>
import api from './instance'


const testEndpoint = ( ) => (
  api.post('/webhooks/validate-customer-endpoint', { } )
    .then(response => response.data)
)

export default {
  testEndpoint
}
import api from "../../api";
import { global as EventBus } from "../../js/utils/eventBus";

const defaultState = {
  lectures: [],
  courseEnrolls: [],
  enrollingUsers: [],
  relatedCourses: [],
  allCourses: [],
  onlyPaths: [],
  onlyCourses: [],
  areas: [],
};

const getters = {
  lectures(state) {
    return state.lectures
      ? state.lectures.sort((a, b) =>
          parseInt(a.position) > parseInt(b.position) ? 1 : -1
        )
      : state.lectures;
  },
  courseEnrolls(state) {
    return state.courseEnrolls;
  },
  enrollingUsers(state) {
    return state.enrollingUsers;
  },

  relatedCourses(state) {
    return state.relatedCourses;
  },
  allCourses(state) {
    return state.allCourse;
  },
  onlyCourses(state) {
    return state.allCourses.filter((el) => !el.path);
  },
  onlyPaths(state) {
    return state.allCourses.filter((el) => el.path);
  },
  areas(state) {
    return state.areas;
  },
};

export const actions = {
  getLectures({ commit }, lectures) {
    commit("getLectures", lectures);
  },

  getRelated({ commit }, related) {
    commit("getRelated", related);
  },

  addLecture({ commit }, lecture) {
    commit("lectureAdded", lecture);
  },

  updateLecture({ commit }, lecture) {
    commit("lectureUpdated", lecture);
  },

  updateRelated({ commit }, course) {
    commit("relatedUpdated", course);
  },

  deleteLecture({ commit }, lecture) {
    api.Lecture.destroy(lecture).then(() => {
      commit("lectureDeleted", lecture);
    });
  },

  getCourseEnrolls({ commit }, enrolls) {
    commit("getCourseEnrolls", enrolls);
  },

  addEnroll({ commit }, enroll) {
    commit("enrollAdded", enroll);
  },

  addEnrolls({ commit }, enrolls) {
    commit("enrollsAdded", enrolls);
  },

  addEnrollingUser({ commit }, user) {
    commit("enrollingUserAdded", user);
  },

  removeEnrollingUser({ commit }, user) {
    commit("enrollingUserRemoved", user);
  },

  getAllCourses({ commit }, courses) {
    commit("getAllCourses", courses);
  },
  getOnlyCourses({ commit }, courses) {
    commit("getOnlyCourses", courses);
  },
  getOnlyPaths({ commit }, courses) {
    commit("getOnlyPaths", courses);
  },
  getCourseAreas({ commit }, areas) {
    commit("getCourseAreas", areas);
    console.log(areas);
  },
};

export const mutations = {
  getLectures(state, lectures) {
    // let orderedLectures = lectures.sort((a, b) => (a.row_order > b.row_order) ? 1 : -1)
    // let orderedLectures = lectures.sort((a, b) => a.row_order - b.row_order)
    state.lectures = lectures;
  },

  getRelated(state, related) {
    // let orderedLectures = lectures.sort((a, b) => (a.row_order > b.row_order) ? 1 : -1)
    // let orderedLectures = lectures.sort((a, b) => a.row_order - b.row_order)
    state.relatedCourses = related;
  },

  lectureAdded(state, lecture) {
    state.lectures.push(lecture);
    EventBus.emit("lecture:added", lecture);
  },

  lectureUpdated(state, updatedLecture) {
    // const objIndex = state.lectures.findIndex(obj => obj.id === updatedLecture.id);

    // const updatedObj = { ...state.lectures[objIndex], updatedLecture };

    // const updatedLectures = [
    //   ...state.lectures.slice(0, objIndex),
    //   updatedObj,
    //   ...state.lectures.slice(objIndex + 1),
    // ];

    // state.lectures = updatedLectures

    state.lectures = [
      ...state.lectures.filter((element) => element.id !== updatedLecture.id),
      updatedLecture,
    ];
  },

  relatedUpdated(state, related) {
    state.relatedCourses = related;
  },

  lectureDeleted(state, lecture) {
    let lectures = !!state.lectures
      ? state.lectures.filter((item) => item.id !== lecture.id)
      : state.lectures;
    state.lectures = lectures;
  },

  getCourseEnrolls(state, enrolls) {
    state.courseEnrolls = enrolls;
  },

  enrollAdded(state, enroll) {
    state.enrollingUsers.push(enroll.user);
  },

  enrollsAdded(state, enrolls) {
    let users = [];
    enrolls.map((enroll) => users.push(enroll.user));
    state.enrollingUsers = users;
  },

  enrollingUserAdded(state, user) {
    if (state.enrollingUsers.filter((u) => u.id === user.id).length === 0) {
      state.enrollingUsers.push(user);
    }
  },

  enrollingUserRemoved(state, user) {
    state.enrollingUsers = state.enrollingUsers.filter(
      (item) => item.id !== user.id
    );
  },

  getAllCourses(state, courses) {
    state.allCourses = courses;
  },
  getOnlyCourses(state, courses) {
    state.onlyCourses = courses.filter((el) => !el.path);
    console.log(courses.filter((el) => !el.path));
  },
  getOnlyPaths(state, courses) {
    state.onlyPaths = courses.filter((el) => el.path);
  },
  getCourseAreas(state, areas) {
    state.areas = areas;
  },
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};

import api from "./instance";

/**
 * Create
 */
const create = (setting) =>
  api
    .post(`/admin/academy_settings`, setting)
    .then((response) => response.data);

/**
 * destroy
 */
const destroy = (settingId) =>
  api
    .delete(`/admin/academy_settings/${settingId}`)
    .then((response) => response.data);

const updateHomeTranslation = (settingId, homeTranslation) =>
  api
    .put(
      `/admin/academy_settings/${settingId}/home_translation`,
      homeTranslation
    )
    .then((response) => response.data);

export default {
  create,
  updateHomeTranslation,
  destroy,
};

<template>
  <div>
    <div class="Form--Boxed mb-4">
        <h3 class="mb-4">{{t('pages.groups.title.p')}}</h3>
        <p></p>
        <a  class="Btn Btn--Save" href="/admin/groups">{{t('pages.groups.go_to_groups')}}</a>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Groups',
  props: {
   settings: Object
  },
  methods: {
   
  }
}
</script>
import api from "./instance";

/**
 * invite user
 */
const create = (user, userFields, userType, course_id) =>
  api
    .post(`/admin/users`, {
      user: user,
      user_fields: userFields,
      user_type: userType,
      course_id: course_id,
    })
    .then((response) => response.data);

const destroy = (user) =>
  api.delete(`/admin/users/${user.id}`).then((response) => response.data);

const update = (user) =>
  api.put(`/admin/users/${user.id}`, user).then((response) => response.data);

const getUsers = (userType, page) =>
  api
    .get(`/admin/users.json`, { params: { user_type: userType, page: page } })
    .then((response) => response.data);

/**
 * search users
 */
const getUsersFromSearch = (params) =>
  api
    .get(`/admin/users/search.json`, { params })
    .then((response) => response.data);

const exportUserStats = (userParams) =>
  api
    .get(`/admin/users/${userParams.id}/user_stats_export.json`, {
      params: {},
    })
    .then((response) => response.data);

/**
 * import users
 */
const importUsers = (s3_file_path) =>
  api
    .post(`/admin/users/import`, { s3_file_path: s3_file_path })
    .then((response) => response.data);

/**
 * export users
 */
const exportUsers = () =>
  api.get(`/admin/users/export`).then((response) => response.data);

const resetPassword = (user) =>
  api
    .post(`/admin/password_resets`, { user_id: user.id })
    .then((response) => response.data);

export default {
  create,
  update,
  destroy,
  getUsers,
  getUsersFromSearch,
  importUsers,
  exportUsers,
  exportUserStats,
  resetPassword,
};

<template>
  <a :href="href" :class="[cssClass, 'Btn Btn--Primary Btn--Icon Btn--Back']" data-turbolinks="false">
    <svg height="16" width="16" class="fill-current"> 
      <use xlink:href="#icon-arrow-left"></use>
    </svg>
  </a>
</template>

<script>

export default {
  name: 'BackButton',
  props: {
    cssClass: String,
    href: String,
  }
}
</script>
<template>
  <div>
 
    <div class="container container--toleft">
      <div class="mb-8">
        <div class="flex flex-wrap gap-2 justify-between mb-4">
          <h2 class="text-2xl">{{t('pages.stats.general')}}</h2>
          <div>
            <date-picker confirm="true" v-model="dateRange" placeholder="Scegli un range di date" range></date-picker>
            <button @click="exportStats" :disabled="loading" class="Btn Btn--Save md:ml-4">{{t('actions.export_stats')}}</button>
          </div>
        </div>
        <div class="grid sm:grid-cols-3 gap-3">
          <div class="CourseChart Box Box--Clear">
            <div class="Box__BgTitle mb-6">
              <h2 class="text-lg">{{t('global.users')}}<span v-if="rangeActive">*</span></h2>
              <svg height="16" width="16" class="icon-user-group fill-current Boxed">
                <use xlink:href="#icon-user-group"></use>
              </svg>
            </div>
            <div class="text-center">
              <p class="text-3xl"><strong>{{general.users}}</strong></p>
              <p v-if="rangeActive" class="text-sm text-grayScale-middle text-left mt-3">*{{t('pages.stats.users_selected_period')}}</p>
            </div>
          </div>
          <div class="CourseChart Box Box--Clear">
            <div class="Box__BgTitle mb-6">
              <h2 class="text-lg">{{t('global.courses.p')}} {{ t('pages.stats.started_pl') }}<span v-if="rangeActive">*</span></h2>
              <svg height="16" width="16" class="icon-book-reference fill-current Boxed">
                <use xlink:href="#icon-book-reference"></use>
              </svg>
            </div>
            <div class="text-center">
              <p class="text-3xl"><strong>{{general.started}}</strong></p>
              <p v-if="rangeActive" class="text-sm text-grayScale-middle text-left mt-3">*{{t('pages.stats.started_selected_period')}}o</p>
            </div>
          </div>
          <div class="CourseChart Box Box--Clear">
            <div class="Box__BgTitle mb-6">
              <h2 class="text-lg">{{t('global.courses.p')}} {{ t('pages.stats.completed_pl') }}<span v-if="rangeActive">*</span></h2>
              <svg height="16" width="16" class="icon-checkmark-outline fill-current Boxed">
                <use xlink:href="#icon-checkmark-outline"></use>
              </svg>
            </div>
            <div class="text-center">
              <p class="text-3xl"><strong> {{general.completedCourses}}</strong></p>
              <p v-if="rangeActive" class="text-sm text-grayScale-middle text-left mt-3">*{{t('pages.stats.completed_selected_period')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-8">
        <div class="flex flex-wrap gap-2 justify-between mb-4">
          <h2 class="text-2xl">{{t('pages.stats.courses')}}</h2>
          <div class="flex">
            <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_courses`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
            <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
          </div>
        </div>
       
        <div class="Table__Container">
          <table class="Table table-auto"  >
            <thead>
              <tr>
                <th class="Table__Courses text-left">{{t('global.courses.p')}}</th>
                <th class="text-left">
                  <button :class="[{active: activeSort === 'enrolls'}, 'flex gap-1 items-center text-left']" @click="sort('enrolls')">
                    <strong>{{t('pages.stats.enrolled_users')}}</strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left ">
                  <button :class="[{active: activeSort === 'started'}, 'flex gap-1 items-center text-left']" @click="sort('started')" >
                    <strong> {{t('pages.stats.started_users')}}</strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">
                  <button :class="[{active: activeSort === 'completed'}, 'flex gap-1 items-center text-left']" @click="sort('completed')" >
                    <strong>{{t('pages.stats.completed_users')}}</strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="">
                  <button :class="[{active: activeSort === 'perc_completion'}, 'flex gap-1 items-center text-left']" @click="sort('perc_completion')" >
                    <strong>{{t('pages.stats.perc_media')}} </strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
              </th>
              </tr>
            </thead>
            <tbody :class="['List', {loading: loading}]">
              <div v-if="loading" class="LoadingBox mt-4 text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
              <div v-else-if="error != ''" class="Box Box--Clear">
                <p class="text-danger">{{error}}</p>
              </div>
              <div v-else-if="enrollsByCourses.length == 0">
                <p class="text-left p-4">Non sono presenti attività</p>
              </div>
              <template v-else>
                <tr v-for="(course, i) in  pageOfItems" :key="`course-${i}`">
                  <td ><a :href="course.url">{{course.name}}</a></td>
                  <td>{{course.enrolls}}</td>
                  <td>{{course.started}}</td>
                  <td>{{course.completed}}</td>
                  <td>{{course.perc_completion}}%</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
       
        <div class="text-center mt-4">
          <jw-pagination :items="enrollsByCourses" @changePage="onChangePage" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
      </div>
      <div>
        <div class="flex flex-wrap justify-between mb-4">
          <h2 class="text-2xl">{{t('pages.stats.top_10')}}</h2>
        </div>
        <div v-if="enrollsByCourses.length == 0" class="Box Box--Clear Box--Thin">
          <p class="text-left">Non sono presenti attività</p>
        </div>
        <div v-else>
          <pie-chart  :data="chartData" legend="bottom" ></pie-chart>
        </div>
      </div>
     
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import api from '../../../api'
import * as XLSX from 'xlsx-js-style';
import DatePicker from 'vue2-datepicker';




export default {
  props: {
   
  },
  data(){
    return {
     enrollsByCourses: [],
     page: 1,
     pagy: {},
     loading: false,
     error: false,
     pageOfItems: [],
     toSortBy: 'asc',
     activeSort: 'enrolls',
     search: '',
     dateRange: [],
     general: {},
     customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
    }
  },
  components: {
    DatePicker
  },

  mounted() {
    this.getEnrollsByCourses()
    this.getGeneralInfo()
  },


  computed: {

    filteredCourses() {
      return this.enrollsByCourses.map(el => [el.name, el.enrolls])
    },

    chartData(){
      return this.filteredCourses.slice(0, 10)
    },

    rangeActive() {
      return this.dateRange.length > 0 && this.dateRange[0] != null && this.dateRange[1] != null
    },



    coursesForExport() {
      return this.enrollsByCourses.map(course => (
        {
          name: course.name,
          enrolls: course.enrolls,
          started: course.starte,
          completed: course.completed,
          perc_completion: course.perc_completion
        }
      ))
    },
    
    generalInfo() {
      return [{
        users:  this.general.users,
        started: this.general.started,
        completed: this.general.completedCourses
      }]
    }

  },

  watch: {
    dateRange() {
      this.getEnrollsByCourses()
      this.getGeneralInfo()
    }
  },
  methods: {

    getCoursesFromSearch(){
      this.page = 1
      this.getEnrollsByCourses()
    },
 
    getEnrollsByCourses() {
      this.loading = true
      api.Stats.getEnrollByCourses({
        page: this.page,
        search: this.search, 
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      })
      .then( res => {
        this.enrollsByCourses = res.enrolls_by_courses;
        this.loading = false
      }).catch(err => {
        this.err = err
      })
    },

     getGeneralInfo() {
      api.Stats.getGeneralInfo({
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      })
        .then( res => {
          this.general = res
        }).catch(err => {
          this.err = err
        })
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    sort(value) {
      console.log(this.activeSort, value, this.toSortBy)
      if  (this.activeSort !== value) this.toSortBy == 'desc'
      
      this.activeSort = value
      this.enrollsByCourses.sort((a, b) =>  this.toSortBy === 'asc' ? ( a[value] - b[value]) :  (b[value] - a[value]) );
      this.toSortBy = this.toSortBy == 'asc' ? 'desc' : 'asc'
    },

    exportStats() {
        const  wb = XLSX.utils.book_new()

        const sheets = [
          {
            heading: [ [
              { v: "Utenti totali", t: "s", s: { font: { bold: true } } },
              { v: "Corsi iniziati", t: "s", s:{ font: {  bold: true } } },
              { v: "Corsi completati", t: "s", s: { font: {  bold: true } } }
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.generalInfo,
            name:  "Info generali"
          },
          {
            heading: [ [
              { v: 'Corsi', t: "s", s: { font: { bold: true } } },
              { v: 'Utenti iscritti', t: "s", s:{ font: {  bold: true } } },
              { v: 'Utenti che hanno iniziato', t: "s", s: { font: {  bold: true } } },
              { v: 'Utenti che hanno completato', t: "s", s:{ font: {  bold: true } } },
              { v: 'Media di completamento', t: "s", s:{ font: {  bold: true } } },
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.coursesForExport,
            name: "Corsi"
          }
        ]

        sheets.forEach(el => {
          XLSX.utils.sheet_add_aoa(el.ws, el.heading);
          XLSX.utils.sheet_add_json(el.ws, el.arr, { origin: 'A2', skipHeader: true });
          XLSX.utils.book_append_sheet(wb, el.ws, el.name)
        })

        XLSX.writeFile(wb, `Report_General.xlsx`)
        
      },

   
   

  }
}
</script>

<style lang="scss">
  .List.loading {
    position: relative;
    height: 150px;
    .LoadingBox {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }

  .Table {
    &__Courses {
      width: 30%;
    }
    button {
      span {
        display: none;
      }
      &.active {
        span {
          display: block;
        }
      }
    }
  }
</style>
<template>
  <div class="LecturePage__SideBar__List">
    <div class="LecturePage__SideBar__Header p-4 flex justify-between">
      <a href="/" class="font-bold">Home</a>
      <a :href="`../../${enrolledPath}/path`" class="Btn Btn--Text PathLink" v-if="!!enrolledPath && course.id !== parseInt(enrolledPath)">{{ t('pages.lecture.go_back_to_path') }}</a>
    </div>
    <div class="LecturePage__SideBar__CourseName p-4 bg-primary text-white">
     <h4><a :href="`/courses/${course.id}/page`">{{course.name}}</a></h4>
    </div>
    <div class="LecturesList__Container">
      <ul class="LecturesList p-4 pb-16">
        <li v-for="item in lectures" 
          :class="{active: currentLecture.id === item.id ,  LecturesList__Section: item.header, completed: item.completed || item.percentage_value === 100 }" 
          :key="item.id">
          <span v-if="item.header"><b>{{item.name}}</b></span>
          <button :ref="`button${item.id}`" v-else-if="!item.canShow" :id="item.id" class="cannot-view">{{item.name}}</button>
          <a :href="`${item.nextLecture}`" v-else-if="item.course">{{item.name}}</a>
          <a v-else :href="`${item.id}`">
            {{item.name}}
            <b v-if="item.live && item.start_date">{{itemstart_date | moment("D/M/YY k:mm") }}</b>
          </a>     
        </li>
    </ul>
    </div>
    
  </div>
</template>

<script>

import { global as EventBus } from '../../js/utils/eventBus';

export default {
  name: 'Sidebar',

  props: {
    currentLecture: Object,
    course: Object,
    lectures: Array,
    logo: String,
    enrolledPath: Number
  },

  computed: {

  },

  mounted() {
    EventBus.on('lecture:completed',  (payload) => {
      console.log('lecture:completed', payload)
      const button = this.$refs[`button${payload.target.lectureId}`]
      if (button) {
        console.log('button', button)
        button[0].outerHTML = `<a href="${payload.target.lectureId}">${button[0].innerText}</a>`
      }
    })
  },

  methods: {
    isCourse(item) {
      return item.hasOwnProperty('lectures')
    }
  }

}
</script>

<template>
  <div class="Box__BgTitle mb-6">
    <h2 class="text-lg">{{title}}</h2>
    <svg height="16" width="16" class="fill-current Boxed">
      <use :xlink:href="`#icon-${icon}`"></use>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'BgTitle',
  props: {
    title: String,
    icon: String
  }
}
</script>
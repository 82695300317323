import { pick } from "lodash";
import api from "./instance";

const update = (id, params) =>
  api
    .put(`/admin/custom_notifications/${id}`, params)
    .then((response) => response.data);

const sendTestEmail = (id, params) =>
  api
    .post(`/admin/custom_notifications/${id}/send_test_email`, params)
    .then((response) => response.data);

const sendEmail = (id, params) =>
  api
    .post(`/admin/custom_notifications/${id}/send_email`, params)
    .then((response) => response.data);

const getCourses = (params) =>
  api
    .get("/admin/custom_notifications/courses", { params })
    .then((response) => response.data);

const createNotificationTargets = (id, params) =>
  api
    .post(
      `/admin/custom_notifications/${id}/create_notification_targets`,
      params
    )
    .then((response) => response.data);

const deleteNotificationTargets = (id, params) =>
  api
    .put(
      `/admin/custom_notifications/${id}/delete_notification_targets`,
      params
    )
    .then((response) => response.data);

const deleteSentNotifications = (id, params) =>
  api
    .put(`/admin/custom_notifications/${id}/delete_sent_notifications`, params)
    .then((response) => response.data);

const updateTranslation = (id, params) =>
  api
    .put(`/admin/custom_notifications/${id}/update_translation`, params)
    .then((response) => response.data);

const addUsers = (id, params) =>
  api.post(`/admin/custom_notifications/${id}/add_users`, { custom_notification_id: id, ...params })
    .then(response => response.data);

const removeUsers = (id, params) =>
  api.post(`/admin/custom_notifications/${id}/remove_users`, { custom_notification_id: id, ...params })
    .then(response => response.data);

const addGroups = (id, params) =>
  api
    .post(`/admin/custom_notifications/${id}/add_groups`, params)
    .then((response) => response.data);

const removeGroups = (id, params) =>
  api
    .post(`/admin/custom_notifications/${id}/remove_groups`, params)
    .then((response) => response.data);

export default {
  update,
  sendTestEmail,
  createNotificationTargets,
  deleteNotificationTargets,
  getCourses,
  updateTranslation,
  sendEmail,
  deleteSentNotifications,
  addUsers,
  removeUsers,
  addGroups,
  removeGroups
};
import api from "./instance";

const createGroupEnrolls = (group_ids, course_id) =>
  api
    .post(`/admin/group_courses`, { group_ids, course_id })
    .then((response) => response.data);


    const destroyGroupEnrolls = (group_ids, course_ids) =>
      api
        .delete(`/admin/group_courses/destroy_all`, {
          data: { group_ids, course_ids }
        })
        .then((response) => response.data);
export default {
  createGroupEnrolls,
  destroyGroupEnrolls,
};
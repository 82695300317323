<template>
  <div class="mb-6">
    <p class="mb-2">{{t('actions.new_coach_sess')}}</p>
    <v-select
      :options="coachesArray"
      @input="selection => updateSelected(selection)"
      class="mb-2"
    />
    <datetime 
      :placeholder="t('actions.select_date')"
      type="datetime"
      v-model="startTime"
      class="mb-2">
    </datetime>
    <button @click="scheduleEvent()" class="Btn Btn--Save">{{t('actions.save')}}</button>
  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    user: Object,
    coaches: Array,
  },
  data() {
    return {
      selectedCoach: null,
      startTime: String
    }
  },
  computed: {
    coachesArray(){
      return this.coaches.sort((a, b) => a.fullName - b.fullName ).map( coach => {
        return {label: coach.fullName, value: coach.id}
      })
    },
  },
  methods:{
    updateSelected (selection) {
      this.selectedCoach = selection.value;
    },

   scheduleEvent(){
      let self = this
      api.Coach.book(this.user.id, this.selectedCoach, this.startTime)
      .then( res =>{
        // TODO add a better res
        location.reload()
      })
    },


  }
}
</script>
<template>
  <div class="field--inputImage">
    <div class="flex items-center mb-4">
      <p class="font-bold mr-2">{{name}}</p>
      <file-upload
        ref="uploadLogo"
        v-model="files"
        class="mr-2 Btn--Icon"
        :post-action="postAction"
        accept="image/png,image/gif,image/jpeg,image/webp"
        :data="{name: settingType, id: settingId}"
        :drop="true"
        @input-file="inputFile"
        :input-id="`upload${settingType}`">
          <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-upload"></use>
          </svg>
      </file-upload>
      <button
        v-if="setting && setting.url"
        class="Btn Btn--Icon Btn--Delete"
        @click="removeImage(setting.id)" >
          <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-close"></use>
          </svg>
      </button> 
    </div>
    
    <img v-if="setting && setting.url" :src="setting.url" class="mb-6" />

    <span v-for="(file, index) in files" :key="index">
      <div class="progress" v-if="file.active">
        <progressBar :progress="1" :indeterminate="true" />
      </div>
    </span>
  </div>
  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import progressBar from '../commons/progressBar'
import api from '../../api'

export default {
  props: {
    setting: Object,
    settingClass: String,
    settingType: String,
    name: String
  },
  data(){
    return {
      files: [],
      postAction: "",
      putAction: ""
    }
  },
  components: {
    progressBar
  },
  computed: {
    settingId(){
      return this.setting ? this.setting.id : null
    }

  },
  mounted() {
    if (this.settingClass === 'academySetting') {
      this.postAction = "/admin/academy_settings"
    } else {
      this.postAction = `/admin/course_settings`
    }
  },

  methods: {

    ...mapActions({
      updateAcademySetting: 'updateAcademySetting',
    }), 
    
    inputFile(newFile, oldFile) {
      let self = this
      this.$refs.uploadLogo.active = true
        if (newFile) { 
          if (!newFile.active && newFile.success && newFile.response) {
            let setting = newFile.response
            let settingType = self.settingType
            if (this.settingClass === 'academySetting') {
              this.updateAcademySetting({setting, settingType}) 
              location.reload() 
            } else {
              this.setting = setting
            }
          }
        }   
    },

    removeImage(settingId){
      console.log(settingId)
      if(confirm( `You are about to delete the image \n Are you really sure?` )){

        if (this.settingClass === 'courseSetting') {
          api.CourseSetting.destroyImage(settingId).then(res => {
            location.reload()
          })
        } else {
           //sostutuirlo con la stessa logica di quello sopra
          api.AcademySetting.destroy(settingId)
          .then(res => {
            this.setting = null
          })
        }
       
      }
    }
    
  }


}
</script>

<style lang="scss" scoped>

  img {
    max-height: 80px;
    width: auto;
  }

</style>
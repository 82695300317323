<template>
  <div>
    
    <ul class="UsersList List--Boxed mt-4">
      <li class="UsersList__Item UsersList__Item--5 ">
        <div class="UserList__Item__Name">
          <b>{{t('global.full_name')}}</b>
        </div>
        <div>
          <b>Email</b>
        </div>
        <div>
          <b>{{t('global.courses.s')}}</b>
        </div>
        <div class="text-right">
          <b>{{t('global.actions')}}</b>
        </div>
      </li>
      
      <li v-if="enrolls.length == 0">{{t('pages.enroll.no_requests')}}</li>
      <li v-else  v-for="enroll in enrolls" :key="enroll.id" class="UsersList__Item UsersList__Item--5">
        <div class="UserList__Item__Name">
          <span> {{ enroll.user.firstName }} {{ enroll.user.lastName }}   </span>
        </div>
        <div> <span>{{ enroll.user.email }}</span></div>
        <div> <span>{{ enroll.course.name }}</span></div>
        <div class="text-right">
          <a class="Btn Btn--Primary" @click="approveEnroll(enroll)">
            {{t('global.approve')}}
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  props: {
    enrolls: Array
  },

  methods: {
    approveEnroll(enroll) {
      let self = this
      enroll.approved = true
      api.Enroll.update(enroll)
      .then( res => {
        self.enrolls = self.enrolls.filter(e => e.id !== res.id)
      })
    }
  }
}
</script>
import api from './instance'


const createCourse = (params) => (
  api.post(`/admin/scorm_cloud/create_course`, {
    file: params.file
  })
    .then(res => res.data)
)

const getCourse = (params) => (
  api.get(`/admin/scorm_cloud/get_course/${params.id}`)
    .then(res => res.data)
)

const getCourses = (params) => (
  api.get(`/admin/scorm_cloud/get_courses`)
    .then(res => res.data)
)

export default {
  createCourse,
  getCourse,
  getCourses,
}
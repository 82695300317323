<template>
  <div class="field">
    <p class="font-bold mb-2">Immagine Profilo</p>
    <img v-if="userField && userField.url" :src="userField.url" width="80px" class="mb-4">
    <file-upload
      ref="uploadImage"
      v-model="files"
      post-action="/user_fields/upload"
      accept="image/png,image/gif,image/jpeg,image/webp"
      :data="{user_field_id: userField.id}"
      :drop="true"
      @input-file="inputFile"
      :input-id="`upload${userField.id}`"
    >
    {{t('actions.upload')}}
  </file-upload>

  <span v-for="(file, index) in files" :key="index">
    <div class="progress" v-if="file.active">
      <progressBar :progress="1" :indeterminate="true" />
    </div>
  </span>

  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
import progressBar from '../commons/progressBar'


export default {
  props: {
    userField: Object,
  },
  data(){
    return {
      files: []
    }
  },
  components: {
    progressBar
  },
  methods: {
    
    inputFile(newFile, oldFile) {
      let self = this
      this.$refs.uploadImage.active = true
        if (newFile) { 
          if (!newFile.active && newFile.success && newFile.response) {
            location.reload();
          }
        }   
    },
  }


}
</script>
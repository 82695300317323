<template>
  <div :class="[cssClass, 'field--inputImage']">
    <div class="flex items-center">
      <p class="font-bold mr-2">
        {{name}}
        <button v-if="hint" :content="hint" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
          <svg height="18" width="18" class="fill-current"> 
            <use xlink:href="#icon-information-outline"></use>
          </svg>
        </button>
      </p>
      <file-upload
        ref="uploadLogo"
        v-model="file"
        class="Btn--Icon"
        :post-action="postAction"
        :data="{ course_id: course.id, image: true }"
        accept="image/png,image/gif,image/jpeg,image/webp"
        :drop="true"
        @input-file="inputFile">
          <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-upload"></use>
          </svg>
      </file-upload>
      <button
        v-if="image_url"
        class="Btn Btn--Icon Btn--Delete ml-2"
        @click="removeImage()" >
          <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-close"></use>
          </svg>
      </button> 
    </div>
    
    <img v-if="image_url" :src="image_url" class="mb-4" />

    <span v-for="(file, index) in files" :key="index">
      <div class="progress" v-if="file.active">
        <progressBar :progress="1" :indeterminate="true" />
      </div>
    </span>
  </div>
  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import progressBar from '../commons/progressBar'
import api from '../../api'

export default {
  props: {
    course: Object,
    name: String,
    hint: String,
    image_url: String,
    cssClass: String
  },
  data(){
    return {
      file: [],
      postAction: '/admin/courses/upload',
      putAction: ""
    }
  },
  components: {
    progressBar
  },
  computed: {
 

  },
  created() {

  },
  methods: {

   
    
    inputFile(newFile, oldFile) {
      let self = this
      this.$refs.uploadLogo.active = true
        if (newFile) { 
          if (!newFile.active && newFile.success && newFile.response) {
            location.reload() 
          }
        }   
    },

    removeImage(){
      if(confirm( `You are about to delete the image \n Are you really sure?` )){
        api.Course.destroyImage({course_id: this.course.id, image: true})
        .then(res => {
          location.reload()
        })
      }
    }
    
  }


}
</script>

<style lang="scss" scoped>
  img {
    width: auto;
    height: 80px;
  }

</style>
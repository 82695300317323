import api from './instance'

/**
* Create
*/
const update = (setting) => (
  api.put(`/admin/course_settings/${setting.id}`, setting )
  .then(response => response.data)
)

const destroyImage = (settingId) => (
  api.delete(`/admin/course_settings/${settingId}/destroy_image`)
    .then(response => response.data)
)


// /**
// * destroy
// */
// const destroy = (settingId) => (
//   api.delete(`/admin/academy_settings/${settingId}` )
//   .then(response => response.data)
// )

export default {
  update,
  destroyImage
}
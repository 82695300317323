<template>
  <div>
    <h1 class="text-xl">
      <a class="Btn Btn--Primary Btn--Icon mb-2 mr-4" href="/users/leaderboards">
        <svg height="16" width="16" class="icon-arrow-left fill-current">
          <use xlink:href="#icon-arrow-left"></use>
        </svg>
      </a>{{ t('pages.gamification.leaderboards.title.s') }}{{ ' ' + leaderboardName }}
    </h1>
    <p class="mt-5 mb-2">
      {{ t('global.start_date') }}: <b>{{ startDate }}</b>   |   {{ t('global.end_date') }}: <b>{{ endDate }}</b>
    </p>

    <div v-if="loading" class="LoadingBox mt-4 text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>

    <div v-else class="Form--Boxed mt-5">
      <div v-if="!paginatedScores || paginatedScores.length === 0" class="text-center">
        <h2 class="text-lg p-4">{{t('pages.gamification.leaderboards.no_partecipants')}}</h2>
      </div>
      <div v-else class="">
        <table class="leaderboard-table w-full table table-striped">
          <thead>
            <tr>
              <th class="text-center py-5">Rank</th>
              <th class="text-left py-5">{{ t('global.user.s') }}</th>
              <th class="text-center py-5">{{ t('pages.prizes.points') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(score, index) in paginatedScores" :key="index" :class="{ 'highlight-user': score.user_id === userId }">
              <td class="text-center">{{ score.rank }}</td>
              <td class="text-left" :class="{ 'blur-text': anonymity && score.user_id !== userId }">{{ score.user_name }}</td>
              <td class="text-center">{{ score.points }}</td>
            </tr>
          </tbody>
        </table>

        <div class="text-center mt-6">
          <paginate
            v-model="currentPage"
            :page-count="totalPages"
            :click-handler="onChangePage"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leaderboardId: Number,
    leaderboardName: String,
    scores: {
      type: Array,
      default: () => [],
    },
    userId: Number,
    initialPage: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    anonymity: {
      type: Boolean,
      default: false,
    },
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      currentPage: this.initialPage,
      loading: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.scores.length / this.itemsPerPage);
    },
    paginatedScores() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      const sorted = this.scores
        .sort((a, b) => b.points - a.points)
        .map((score, index) => ({
          ...score,
          rank: index + 1,
        }));

      return sorted.slice(start, end);
    },
  },
  methods: {
    onChangePage(page) {
      this.currentPage = page;
    },
    findUserPage() {
      const sorted = this.scores
        .sort((a, b) => b.points - a.points)
        .map((score, index) => ({
          ...score,
          rank: index + 1,
        }));
      const userIndex = sorted.findIndex(score => score.user_id === this.userId);
      if (userIndex !== -1) {
        this.currentPage = Math.floor(userIndex / this.itemsPerPage) + 1;
      } else {
        this.currentPage = this.initialPage;
      }
    },
  },
  mounted() {
    this.findUserPage();
    console.log("Leaderboard ID:", this.leaderboardId);
  },
};
</script>

<style>
.highlight-user {
  background-color: #f0f8ff;
  font-weight: bold;
}
.blur-text {
  filter: blur(5px);
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
</style>
<template>
  <div>
    <div class="Form--Boxed mb-4" >
      <div class="field mb-10">
        <div class="flex items-center justify-between">
          <label for="webhook">Webhook Endpoint
            <a class="Btn--Tooltip ml-3" target="_blank" href="https://skillsincloud.gitbook.io/api/reference/webhooks">
              <svg height="18" width="18" class="fill-current"> 
                <use xlink:href="#icon-information-outline"></use>
              </svg>
            </a>
          </label>
          <div v-if="settings.webhook_enabled.value == 'true'" class="flex text-success items-center mb-1 gap-1">
            <p>Endpoint valido</p>
            <svg height="14" width="14" class="fill-current"> 
              <use xlink:href="#icon-checkmark"></use>
            </svg>
          </div>
          <div v-else class="flex text-danger items-center mb-1 gap-1">
            <p>Endpoint non valido</p>
            <svg height="14" width="14" class="fill-current"> 
              <use xlink:href="#icon-close"></use>
            </svg>
          </div>
        </div>
        <input type="text" id="webhook" placeholder="https://your-domain.com/webhook"  class="mb-3" v-model="settings.webhook_endpoint.value">
        <div class="flex justify-between">
          <button @click="saveSetting(settings.webhook_endpoint, 'webhook_endpoint')" class="Btn Btn--Save">{{t('actions.validate_endpoint')}}</button>
        </div>
      </div>
    </div>
    <div class="Form--Boxed" >
      <div class="field mb-10">
        <div class="flex items-center justify-between">
          <label for="webhook">Webhook Secret Key:</label>
          <button @click="secretShow = !secretShow">{{ secretShow ? 'Nascondi' : 'Mostra' }}</button>
        </div>
        <div>
          <input  :type="secretShow ? 'text' : 'password'" id="webhook-secret" disabled :class="['flex-1 mb-2']" v-model="settings.webhook_secret.value">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from '../../../api'

export default {
  name: 'Webhook',
  props: {
   settings: Object,
   academySettings: Object
  },
  data() {
    return {
      secretShow: false
    }
  },
  computed: {
   
  },
  mounted() {
    
  },
  methods: {

    saveSetting(setting, settingType, reload) {
      this.$emit('saveSet', '', setting, settingType, reload);
    },

    
  }
}
</script>

<style lang="scss" scoped>
</style>
import api from './instance'

/**
* create message
*/
const create = (message) => (
  api.post(`/messages`, message )
    .then(response => response.data)
)

export default {
  create
}
<template>
  <div class="LecturePage__Main__Body__Scorm">
    <iframe
      :src="launchLink"
      frameborder="0">
    </iframe>
  </div>
</template>

<script>
export default {
  props: {
    lecture: Object,
    content: Object,
    launchLink: String,
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>
<template>
  <div class="EditQuiz__QuestionsList">
    <h4 class="mb-4">{{ t('pages.course.questions_list') }}</h4>
    <ul class="List--Lined">
      <li v-for="question in questions" :key="question.id" >
        <div v-if="editedQuestion && editedQuestion.id === question.id">
          <!-- <input v-model="editedQuestion.body" v-on:keyup.enter="saveQuestion(editedQuestion)" type="text" />
          <button @click="saveQuestion(editedQuestion)" class="Btn Btn--Save">{{t('actions.save')}}</button> -->
          <vue-editor 
            :id="`editor${editedQuestion.id}`"
            v-model="editedQuestion.body" 
            :editorToolbar="customToolbar"
            class="mb-4"
          >
          </vue-editor>

          <button @click="saveQuestion(editedQuestion)" class="Btn Btn--Save">
            Save Question
          </button>
        </div>
        <div v-else class="flex items-center">
          <span class="flex-1" v-html="question.body"></span>
          <button @click="editQuestion(question)" class="Btn Btn--Edit Btn--Icon mr-2">
            <svg height="12" width="12" class="fill-current"> 
              <use xlink:href="#icon-edit-pencil"></use>
            </svg>
          </button>
          <button @click="removeQuestion(question)" class="Btn Btn--Delete Btn--Icon">
            <svg height="12" width="12" class="fill-current"> 
               <use xlink:href="#icon-close"></use>
            </svg>
          </button>
        </div>
        <ul class="pt-4 pl-4 pb-2">
          <li v-for="answer in question.answers" :key="answer.id" class="mt-1 mb-1" >
            <div v-if="editedAnswer && editedAnswer.id === answer.id" class="flex">
              <input type="checkbox" v-model="editedAnswer.correct">
              <input v-model="editedAnswer.body" v-on:keyup.enter="saveAnswer(question, editedAnswer)"  type="text"/>
              <button @click="saveAnswer(question, editedAnswer)" class="Btn Btn--Save">{{t('actions.save')}}</button>
            </div>
            <div v-else class="flex">
              <span class="flex-1">{{ answer.correct ? '✓ ' : '𝘹 ' }} {{ answer.body }} </span>
              <button @click="editAnswer(answer)" class="Btn Btn--Edit Btn--Icon mr-2">
                <svg height="12" width="12" class="fill-current"> 
                  <use xlink:href="#icon-edit-pencil"></use>
                </svg>
              </button>
              <button @click="removeAnswer(question, answer)" class="Btn Btn--Delete Btn--Icon">
                <svg height="12" width="12" class="fill-current"> 
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
            </div>
          </li>
        </ul>
        <div class="flex items-center mb-4" v-if="selectedQuestion === question.id" >
          <input type="checkbox" id="checkbox" v-model="newAnswer.correct">
          <input 
            v-model="newAnswer.body" 
            v-on:keyup.enter="createAnswer(question)"
            placeholder="add answer"
            class="ml-2 mr-2"
            type="text"
            />
          <button  @click="createAnswer(question)" class="Btn Btn--Save">{{t('actions.save')}}</button>
        </div>
        <button  @click="activateInput(question)" class="Btn Btn--Save" >{{t('pages.course.add_new_answer')}}</button>

      </li>
    </ul>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '../../../api'
import { VueEditor } from "vue2-editor";


export default {
  
  props: {
    quiz: Object
  },

  data() {
    return {
      selectedQuestion: null,
      newAnswer: {
        correct: false,
        body: "",
        question_id: null,
      },
      editedQuestion: null,
      editedAnswer: null,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]]
    }
  },

  components: {
    VueEditor
  },

  computed: {
    ...mapGetters({
      questions: "questions"
    })
  },

  mounted() {
    this.getQuestions(this.quiz.questions)
  },

  methods: {
    
    ...mapActions({
      getQuestions: 'getQuestions',
      updateQuestion: 'updateQuestion',
      deleteQuestion: 'deleteQuestion',
      addAnswer: 'addAnswer',
      updateAnswer: 'updateAnswer',
      deleteAnswer: 'deleteAnswer'
    }),

    activateInput(question){
      this.selectedQuestion = question.id
    },

    createAnswer(question){
      
      if (this.newAnswer.body != "") {
        let self = this
        api.Quiz.createAnswer(this.quiz, question, this.newAnswer)
        .then((res) => {
          this.addAnswer({question: question, answer: res})
          this.newAnswer.body = ""
          this.newAnswer.correct = false
        })
        .catch(e =>{
         
        })
      }
    },

    editQuestion(question) {
      this.editedQuestion = question
    },

    saveQuestion(question){
      api.Quiz.updateQuestion(this.quiz, question)
      .then((res) => {
        this.editedQuestion = null
        this.updateQuestion(question)
      })
    },

    removeQuestion(question) {
      let quiz = this.quiz
      if(confirm( `You are about to delete the question \n Deleting the question will remove all the data related to the question \n Are you really sure?` )){
        this.deleteQuestion({ quiz, question })
      }
    },

    editAnswer(answer) {
      this.editedAnswer = answer
    },

    saveAnswer(question, answer){
      api.Quiz.updateAnswer(this.quiz, question, answer)
      .then((res) => {
        this.editedAnswer = null
        this.updateAnswer({question, answer})
      })
    },

    removeAnswer(question, answer) {
      let quiz = this.quiz
      if(confirm( `You are about to delete the answer \n Are you really sure?` )){
        this.deleteAnswer({ quiz, question, answer })
      }
    },

  },
  
}
</script>
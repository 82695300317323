<template>
  <div>
     <div class="flex mb-4">
        <BackButton :href="`/admin/pages`" :cssClass="'mr-4'" />
        <h2 class="text-xl  mb-2">{{ page.name.replace(/_/g, ' ') }}</h2>
     </div>

    <div class="editor mb-4">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-format-bold"></use>
            </svg>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-format-italic"></use>
            </svg>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-format-underline"></use>
            </svg>
          </button>


          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <strong>p</strong>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            <strong>H1</strong>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            <strong>H2</strong>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <strong>H3</strong>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <strong>ul</strong>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <strong>ol</strong>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
          >
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-code"></use>
            </svg>
          </button>

          <button
            class="menubar__button"
            @click="commands.horizontal_rule"
          >
            <strong>hr</strong>
          </button>

          <button
            class="menubar__button"
            @click="commands.undo"
          >
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-step-backward"></use>
            </svg>
          </button>

        </div>
      </editor-menu-bar>

      <hr>

      <editor-content class="editor__content p-4" :editor="editor" />

    </div>

    <button 
      class="Btn Btn--Save"
      @click="updatePage()">
      save
    </button>

  </div>
</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'

import {
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Underline,
  History,
} from 'tiptap-extensions'


export default {
  props: {
    page: Object
  },
  components: {
    EditorContent,
    EditorMenuBar,
    BackButton
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Italic(),
        new Underline(),
        new History(),
      ],
      content: this.page.body,
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {

    updatePage(){
      let body = this.editor.getHTML()
      api.Page.update(this.page, body)
      .then( res => {
        console.log('updated')
      })
    }
  }
}
</script>

<style scoped>
 .editor__content{
   background-color: #fff;
 }
</style>
import api from "./instance";
import lecture from "./lecture";

/**
 * get user activities
 */
const create = (userActivity) =>
  api
    .post(`/admin/user_activities`, userActivity)
    .then((response) => response.data);

const getUserActivities = (lecture, user) =>
  api
    .get(`/admin/user_activities?lecture_id=${lecture.id}&user_id=${user.id}`)
    .then((response) => response.data);
const getLectureActivities = (params) =>
  api
    .get(`/admin/user_activities/lectures`, { params })
    .then((response) => response.data);

const getQuizActivities = (params) =>
  api
    .get(`/admin/user_activities/quizzes`, { params })
    .then((response) => response.data);

const updateDuration = (lecture_id, duration) =>
  api
    .put(`/user_activities/update_duration`, {
      lecture_id,
      duration,
    })
    .then((response) => response.data);

const getUserGlobalLectures = (params) =>
  api
    .get(`/admin/user_activities/global_lectures`, { params })
    .then((response) => response.data);

const getCourseDetailsInfo = (params) =>
  api
    .get(`/admin/user_activities/course_details`, { params })
    .then((response) => response.data);

const updateUserActivity = (userActivity) =>
  api
    .put(`/admin/user_activities/${userActivity.id}`, userActivity)
    .then((response) => response.data);

const checkIfSkipped = (lecture_id, user_id) =>
  api
    .get(`/user_activities/check_if_skipped`, {
      params: { lecture_id, user_id },
    })
    .then((response) => response.data);

const getDailyHours = (params) =>
  api
    .get(`/admin/user_activities/daily_hours`, { params })
    .then((response) => response.data);

export default {
  create,
  getUserActivities,
  updateDuration,
  getLectureActivities,
  getQuizActivities,
  getUserGlobalLectures,
  getCourseDetailsInfo,
  updateUserActivity,
  checkIfSkipped,
  getDailyHours,
};

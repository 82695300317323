<template>
  
  
  <div>
    <div class="Player" ref="player">
      <div class="Player__Container">
        <div id="player"></div>
      </div>
    </div>

    <modal name="end-lecture-modal" @opened="startCount" @closed="stopCount">
      <div class="Modal__Body flex items-center justify-center h-full text-center p-10">
        <div class="LectureEnd" >
          <p class="text-xl mb-4">{{ t('pages.lecture.lecture_completed')}}: <b>{{lecture.name}}</b></p>
          <p class="text-2xl mb-4" v-if="course.show_percentage">
            {{ t('pages.lecture.percentage_completed')}} 
            <span><strong class="text-3xl">{{Math.floor(resp.percentageCompleted)}}%</strong></span> 
            {{ t('pages.lecture.of_the_course')}}!
          </p>
          <p v-if="course.points && prizesActive" class="text-lg mb-4">
            {{ t('pages.lecture.congratulations_points', { course_points: course.points }) }}
          </p>
          <div v-if="next" id="countdown">
            <span id="countdown-number">{{countDown}}</span>
            <svg>
              <circle r="18" cx="20" cy="20"></circle>
            </svg>
          </div>
          <a  v-if="resp.lastCompleted && resp.completedCourse && course.certification" 
              class="Btn Btn--Text mb-2"
              :href="`/certifications/${resp.completedCourse.unique_identifier}`"
              target="_black">{{ t('pages.lecture.download_certification')}}
               <svg height="16" width="16" class="fill-current ml-2"> 
                <use xlink:href="#icon-download"></use>
              </svg>
            </a>
          <div class="flex justify-center">
            <a  :href="lecture.id" class="Btn Btn--Primary Btn--Arrow mr-2 ml-2" @click="stopCount" >
              {{ t('pages.lecture.restart')}} 
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-reload"></use>
              </svg>
            </a>
            <a v-if="next" :href="next.id" class="Btn Btn--Primary Btn--Arrow mr-2 ml-2" @click="stopCount" >
              {{ t('pages.lecture.next_lecture')}} 
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
            <a v-if="nextCourse && !next" :href="nextCourse.id" class="Btn Btn--Primary Btn--Arrow mr-2 ml-2" @click="stopCount" >
              {{ t('pages.lecture.next_course')}} 
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </modal>

    <modal name="quiz-modal" class="v--modal--prevent-close">
      <div class="Modal__Body flex items-center justify-center h-full text-center p-5">
        <quiz :lectureQuiz="quiz" :course="course" :next="next" />
      </div>
    </modal>

  </div>



</template>

<script>
import api from '../../api'
import { global as EventBus } from '../../js/utils/eventBus'; 
import quiz from '../quiz/show'
import { mapState, mapActions, mapGetters } from 'vuex'


export default {
  name: 'Vimeo',
  components: { 
    quiz
  },

  props: {
    videoId: String,
    lecture: Object,
    course: Object,
    blockForward: Boolean,
    next: String,
    keyId: String,
    videoIndex: Number,
    quiz: Object,
    videoProgress: Number,
    nextCourse: String,
    prizesActive: Boolean,
  },

  data() {
    return {
      paused: true,
      resp: Object,
      countDown: 10,
      muted: false,
      previous: 0,
      secondsSkipped: '',
      lastSkippedPoint: 0,
      player: null,
      interval: null,
    }
  },


  computed: {
    ...mapGetters({
      quizResult: "quiz",
    }),
  },


  mounted() {
    // Dynamically load the YouTube IFrame API
    if (!window.YT) {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set a function to be called when the API is ready
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
    } else {
      this.onYouTubeIframeAPIReady();
    }

    console.log('zioca')
    console.log(this.videoId)
  },

  watch: {
    quizResult: {
      handler(newVal, oldVal) {
        console.log(`Quiz passed: ${newVal.passed}. Old val: ${oldVal.passed}`)
        if (newVal.passed) {
          api.CompletedLecture.create(this.lecture.id)
            .then((res) => {
              this.resp = res
              console.log(res)
          })
        }
      },
      deep: true  
    }
  },

  methods: {
    onYouTubeIframeAPIReady() {
      this.player = new YT.Player('player', {
        height: '360',
        width: '640',
        videoId: this.videoId,  // Use the videoId prop
        playerVars: {
          'controls': this.blockForward ? 0 : 1,
          'rel': 0,
          'disablekb': this.blockForward ? 1 : 0,
        },
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange
        }
      });
    },
    onPlayerReady(event) {
      event.target.playVideo();
    },
    onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING && !this.done) {
        //counting duration
        console.log('Playing')
        this.interval = setInterval(() => {
          this.updateDuration(5)
        }, 5000)

      }
      if (event.data == YT.PlayerState.ENDED) {
       this.handleEndLecture()
      }
      if (event.data == YT.PlayerState.PAUSED) {
        //stop counting duration
        console.log('Paused')
        clearInterval(this.interval)
      }

      if (event.data == YT.PlayerState.BUFFERING) {
        //stop counting duration
        console.log('Buffering')
        clearInterval(this.interval)
      }
    },
    stopVideo() {
      this.player.stopVideo();
    },
    handleEndLecture() {
      console.log('End of lecture')
      clearInterval(this.interval)
      if (!this.quiz || (this.quiz && this.quiz.questions.length === 0) ) {
        api.CompletedLecture.create(this.lecture.id)
          .then((res) => {
            this.resp = res
            this.$modal.show('end-lecture-modal', {
              width: '100%',
              maxWidth: '600px',
              adaptive: true
          });
        })
      } else {
        this.$modal.show('quiz-modal', {
              width: '100%',
              maxWidth: '600px',
              adaptive: true
        });
      }
    },
    startCount() {
      if (this.countDown > 0 && this.next) {
        this.timeout = setTimeout(() => {
            this.countDown -= 1;
            this.startCount()
        }, 1000)
      } else {
        if (this.next) {
          window.location.href = this.next.id
        }
          
      }
    },

    stopCount() {
      clearTimeout(this.timeout)
      this.countDown = 10;
    },

    nextVideo (event) {
      this.$emit('nextVideo', 'someValue')
    },

    async updateDuration(interval) {
     try {
      await api.UserActivity.updateDuration(this.lecture.id, interval)
     } catch (error) {
      console.warn(error)
     }

    },
    
  }

}
</script>


<style scoped lang="scss">

#countdown {
  position: relative;
  margin: auto;
  margin-bottom: 24px;
  height: 40px;
  width: 40px;
  text-align: center;
}


#countdown-number {
  display: inline-block;
  line-height: 40px;
  color: var(--color-primary);
}


#countdown svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: theme('colors.primary');
  fill: transparent;
  animation: countdown 10s linear infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

.Player {
  position: relative;
  &__Container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__Controls {
    transition: all .3s;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    padding: 8px;

    @media (min-width: 48em) {
      opacity: 0;
      visibility: hidden;
    }

    button {
      display: inline-flex;
      align-items: center;
      padding: 4px;
      background: rgba(255, 255, 255, .2);
      border-radius: 7px;
      font-size: 13px;
      transition: background .2s;
      &:hover {
        background: rgba(255, 255, 255, .4);
      }
    }
  }

  &:hover {
      .Player__Controls {
        opacity: 1;
        visibility: visible;
      }
    }
}
</style>
<template>
  <div>
    <div class="md:flex mb-4 justify-between gap-4">
      <div class="flex flex-1">
        <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_undefined`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
        <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
      </div>
      <div class="Dropdown mt-2 md:mt-0">
        <button :class="['Dropdown__Trigger w-full', {open: orderByOpen}]" @click="toggleOrder">
          {{t('filter.order')}}
          <svg height="18" width="18" class="fill-current ml-4">
              <use :xlink:href="`#icon-cheveron-${orderByOpen ? 'up' : 'down'}`"></use>
            </svg>
        </button>
         <transition name="fade">
          <div class="Dropdown__Body orderBy" v-if="orderByOpen">
            <button v-for="order in orderOptions" :key="order.value" :class="['Btn Btn--Filter w-full mb-2', {active: selectedOrder === order.value}]" @click="selectOrderBy(order.value)" >
              {{t(`filter.${order.label}`)}}
            </button>
            <div class="text-right">
              <button class="Btn Btn--Save" @click="getCourses">{{t('actions.save')}}</button>
            </div>
          </div>
         </transition>
      </div>
      <div class="Dropdown mt-2 md:mt-0" v-if="showFilters">
        <button :class="['Dropdown__Trigger w-full', {open: filtersOpen}]" @click="toggleFilter">
          {{t('filter.title')}}
          <svg height="18" width="18" class="fill-current ml-4"> 
              <use :xlink:href="`#icon-cheveron-${filtersOpen ? 'up' : 'down'}`"></use>
            </svg>
        </button>
        <transition name="fade">
          <div class="Dropdown__Body filters" v-if="filtersOpen">
            <template   v-if="!type && features.paths" >
              <label for="" class="text-grayScale-dark text-lg mb-2 block font-semibold">{{t('filter.by_type')}}</label>
              <div class="flex gap-2 mb-4">
                <button  :class="['Btn Btn--Filter', {active: selectedTypes.includes('courses')}]" @click="addType('courses')">
                  <svg height="14" width="14" class="fill-current mr-2"> 
                    <use xlink:href="#icon-book-reference"></use>
                  </svg>
                  {{t('global.courses.p')}}
                </button>
                <button  :class="['Btn Btn--Filter', {active: selectedTypes.includes('paths')}]" @click="addType('paths')">
                  <svg height="14" width="14" class="fill-current mr-2"> 
                    <use  xlink:href="#icon-view-tile"></use>
                  </svg>
                  {{t('global.paths.p')}}
                </button>
              </div>
            </template>
            <template  v-if="hasLacerbaCourses && features.filter_by_author">
              <label class="text-grayScale-dark text-lg mb-2 block font-semibold">{{t('filter.by_author')}}</label>
              <div class="flex flex-wrap gap-2 mb-4">
                <button  @click="addAuthor('lacerba')" :class="['Btn Btn--Filter', {active:  selectedAuthor.includes('lacerba')}]">
                      Lacerba
                </button>
                <button  @click="addAuthor('other')" :class="['Btn Btn--Filter', {active:  selectedAuthor.includes('other')}]">
                     {{features.name}}
                </button>
              </div>
            </template>
            <template   v-if="subjects.length > 0">
              <label class="text-grayScale-dark text-lg mb-2 block font-semibold">{{t('filter.by_subject')}}</label>
              <div class="flex flex-wrap gap-2 mb-4">
                <button v-for="area in subjects" :key="area.id" @click="addArea(area)" :class="['Btn Btn--Filter', {active: selectedAreas.includes(area.id)}]">
                      {{area.name}}
                </button>
              </div>
            </template>
            <template   v-if="areas40.length > 0">
              <label class="text-grayScale-dark text-lg mb-2 block font-semibold">{{t('filter.by_area_40')}}</label>
              <div class="flex flex-wrap gap-2 mb-4">
                <button v-for="area in areas40" :key="area.id" @click="addArea(area)" :class="['Btn Btn--Filter', {active: selectedAreas.includes(area.id)}]">
                      {{area.name}}
                </button>
              </div>
            </template>
            <template v-if="availableLanguages.length > 0">
              <label class="text-grayScale-dark text-lg mb-2 block font-semibold">{{ t('filter.by_language') }}</label>
              <div class="flex flex-wrap gap-2 mb-4">
                <button v-for="lang in languages" :key="lang.code"
                        :class="['Btn Btn--Filter', { active: selectedLanguages.includes(lang.code) }]"
                        @click="toggleLanguage(lang.code)">
                  {{ lang.code.toUpperCase() }}
                </button>
                <button  :class="['Btn Btn--Filter', { active: selectedLanguages.includes('') }]" @click="toggleLanguage('')">Default</button>
              </div>
            </template>
            <div class="text-right">
              <button class="Btn--Text text-danger mr-2" @click="removeFilters" v-if="selectedTypes.length > 0 || selectedAreas.length > 0 || selectedAuthor.length > 0 || selectedLanguages.length > 0">{{t('filter.remove')}}</button>
              <button class="Btn Btn--Save" @click="getCourses">{{t('actions.save')}}</button>
            </div>
            
          </div>
        </transition>
      </div>
       
    </div>
    <div v-if="loading" class="LoadingBox mt-4 text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-else-if="error != ''" class="Box Box--Clear">
      <p class="text-danger">{{error}}</p>
    </div>
    <div v-else>
      <div v-if="allCourses.length > 0">
        <ul class="Courses__List grid sm:grid-cols-2 gap-4 mb-6">
          <li v-for="course in allCourses" :key="course.id">
            <courseThumbnail :course="course"  :admin="true" :user="user" /> 
          </li>
        </ul>
        <div class="text-center mt-2">
          <paginate
            v-model="page"
            :page-count="pagy.pages"
            :click-handler="pageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </div>
      <div v-else class="mt-4 text-center">
        <p>{{t('pages.courses.no_courses')}}</p>
       </div>
    </div>
    </div>
    
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../../api'
import courseThumbnail from '../../commons/courseThumbnail'


export default {

  props: {
    user: Object,
    areas: Array,
    type: String,
    features: Object,
    hasLacerbaCourses: Boolean,
    academyName: String,
    languages: Array
  },

  components: {
    courseThumbnail
  },

  data() {
    return {
      page: 1,
      courses: [],
      loading: false,
      pagy: {},
      search: '',
      error: '',
      filtersOpen: false,
      orderByOpen: false,
      selectedTypes: [],
      selectedAreas: [],
      selectedOrder: 'az',
      selectedAuthor: [],
      orderOptions: [
        {
          label: "a_z",
          value: "az"
        },
        {
          label: "z_a",
          value: "za"
        }
        //{
          //label: "Dal più recente",
          //value: "desc"
        //},
        //{
          //label: "Dal meno recente",
          //value: "asc"
        //},
      ],
      selectedLanguages: []
    }
  },
  
  computed: {
    ...mapState({ 
      allCourses: state => state.course.allCourses
    }),

    availableLanguages() {
      return this.languages || [];
    },

    subjects() {
      return this.areas.filter(el => el.area_type === 'subject' || el.area_type === 'home')
    },

    areas40() {
      return this.areas.filter(el => el.area_type === 'formazione-40')
    },
    showFilters() {
      return  (
        (!this.type && this.features.paths) || 
        (this.hasLacerbaCourses && this.features.filter_by_author) ||
        this.subjects.length > 0 || 
        this.areas40.length > 0 ||
        this.availableLanguages.length > 0
      )
       
    }

  },
  mounted() {
   this.getCourses()
  },
  methods: {

     ...mapActions({
      getAllCourses: 'getAllCourses'
    }),

    toggleLanguage(langCode) {
      const index = this.selectedLanguages.indexOf(langCode);
      if (index === -1) {
        this.selectedLanguages.push(langCode);
      } else {
        this.selectedLanguages.splice(index, 1);
      }
    },
      
    getCourses() {
      console.log(this.selectedAreas)
      this.loading = true
      const type = this.type ? this.type : this.selectedTypes.length === 2 ? '' : this.selectedTypes[0]
      const author =  this.selectedAuthor.length === 2 ? '' : this.selectedAuthor[0]
      api.Course.getCourses({
        page: this.page, 
        search: this.search, 
        type,
        order: this.selectedOrder,
        areas: this.selectedAreas,
        author,
        language: this.selectedLanguages,
        all_courses: this.academyName === 'lacerba-api-fad'
      } ).then(res => {
        this.getAllCourses(res.courses)
        this.pagy = res.pagy
        this.loading = false
        this.filtersOpen = false
        this.orderByOpen = false
      }).catch( err => {
        this.error = err.message
        this.loading = false
      })
    },

    pageCallback(pageNum){
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getCourses({
        page: this.page
      })
    },
   
   getCoursesFromSearch(){
      this.page = 1
      this.getCourses()
    },

    addArea(area) {
      if (this.selectedAreas.indexOf(area.id) === -1 ) {
         this.selectedAreas.push(area.id)
      } else {
        this.selectedAreas.splice(this.selectedAreas.indexOf(area.id), 1)
      }
    },

    addType(type) {
      if (this.selectedTypes.indexOf(type) === -1 ) {
        this.selectedTypes.push(type)
      } else {
        this.selectedTypes.splice(this.selectedTypes.indexOf(type), 1)
      }
    },

    addAuthor(author) {
      if (this.selectedAuthor.indexOf(author) === -1 ) {
        this.selectedAuthor.push(author)
      } else {
        this.selectedAuthor.splice(this.selectedAuthor.indexOf(author), 1)
      }
    },

    removeFilters() {
      this.selectedTypes = []
      this.selectedAreas = []
      this.selectedAuthor = []
      this.selectedLanguages = []
    },
    
    selectOrderBy(value) {
      this.selectedOrder = value
    },

    toggleOrder() {
      this.orderByOpen = !this.orderByOpen
      this.filtersOpen = false
    },
    toggleFilter() {
      this.filtersOpen = !this.filtersOpen
      this.orderByOpen = false
    }
     
  }
}
</script>
import api from "./instance";

/**
 * update content
 */
const update = (content) =>
  api
    .put(`/admin/contents/${content.id}`, content)
    .then((response) => response.data);

/**
 * remove content
 */
const destroy = (content) =>
  api.delete(`/admin/contents/${content.id}`).then((response) => response.data);

const addExternalVideo = (params) =>
  api
    .post(`/admin/contents/add_external_video`, params)
    .then((response) => response.data);

export default {
  update,
  destroy,
  addExternalVideo,
};

import api from './instance'

const getAcademies = (params ) => (
  api.get(`/admin/academies.json`, { params } )
    .then(response => response.data)
)

const createAcademies = (params ) => (
  api.post(`/admin/general`, { params } )
    .then(response => response.data)
)

const showAcademy = (params ) => (
  api.get(`/admin/${params.tenant}.json`, { params } )
    .then(response => response.data)
)

const getCourses = (params ) => (
  api.get(`/admin/courses.json`, { params } )
    .then(response => response.data)
)

const addCourses = (params ) => (
  api.get(`/admin/add_courses`, { params } )
    .then(response => response.data)
)

const removeCourses = (params) => (
  api.get(`/admin/remove_courses`, {params})
  .then(response => response.data)
)

export default {
  getAcademies,
  showAcademy,
  createAcademies,
  getCourses,
  addCourses,
  removeCourses
}
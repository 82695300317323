<template>
<div class="Settings">
  <h2 class="text-xl mb-8">{{t('settings.academy')}}</h2>
  <div class="Settings InnerNav">
    <div class="InnerNav__Side">
      <ul>
        <li :class="{active: ''}">
          <router-link to="/branding">Branding</router-link>
        </li>
        <li :class="{active: ''}" v-if="zoomEnabled || externalScriptsEnabled">
          <router-link to="/zoom">Zoom</router-link>
        </li>
        <li :class="{active: ''}">
          <router-link to="/login">{{t('global.login_or_registration')}}</router-link>
        </li>
        <li :class="{active: ''}">
          <router-link to="/settings">{{t('global.settings')}}</router-link>
        </li>
        <li :class="{active: ''}" v-if="customNotificationsEnabled">
          <router-link to="/notifications">{{t('global.notification.p')}}</router-link>
        </li>
        <li :class="{active: ''}" v-if="gamificationEnabled">
          <router-link to="/gamification">{{t('global.gamification.setting')}}</router-link>
        </li>
        <li :class="{active: ''}" v-if="groupsEnabled">
          <router-link to="/groups">{{t('global.groups')}}</router-link>
        </li>
        <li :class="{active: ''}" v-if="settings.webhook_endpoint && settings.webhook_enabled">
          <router-link to="/webhook">{{t('global.webhooks')}}</router-link>
        </li>
        <li :class="{active: ''}">
          <router-link to="/pages">{{t('global.pages')}}</router-link>
        </li>
      </ul>
    </div>
    
    <div class="InnerNav__Main">
      <router-view 
        :settings="settings" 
        :features="features"
        :styles="styles"
        :zoomUsers="zoomUsers"
        :languages="languages"
        :academySettings="academySettings" 
        @saveSet="saveSetting">
      </router-view>
    </div>
  </div>

</div>





</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import settingWithImage from '../../commons/settingWithImage'
import { global as EventBus } from '../../../js/utils/eventBus'; 
import { VueEditor } from "vue2-editor";
import { Chrome } from 'vue-color'


export default {
  props: {
    academySettings: Object,
    academyStyles: Array,
    pages: Array,
    features: Array,
    zoomUsers: Array,
    languages: Array,
  },
  components: {
    settingWithImage,
    'chrome-picker': Chrome,
    VueEditor
  },
  data() {
    return {
      extScripts: '',
      newSetting: {}
    }

  },
  computed: {
    ...mapGetters({
      settings: "academySettings",
      styles: "academyStyles"
    }),

    zoomEnabled(){
      return this.features.find(x => x.name === 'zoom') && this.features.find(x => x.name === 'zoom').value === 'true'
    },

    customNotificationsEnabled(){
      return this.features.find(x => x.name === 'custom_notifications') && this.features.find(x => x.name === 'custom_notifications').value === 'true'
    },

    gamificationEnabled(){
      return this.features.find(x => x.name === 'gamification') && this.features.find(x => x.name === 'gamification').value === 'true'
    },

    groupsEnabled(){
      return this.features.find(x => x.name === 'groups') && this.features.find(x => x.name === 'groups').value === 'true'
    },

    externalScriptsEnabled(){
      return this.settings.ext_script
    },
    
  },
  mounted() {
    this.getSettings(this.academySettings)
    this.getStyles(this.academyStyles)
  },
  methods: {
    ...mapActions({
      getSettings: 'getSettings',
      getStyles: 'getStyles',
      updateAcademySetting: 'updateAcademySetting',
      updateStyle: 'updateStyle'
    }), 
    saveSetting(evt, setting, settingType, reload, apply) {
      const target = event.currentTarget ;
      EventBus.emit('loader:local:start', { elem: target })
      if (!setting.name) {
        setting.name = settingType
      }
      console.log(evt, setting, settingType, reload, apply)
      setting.apply = apply
      api.AcademySetting.create(setting)
      .then( res => {
        console.log(setting, settingType);
        setTimeout( e => {
          console.log(EventBus)
          EventBus.emit('loader:local:end', { elem: target })
        }, 1000)
        this.updateAcademySetting({setting, settingType })
        this.newSetting = []
        if (settingType == 'webhook_endpoint') this.validateEndpoint()
        if (reload) location.reload()
      }).catch(err => {
         EventBus.emit('loader:local:end', { elem: target })
         this.$toastr.e(this.t('actions.error'));
      })  
    },

    async validateEndpoint() {
      try {
        const res = await api.Webhook.testEndpoint(this.settings.webhook_endpoint.value)
        console.log(res)
        location.reload()
      } catch (error) {
        console.error(error)
      }
    }
  
    

  }

}
</script>

import api from "./instance";

/**
 * Create a comment
 */
const create = (lectureId) =>
  api.post(`${lectureId}/completed`).then((response) => response.data);

const markAsCompleted = (lectureId, userId) =>
  api
    .post(`/admin/lectures/${lectureId}/mark_as_completed`, { user_id: userId })
    .then((response) => response.data);

export default {
  create,
  markAsCompleted,
};

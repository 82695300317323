<template>
  <div :class="[{'Comments__Header--New': reply }, {'Box Box--Clear mb-4': !reply },'Comments__Header ']">
    <h2 class="mb-4">{{ t('pages.comments.write') }}</h2>
    <textarea type="text" v-model="newComment" :placeholder="t('pages.comments.write_something')"></textarea>
    <div>
      <div class="file-upload mt-2 mb-2">
        <input type="file" ref="fileInput" @change="handleFileUpload" multiple style="display: none;">
        <button class="flex items-center gap-2" @click="$refs.fileInput.click()" :disabled="files.length >= 3">
          <svg height="14" width="14" class="fill-current"> 
            <use xlink:href="#icon-attachment"></use>
          </svg>
          <strong>{{ t('pages.comments.attach') }}</strong>
        </button>
        <p class="mt-2" v-if="files.length > 0">{{ files.length }} {{ t('pages.comments.files_attached') }}</p>
      </div>
      <div v-if="files.length > 0" class="file-preview mt-2 mb-2">
        <div v-for="(file, index) in files" :key="index" class="file-item">
          <span>{{ file.name }}</span>
          <button class="Btn Btn--Icon Btn--Delete ml-2" @click="removeFile(index)">
            <svg height="14" width="14" class="fill-current"> 
                <use xlink:href="#icon-close"></use>
              </svg>
          </button>
        </div>
      </div>
    </div>
    <button class="Btn Btn--Save" @click="saveComment()">{{ t('pages.comments.send') }}</button>
  </div>
</template>

<script>


import api from '../../api'
import { global as EventBus } from '../../js/utils/eventBus';



export default {
  props: {
    reply: Boolean,
    course: Object,
    lecture: Object,
    parent: Object
  },
  data() {
    return {
      files: [],
      newComment: '',
    }
  },

  computed: {
  
  },
  methods: {

    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      const availableSlots = 3 - this.files.length;
      const filesToAdd = newFiles.slice(0, availableSlots);
      this.files = [...this.files, ...filesToAdd];
      
      if (newFiles.length > availableSlots) {
        this.$toastr.w(`Only ${availableSlots} file(s) added. Max limit reached.`);
      }
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    saveComment() {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })

      const formData = new FormData();
      formData.append('comment[course_id]', this.course?.id);
      if (this.lecture?.id) {
        formData.append('comment[lecture_id]', this.lecture.id);
      }
      formData.append('comment[parent_id]', this.parent?.id);
      formData.append('comment[body]', this.newComment);

      this.files.forEach((file, index) => {
        formData.append(`comment[attachments][]`, file);
      });

      api.Comment.create(formData)
        .then(res => {
          console.log(res);
          if (res && res.id) {
            this.newComment = '';
            this.files = [];
            EventBus.emit('loader:local:end', { elem: target })
            window.scrollTo({ 
              top: window.scrollY + 100, 
              behavior: 'smooth' 
            })
            if (!res.parent_id) {
              this.$parent.getComments();
            } else {
              this.$parent.getChildComments();
            }
          }
        })
        .catch(err => {
          this.$toastr.e(err);
          EventBus.emit('loader:local:end', { elem: target })
        });
    }
  }

}
</script>

<style scoped>
.file-preview {
  display: flex;
  flex-wrap: wrap;
}

.file-item {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

</style>
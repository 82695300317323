import api from './instance'

const getByBadgeId = (badgeId) =>
  api
    .get(`/admin/badge_assignment_criteria?badge_id=${badgeId}`)
    .then((response) => response.data)

const create = (params) =>
  api
    .post(`/admin/badge_assignment_criteria`, { badge_assignment_criteria: params })
    .then((response) => response.data)

const update = (id, params) =>
  api
    .put(`/admin/badge_assignment_criteria/${id}`, { badge_assignment_criteria: params })
    .then((response) => response.data)

export default {
  getByBadgeId,
  create,
  update
}
import api from './instance'

/**
* Create
*/
const create = (feature) => (
  api.post(`/admin/features`, feature )
  .then(response => response.data)
)



export default {
  create
}
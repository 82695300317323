import api from '../../api'

const getters = {
  questions(state) {
    return state.questions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at) )
  }
}

const defaultState = {
  questions: []
}

export const actions = {
  
  getQuestions({ commit }, questions) {
    commit('getQuestions', questions)
  },

  addQuestion({ commit }, question) {
    commit('questionAdded', question)
  },

  updateQuestion({ commit }, question) {
    commit('questionUpdated', question)
  },

  deleteQuestion({ commit }, {quiz, question}) {
    let deletedQuestion = question
    api.Quiz.destroyQuestion( quiz, question )
    .then( () => {
      commit('questionDeleted', deletedQuestion)
    })
  },

  addAnswer({ commit }, {question, answer} ) {
    commit('answerAdded', {question, answer} )
  },

  updateAnswer({ commit }, { question, answer }) {
    commit('answerUpdated', { question, answer })
  },

  deleteAnswer({ commit }, { quiz, question, answer }) {
    api.Quiz.destroyAnswer(quiz, question, answer)
    .then(() => {
      commit('answerDeleted', { question, answer } )
    })
  },

  
}

export const mutations = {

  getQuestions(state, questions) {
    state.questions = questions
  },

  questionAdded(state, question) {
    state.questions.push(question)
  },

  questionUpdated(state, question) {
    state.questions.map(q => {
      if (q.id === question.id) {
        q = question
      }
    })
  },

  questionDeleted(state, question) {
    let questions = state.questions.filter(item => item.id !== question.id)
    state.questions = questions
  },

  answerAdded(state, {question, answer} ) {
    state.questions.map(q => {
      if (q.id === question.id) {
        question.answers.push(answer)
      }
    })
  },

  answerUpdated(state, {question, answer} ) {
    state.questions.map(q => {
      if (q.id === question.id) {
        // question.answers.push(answer)
        q.answers.map( a => {
          if (a.id === answer.id) {
            a = answer
          }
        })
      }
    })
  },

  answerDeleted(state, { question, answer } ) {
    // let questions = state.questions.filter(item => item.id !== question.id)
    // state.questions = questions
    state.questions.map(q => {
      if (q.id === question.id) {
        // question.answers.push(answer)
        question.answers = q.answers.filter(a => a.id !== answer.id )
      }
    })
  },

}

export default {
  state: defaultState,
  actions,
  mutations,
  getters: getters
}
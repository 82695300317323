<template>
  <div>

    <div class="">
      <div class="Form--Boxed mb-4">
        <editQuiz :quiz="quiz"  />
      </div>
      <div class="Form--Boxed mb-4">
        <aiQuiz  :quiz="quiz" />
      </div>
      <div class="Form--Boxed mb-4">
        <div class="EditQuiz__NewQuestion">
          <p class="font-bold text-lg mb-4">{{t('pages.course.create_quiz_manually')}}</p>
          <div class="field">
            <label>{{ t('pages.course.add_new_question') }}</label>
              <vue-editor 
                v-model="newQuestion.body" 
                :editorToolbar="customToolbar"
                class="mb-4"
              >
              </vue-editor>
              <button @click="createQuestion" class="Btn Btn--Save">
                {{t('actions.save')}}
              </button>
          </div>
          <questionsList ref="questionsList" :quiz="quiz" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions } from 'vuex'
import editQuiz from '../quiz/editQuiz'
import questionsList from '../quiz/questionsList'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus'; 
import AiQuiz from './aiQuiz.vue'



export default {
  props: {
    quiz: Object
  },
  data() {
    return {
      files: [],
      aiInputError: false,
      loading: false,
      openGenerateQuiz: false,
      editPrompt: false,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
      prompt: "",
      aiQuestions: null,
      aiAnswers: null,
      generatedQuiz: "",
      newQuestion: {
        body: ""
      },
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]]
    }
  },
  components: {
    questionsList,
    editQuiz,
    VueEditor,
    AiQuiz
  },

  watch: {
    prompt(){
      this.aiInputError = false
    }
  },
  methods: {

    ...mapActions({
      addQuestion: 'addQuestion'
    }),
    
    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      const availableSlots = 3 - this.files.length;
      const filesToAdd = newFiles.slice(0, availableSlots);
      this.files = [...this.files, ...filesToAdd];
      
      if (newFiles.length > availableSlots) {
        this.$toastr.w(`Only ${availableSlots} file(s) added. Max limit reached.`);
      }
    },

    removeFile(index) {
      const removedFile = this.files[index];
      if (removedFile.id) {
        // If the file has an ID, it's from the backend, so we track it for deletion
        this.deletedFileIds.push(removedFile.id);
      }
      this.files.splice(index, 1);
    },


    createQuestion(){
      if (this.newQuestion.body != "") {
        let self = this
        api.Quiz.createQuestion(this.quiz, this.newQuestion)
        .then((res) => {
          this.addQuestion(res);
          this.newQuestion.body = "";
          this.$refs.questionsList.activateInput(res);
        })
        .catch(e =>{
         
        })
      }
    },

    callAI(){
      if (!this.prompt){
        this.aiInputError = true
        return
      }
      this.loading = true

      //test wirth file upload
      const formData = new FormData();
      formData.append('prompt', this.prompt);
      formData.append('questions', this.aiQuestions);
      formData.append('answers', this.aiAnswers);
      formData.append('quiz_id', this.quiz.id);
      this.files.forEach((file, index) => {
        formData.append(`attachments[]`, file);
      });
      api.Quiz.createAiQuiz(
        formData
      )
      /*
      api.Quiz.createAiQuiz({
        prompt: this.prompt,
        questions: this.aiQuestions,
        answers: this.aiAnswers
      })
        */
  
      .then(res => {
          console.log(res)
          //this.aiLoading = false
          //polly ajax
          const intervalId = setInterval(() => {
            api.Quiz.checkJobStatus({
              job_id: res.job_id
            })
            .then(resp => {
              if (resp.status == 'error') {
                //this.aiError = resp.error_code
                this.$toastr.e('Error');
                this.loading = false;
                clearInterval(intervalId); // Stop polling
              } else if (resp.text) {
                this.generatedQuiz = resp.text
                this.loading = false
                clearInterval(intervalId); // Stop polling
              }
            })
            .catch(err => {
              this.$toastr.e(err);
              clearInterval(intervalId); // Stop polling if an error occurs
              this.loading = false;
            });
          }, 1500);
        } )
      .catch(e =>{
        this.loading = false
        this.$toastr.e(this.t('actions.error'));
      })
    },

    confirmQuiz(){
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Quiz.confirmAiQuix({
        quiz_id: this.quiz.id,
        quiz_text: this.generatedQuiz
      })
      .then( res => {
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        this.$modal.show(`confirm-modal`);
      }).catch( err => {
        EventBus.emit('loader:local:end', { elem: target })
        this.$toastr.e(this.t('actions.error'));
      } )
    },

    reload(){
      location.reload()
    }
  }
}
</script>

<style scoped>
  .image-placeholder {
    height: 400px;
  }
</style>
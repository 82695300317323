import { global as EventBus } from '../../js/utils/eventBus';

const defaultState = {
  allUsers: []
}

const getters = {
  allUsers(state) {
    return state.allUsers
  },
}

export const actions = {
  getAllUsers({ commit }, users) {
    commit('getAllUsers', users)
  },

  addUser({ commit }, user) {
    commit('userAdded', user)
  },
  
}

export const mutations = {

  getAllUsers(state, users) {
    state.allUsers = users
  },

  userAdded(state, user) {
    if ( state.allUsers && state.allUsers.filter(u => u.id === user.id).length === 0)  {
      state.allUsers.push(user)
    }
  },

}

export default {
  state: defaultState,
  actions,
  mutations,
  getters: getters
}
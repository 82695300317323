<template>
  <div>
    <div class="field">
      <label>Vimeo Live ID</label>
      <input 
      v-model="content.content_code" 
      class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
      type="text"
      placeholder="add vimeo live ID without url" />
    </div>
    <button 
      class="Btn Btn--Save"
      @click="updateContent(content)" >
      Save
    </button> 
  </div>
</template>

<script>
import api from '../../../api'
export default {
  props: {
    content: Object
  },
  methods: {
    updateContent(){
      api.Content.update(this.content)
    }
  }
}
</script>
import api from "./instance";

const getUsers = () => api.get(`/zoom/users`).then((response) => response.data);

const getUsersApi = () =>
  api
    .get(`https://api.zoom.us/v2/users/matteo@lacerba.io`)
    .then((response) => response.data);

/**
 * destroy
 */
const destroy = (zoomUserId) =>
  api
    .delete(`/admin/zoom_users/${zoomUserId}`)
    .then((response) => response.data);

export default {
  getUsers,
  getUsersApi,
  destroy,
};

import api from './instance'

/**
* book coach
*/
const book = (user_id=null, coach_id, startTime=null) => (
  api.post(`/book_coaches`, { user_id: user_id, coach_id: coach_id, start_time: startTime })
    .then(response => response.data)
)


export default {
  book
}
<template>
  <div>
    <template>
      <div class="catalog">
        <ais-instant-search
          :index-name="`${tenant}_index_${env}`"
          :search-client="searchClient"
        >
     
   
        <ais-configure v-if="preventSwitchLangs && userLang" :filters="`language:${userLang}`" /> 
        <div class="search-panel">
          <div class="lg:grid lg:grid-cols-4 gap-4">
            <div class="search-panel__filters-wrapper col-span-1">
              <div :class="['search-panel__filters Box Box--Clear', {'show-filters': showFilters}]">
                  <button @click="showFilters = !showFilters" class="close-filters">
                    <svg height="16" width="16" class="fill-current"> 
                      <use xlink:href="#icon-close"></use>
                    </svg>
                  </button>
                  <div class="flex justify-between items-center mb-4">
                    <h2 class="text-lg">{{t('pages.catalog.filter')}}</h2>
                     <ais-clear-refinements><span class="text-danger" slot="resetLabel"><strong>Reset</strong></span></ais-clear-refinements>
                  </div>
                  <template v-if="((hasLacerbaCourses || hasRadicalHRCourses) && hasAcademyCourses || (hasLacerbaCourses && hasRadicalHRCourses)) && showCatalogNames">
                    <ais-refinement-list attribute="external_academy_name" :count="false"  :transform-items="transformItems" class="mb-4" />
                    <hr>
                  </template>
                  <template v-if="areas > 0">
                    <h2 class="mb-2 mt-2">{{t('pages.catalog.areas')}}</h2>
                    <ais-refinement-list 
                      attribute="area" 
                      :count="false" 
                      class="mb-4"
                      :limit="8"
                      :showMore="areas > 8">
                      <template v-if="areas > 8"  v-slot:showMoreLabel="{ isShowingMore }">
                          {{ isShowingMore ? `- ${t('pages.catalog.show_less')}` : `+ ${t('pages.catalog.show_more')}`}}
                        </template>
                    </ais-refinement-list>
                    <hr>
                  </template>
                  <h2 class="mb-2 mt-2">{{t('pages.catalog.type')}}</h2>
                  <ais-toggle-refinement
                    class=""
                    attribute="path"
                    :label="t('global.paths.p')"
                    :on="true"
                    :count="false"
                  />
                  <ais-toggle-refinement
                    class=""
                    attribute="path"
                    :label="t('global.courses.p')"
                    :on="false"
                    :count="false"
                  />
                  <ais-refinement-list attribute="product_type" :count="false" class="mb-4"  />

                  <template v-if="hasCourseLangs && !preventSwitchLangs">
                    <h2 class="mb-2 mt-2">{{t('global.language')}}</h2>
                    <ais-refinement-list attribute="language" :count="false" class="mb-4"/>
                  </template>

                  <ais-configure :hitsPerPage="10" />
                  <div class="apply-filters__container">
                    <button @click="showFilters = !showFilters" class="Btn Btn--Primary apply-filters">{{t('pages.catalog.apply')}}</button>
                  </div>
              </div>
            </div>
            <div class="col-span-3">
              <div class="searchWidget widget mb-4">
                <div class="searchWidgetPreview">
                  <div class="ais-SearchBox-wrapper" id="search-wrapper">
                    <svg height="18" width="18" class="fill-current ml-4"> 
                      <use xlink:href="#icon-search"></use>
                    </svg>
                    <ais-search-box autofocus show-loading-indicator  :placeholder="t('pages.catalog.search')" class="searchbox" />
                  </div>
                  <button @click="showFilters = !showFilters" class="toggle-filters Btn Btn--Primary Btn--Icon">
                    <svg height="12" width="12" class="fill-current"> 
                      <use xlink:href="#icon-filter"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="search-panel__results">
                <ais-hits>
                  <template v-slot="{ items, sendEvent }">
                    <ul class="grid sm:grid-cols-2 gap-4">
                      <li v-for="item in items" :key="item.objectID">
                        <div :id="item.objectID" :class="['Thumbnail', {'Thumbnail--WithImage': item.image_url}]">
                          <div class="text-left mb-2">
                            <span class="Tag m-0">{{item.path ? t('global.paths.s') : t('global.courses.s')}}</span> 
                            <span v-if="item.product_type" class="Tag m-0">{{item.product_type}}</span> 
                            <span v-if="item.language" class="Tag m-0">{{item.language}}</span> 
                            <span v-if="item.points" class="Tag Tag m-0 inline-flex items-center">
                              <svg height="10" width="10" class="fill-current mr-1"> 
                                <use xlink:href="#icon-badge"></use>
                              </svg>
                              {{ item.points }}
                            </span>
                            <!-- <span v-for="(area, index) in item.area" :key="index" class="Tag m-0">{{area}}</span> -->
                          </div> 
                          <h4 class="Thumbnail__Title sm:text-xl mb-2">{{item.name}}</h4>
                          <!-- <div v-html="stringLimit(item.subtitle)" class="Thumbnail__Description mb-4"></div> -->
                          <div class="Thumbnail__Bottom flex justify-between mt-6 items-center">
                            <a :href="`/courses/${item.id}/${item.path ? 'path' : 'page'}`" class="Btn Btn--Primary Btn--Arrow">
                              {{ t('actions.go') }}
                              <svg height="16" width="16" class="fill-current"><use xlink:href="#icon-arrow-right"></use></svg>
                            </a>
                          </div> 
                          <img class="Thumbnail__Img" :src="item.image_url" alt="" v-if="item.image_url">
                        </div>
                      </li>
                    </ul>
                  </template>
                </ais-hits>
                <ais-pagination v-on:page-change="scrollUp" />
              </div>
            </div>
          </div>
        </div>
        </ais-instant-search>
      </div>
      
    </template>

  </div>
 
</template>

<script>

import { global as EventBus } from '../../js/utils/eventBus';
import courseThumbnail from '../commons/courseThumbnail'
import { mapState, mapActions } from 'vuex'
import api from '../../api'
import algoliasearch from 'algoliasearch/lite';


  export default {
    name: 'Catalog',

    props: {
     tenant: String,
     env: String,
     credentials: Object,
     academyName: String,
     hasLacerbaCourses: Boolean,
     hasAcademyCourses: Boolean,
     hasRadicalHRCourses: Boolean,
     hasCourseLangs: Boolean,
     areas: Boolean,
     user: Object,
     preventSwitchLangs: Boolean,
     academySettings: Object
    }, 
  
    components: {
      courseThumbnail
    },

    data() {
      return {
        searchClient: algoliasearch(
          this.credentials?.accountId,
          this.credentials?.apiKey
        ),
        showFilters: false
      }
    },
    
    mounted() {
      console.log("Mounted state, items:", this.items);
      console.log(this.user)
      console.log(this.academySettings)
    },


    computed: {
      
      imgPath() {
        return window.location.origin
      },

      userLang () {
        return this.user.lang
      },

      showCatalogNames() {
        return this.academySettings.show_catalog_names?.value == 'false' ? false : true;
      }
    },

    methods: {

      transformItems(items) {
        return items.map(item => ({
          ...item,
          label: 
            item.label === 'lacerba-api-fad' ? 'Corsi Lacerba' : 
            item.label === 'radical-hr-api' ? 'Corsi RADICAL HR' : 
            item.label === 'radical_hr' ? 'Corsi RADICAL HR' : 
            item.label === 'academy' ? `Corsi ${this.academyName}` :
            item.label,
        }));
      },
      
      stringLimit(subtitle){
        if( subtitle?.length > 160) {
          return `${subtitle.slice(0,160)}...`;
        }
        return subtitle;
      },
      scrollUp() {
        window.scrollTo({
          top: 110, 
          behavior: 'smooth'
        });
      }
    }

  }

</script>

<style lang="scss">
  .ais-RefinementList-showMore {
    color: theme('colors.primary');
    padding-top: 4px;
    font-weight: bold;
  }
</style>



import api from "../../api";
import { global as EventBus } from "../../js/utils/eventBus";

const defaultState = {
  files: [],
};

const getters = {
  files(state) {
    return state.areas;
  },
};

export const actions = {
  getFiles({ commit }, areas) {
    commit("getFiles", areas);
  },

  addFile({ commit }, file) {
    commit("fileAdded", file);
  },

  clearFiles({ commit }) {
    commit("getFiles", []);
  },
};

export const mutations = {
  getFiles(state, files) {
    state.files = files;
  },

  fileAdded(state, file) {
    state.files.push(file);
  },
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};

import api from "./instance";

const update = (id, params) =>
  api.put(`/admin/groups/${id}`, params).then((response) => response.data);

const addUsers = (id, params) =>
  api
    .post(`/admin/groups/${id}/add_users`, params)
    .then((response) => response.data);

const removeUsers = (id, params) =>
  api
    .post(`/admin/groups/${id}/remove_users`, params)
    .then((response) => response.data);

const getGroups = (params) =>
  api.get("/admin/groups.json", { params }).then((response) => response.data);

const updateGroupUser = (id, params) =>
  api.put(`/admin/group_users/${id}`, params).then((response) => response.data);

const addManager = (id, params) =>
  api
    .get(`/admin/group_users/${id}/add_manager`, { params })
    .then((response) => response.data);

export default {
  update,
  addUsers,
  removeUsers,
  getGroups,
  updateGroupUser,
  addManager,
};

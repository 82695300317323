const defaultState = {
  academySettings: Object,
  academyStyles: Array
}

const getters = {
  academySettings(state) {
    return state.academySettings
  },
  academyStyles(state) {
    return state.academyStyles
  }
}

export const actions = {
  getSettings({ commit }, settings) {
    commit('getSettings', settings)
  },
  getStyles({ commit }, styles) {
    commit('getStyles', styles)
  },
  updateAcademySetting({ commit }, {setting, settingType }) {
    commit('academySettingUpdated', { setting, settingType})
  },
  updateStyle({commit}, style ) {
    commit('styleUpdated', style )
  }
}

export const mutations = {
  getSettings(state, settings) {
    state.academySettings = settings
  },
  
  getStyles(state, styles) {
    state.academyStyles = styles
  },

  academySettingUpdated(state, { setting, settingType} ) {
    if (settingType != 'basicInfo' ){
      state.academySettings[settingType] = setting
    } else {
      state.academySettings.basicInfos[setting.name] = setting
    }
  },
  styleUpdated(state, style){
    state.academyStyles.map(s => {
      if (s.id === style.id) {
        s = style
      }
    })
  }
}

export default {
  state: defaultState,
  actions,
  mutations,
  getters: getters
}
import api from "../../api";
import { global as EventBus } from "../../js/utils/eventBus";

const defaultState = {
  areas: [],
};

const getters = {
  areas(state) {
    return state.areas;
  },
};

export const actions = {
  getAreas({ commit }, areas) {
    commit("getAreas", areas);
  },
};

export const mutations = {
  getAreas(state, areas) {
    state.areas = areas;
  },
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};

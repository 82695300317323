<template>
  <div>

    <countdown 
        :time="new Date(lecture.start_date).getTime() - new Date().getTime()">
        <template slot-scope="props">
          <div class="CountDown pb-5">
          <div id="timer">
            <div class="days"> <div class="numbers">{{ props.days }}</div>D</div> 
            <div class="hours"> <div class="numbers">{{ props.hours }}</div>H</div> 
            <div class="minutes"><div class="numbers"> {{ props.minutes }}</div>M</div> 
            <div class="seconds"><div class="numbers">{{ props.seconds }}</div>S</div> </div>
            <h3 class="text-2xl mt-8">LIVE: {{lecture.start_date | moment("DD/MM/YY - k:mm") }}</h3>
          </div>
        </template>
      </countdown>

      <!-- todo: se il meeting viene chiuso erronamente,far si che al nuovo start time venga riaperto il link-->
    <div v-if="lecture.lecture_info['end_time'] || timeToStart < -(3 * 3600)" class="text-center">
       <h4 v-if="timeToStart < 0" class="Box Box--Clear text-2xl mb-4">{{t('pages.lecture.meeting_ended')}}</h4>
    </div>

    <div v-else>

      

      <div class="flex justify-center mt-3" v-if="timeToStart > 43200">
          <add-to-calendar-button
            :name="lecture.name"
            :startDate="dates.startDate"
            :endDate="dates.endDate"
            :startTime="dates.startTime"
            :endTime="dates.endTime"
            :label="`${t('actions.add_to_calendar')}!`"
            :location="`${location}/courses/${course.id}/lectures/${lecture.id}`"
            options="['Google','Outlook.com','MicrosoftTeams']"
            timeZone="Europe/Rome"
            trigger="hover"
            inline
            listStyle="dropdown"
            language="it"
          >
        </add-to-calendar-button>
        </div>

      <div v-if=" timeToStart < 900 " class="text-center Box Box--Clear">
        <h4 v-if="timeToStart < 0" class="text-2xl mb-4">{{t('pages.lecture.meeting_running')}}</h4>
        <h4 v-else class="text-2xl mb-4">{{t('pages.lecture.meeting_beginning')}}</h4>
        <p class="mb-4">{{t('pages.lecture.join_instructions')}}</p>
        <a v-if="content.content_type !== 'teams'" :href="`/zoom/join?${lecture.event_type || 'meeting'}_id=${content.content_code}`" class="Btn Btn--Primary">{{t('actions.join')}}</a>
        <a v-else :href="`${content.content_code}`" target="_blank" class="Btn Btn--Primary">{{t('actions.join')}}</a>
      </div>

    </div>

  </div>
</template>

<script>

import 'add-to-calendar-button'
import moment from "moment";


export default {
  props: {
    lecture: Object,
    content: Object,
    course: Object
  },
  data() {
    return {
      timeToStart: Number,
      location: window.location.origin
    }
  },
  mounted() {
    this.updateTimeToStart();
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
  },
  computed: {
    dates() {
      return {
        startDate:  moment(this.lecture.start_date).format('YYYY-MM-DD'),
        startTime:  moment(this.lecture.start_date).format('HH:mm'),
        endDate:  moment(this.lecture.start_date).format('YYYY-MM-DD'),
        endTime:  moment(this.lecture.start_date).add(this.lecture.duration, 'minutes').format('HH:mm')
      }
    }
  },
  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
  }
}
</script>
import api from "./instance";

const getLeaderboards = (params) =>
  api
    .get(`/admin/gamification/leaderboards`, { params })
    .then((response) => response.data);

const getLeaderboard = (id) =>
  api
    .get(`/admin/gamification/leaderboards/${id}`)
    .then((response) => response.data);

const updateLeaderboard = (id, params) =>
  api
    .put(`/admin/gamification/leaderboards/${id}`, { leaderboard: params })
    .then((response) => response.data);

const uploadLeaderboardImage = (id, formData) =>
  api
    .post(`/admin/gamification/leaderboards/${id}/upload`, formData)
    .then((response) => response.data);

const deleteLeaderboard = (id) =>
  api
    .delete(`/admin/gamification/leaderboards/${id}`)
    .then((response) => response.data);

const update = (id, params) =>
  api
    .put(`/admin/gamification/prizes/${id}`, params)
    .then((response) => response.data);

const getAllBadges = (id) =>
  api
    .get(`/admin/gamification/leaderboards/${id}/badges`)
    .then((response) => response.data);

const updateTranslation = (id, params) =>
  api
    .put(`/admin/gamification/leaderboards/${id}/update_translation`, params)
    .then((response) => response.data);
    
export default {
  getLeaderboards,
  getLeaderboard,
  updateLeaderboard,
  uploadLeaderboardImage,
  deleteLeaderboard,
  update,
  getAllBadges,
  updateTranslation
};
import api from "./instance";

/**
 * create enrolls
 */
const createEnrolls = (users, course_id, enroll_all) =>
  api
    .post(`/admin/enrolls`, { users, course_id, enroll_all })
    .then((response) => response.data);

/**
 * create multiple enrolls
 */
const createMultipleEnrolls = (params) =>
  api
    .post(`/admin/enrolls/create_multiple`, params)
    .then((response) => response.data);

/**
 * update enroll
 */
const update = (enroll) =>
  api
    .put(`/admin/enrolls/${enroll.id}`, enroll)
    .then((response) => response.data);

/**
 * import enroll
 */
const importEnrolls = (course_id, s3_file_path) =>
  api
    .post(`/admin/enrolls/import`, {
      s3_file_path: s3_file_path,
      course_id: course_id,
    })
    .then((response) => response.data);

/**
 * destroy enrolls
 */
const destroyEnrolls = (users, course_ids, destroy_all) =>
  api
    .post(`/admin/enrolls/destroy_all`, { users, course_ids, destroy_all })
    .then((response) => response.data);

export default {
  createEnrolls,
  update,
  importEnrolls,
  destroyEnrolls,
  createMultipleEnrolls,
};

import api from './instance'

/**
* update style
*/
const update = (room) => (
  api.put(`/admin/rooms/${room.id}`, { room_settings: room.room_settings } )
    .then(response => response.data)
)

export default {
  update
}
// import api from '../../api'

const getters = {
  quiz(state) {
    return state.quiz;
  },

  questionsToBeAnswered(state) {
    if (state.quiz && state.quiz.questions) {
      return state.quiz.questions.filter((question) => {
        return question.answered === false;
      });
    }
  },
};

const defaultState = {
  quiz: {},
  // questions: []
};

export const actions = {
  getAttempt({ commit }, quiz) {
    commit("getAttempt", quiz);
  },

  updateCurrentAttempt({ commit }, attempt) {
    commit("updateCurrentAttempt", attempt);
  },

  updateCompletedAnswer({ commit }, answer) {
    commit("updateCompletedAnswer", answer);
  },

  updateCompletedQuiz({ commit }, pass) {
    commit("updateCompletedQuiz", pass);
  },

  resetCompletedQuestions({ commit }) {
    commit("resetCompletedQuestions");
  },

  // getQuestions({ commit }, questions) {
  //   commit('getQuestions', questions)
  // },
};

export const mutations = {
  getAttempt(state, quiz) {
    state.quiz = quiz;
  },

  updateCurrentAttempt(state, attempt) {
    state.quiz.currentAttempt = attempt;
  },

  updateCompletedAnswer(state, answer) {
    state.quiz.questions.map((question) => {
      if (question.id === answer.quiz_question.id) {
        question.answered = true;
      }
    });
  },

  resetCompletedQuestions(state) {
    state.quiz.questions.map((question) => {
      question.answered = false;
    });
  },

  updateCompletedQuiz(state, pass) {
    state.quiz.passed = pass;
  },

  // getQuestions(state, questions) {
  //   state.questions = questions
  // },
};

export default {
  state: defaultState,
  actions,
  mutations,
  getters,
};

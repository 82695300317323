<template>
  
  <div>
    <h2 class="text-3xl font-bold mb-8" >{{ t('global.settings') }}</h2>
    <form action="" class="Form--Boxed">
      <div  v-for="setting in settings" :key="setting.id">
        <label :for="setting.name">{{t(`pages.user_settings.${setting.name}`)}}</label>
        <button v-if="setting.name === 'calendar'" class="Btn Btn--Text mb-4 text-grayScale-middle" @click="openModal()">{{t(`pages.user_settings.calendar_hint`)}}?</button>
        <input type="text" :id="setting.name"  placeholder="Incolla qui il tuo URL" v-model="setting.value" >
        <button class="Btn Btn--Save mt-4" @click="updateUserSetting(setting)"> {{ t(`actions.${cta}`) }}</button>
      </div>
    </form>


     <modal name="calendar-steps" height="auto">
        <div class="v--modal--header p-4">
          <h2 class="text-xl">{{t(`pages.user_settings.calendar_hint`)}}</h2>
           <button @click="$modal.hide('calendar-steps')" class="Btn Btn--Delete Btn--Icon">
            <svg height="12" width="12" class="fill-current"> 
              <use xlink:href="#icon-close"></use>
            </svg>
          </button>
        </div>
        <div class="v--modal--body body--users p-4">
          <div v-html="t(`pages.user_settings.calendar_description`)" class="mb-4"/>
        </div>
     </modal>
  </div>

</template>

<script>

import api from '../../api'

  export default {
    name: 'UserSettings',

    components: {
    },

    props: {
      settings: Array
    }, 

    data() {
      return {
        cta: "save"
      }
    },

    computed: {
    },

    methods: {

      openModal() {
        event.preventDefault()
        this.$modal.show('calendar-steps');
      },

      updateUserSetting(setting){
        event.preventDefault()
        this.cta = "saving"
        api.UserSetting.update(setting)
        .then( res => {
          this.cta = "saved"
          setTimeout(function () {
            this.cta = "save"
          }, 3000);
          this.courseSetting = res
        })
        .catch(e =>{
          this.cta = "error"
        })
      }
    }


  }

</script>

import api from '../../api'
import { global as EventBus } from '../../js/utils/eventBus'; 

const defaultState = {
  academies: [],
}

const getters = { 
  allAcademies(state) {
    return state.academies
  },

}

export const actions = {

  getAllAcademies({ commit }, academies) {
    commit('getAllAcademies', academies)
  },

}

export const mutations = {

  getAllAcademies(state, academies) {
    state.academies = academies
  },


}

export default {
  state: defaultState,
  actions,
  mutations,
  getters
}
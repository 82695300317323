<template>
<div class="Comments" >
  <div v-if="!inLecture" class="Box Box--Clear sm:flex  items-center mb-4">
    <div>
      <div class="flex items-center">
        <BackButton :href="`/courses/${course.id}/page`" :cssClass="'mr-4'" />
        <h2 class="text-3xl">Forum</h2>
      </div>
      <h1 class="text-2xl mt-3">{{ course.name }}</h1>

    </div>
    
    <img src="../../images/illustrations/forum.svg" class="Comments__Image"/>

  </div>

  <div class="">
    <newComment :course="course" :lecture="lecture"  />
  </div>


  <div class="Comments__Body">

    <div v-for="comment in comments" :key="comment" class="mb-4 ">
      <comment :currentUser="currentUser" :comment="comment" :inLecture="inLecture"  :course="course" />
    </div>

  </div>
</div>
</template>

<script>
import newComment from '../commons/newComment'
import comment from '../commons/comment'
import BackButton from '../commons/backButton'
import api from '../../api'

export default {
  props: {
    currentUser: Object,
    course: Object,
    lecture: Object,
    inLecture: Boolean
  },
  data() {
    return {
      comments: []
    }
  },
  components: {
    newComment,
    comment,
    BackButton
  },
  mounted() {
    this.getComments()
  },
  methods: {
    getComments(){
      api.Comment.index(this.course, this.lecture)
      .then( res => {
        this.comments = res
      })
    },
  }

}
</script>

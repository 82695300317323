<template>
  <div class="Form--Boxed mb-8">  
    <h4 class="mb-4 TextTooltip">
      {{ course ? `${t('pages.users.upload_users_course')}: ${course.name}` : t('pages.users.upload_users_platform') }}
      <button :content="t('pages.users.invite_users_hint')" v-tippy class="Btn--Tooltip">
        <svg height="18" width="18" class="fill-current"> 
          <use xlink:href="#icon-information-outline"></use>
        </svg>
      </button>
    </h4>
    <div class="flex items-center justify-between">
      <a href="/admin/generate_import_csv" target="_blank" class="Btn Btn--Save--Empty mr-2">{{t('pages.users.download_csv')}}</a>
      <file-upload
          ref="uploadCsv"
          v-model="files"
          :post-action=" type === 'enroll' ? '/admin/enrolls/import' : '/admin/users/import' "
          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :data="{ course_id: (course ? course.id : nil ), upload: true }"
          :drop="true"
          @input-file="inputFile"
          input-id="uploadCsv">
          {{t('global.upload')}} csv
      </file-upload>
    </div>
    
    <div v-for="(file, index) in files" :key="index">
      <div class="progress" v-if="file.active">
        <progressBar :progress="1" :indeterminate="true" />
      </div>
      <div class="progress" v-else-if="file.success">
        <p v-if="importing">{{t('pages.users.upload_csv.processing')}}</p>
        <div  class="mt-4" v-if="users.length ===  0">
          <p>{{t('pages.users.upload_csv.no_users')}}</p>
          <p>{{t('pages.users.upload_csv.control')}}</p>
        </div>
        <div class="mt-6" v-else> 
          <p class="font-bold mb-4 text-xl">{{t('pages.users.upload_csv.importing', [users.length])}}</p>
          <div v-if="users.length >  maxUsers">
            <p class="mb-2 text-danger font-bold">{{t('pages.users.upload_csv.limit_reached', [maxUsers])}}</p>
            <p class="mb-2">{{t('pages.users.upload_csv.reduce')}}</p>
            <a class="Btn Btn--Primary" href="https://form.typeform.com/to/f6oqbrwu" target="_blank">{{t('landing.cta.contact')}}</a>
          </div>
          <div v-else>
            <button class="Btn Btn--Save" @click="saveUsers()">{{t('actions.save')}}</button>
            <p class="mt-4">{{message}}</p>
          </div>
        </div>
      </div>  
      <div class="progress text-danger mt-3" v-else-if="file.error">
        <p>{{t('pages.users.upload_csv.error')}}</p>      
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    type: String,
    course: Object,
    maxUsers: Number
  },
  data() {
    return {
      files: [],
      users: [],
      s3_file_path: '',
      message: '',
      importing: false
    }
  },
  methods: {
    inputFile(newFile, oldFile) {
      this.$refs.uploadCsv.active = true
      this.importing = true
      this.users = []
       if (newFile) { 
        if (!newFile.active && newFile.success && newFile.response) {
          console.log(newFile.response)
          this.users = newFile.response.users
          this.s3_file_path = newFile.response.s3_file_path
          this.importing = false
        }
       }   
    },
    saveUsers(){
      let self = this
      if (this.course) {
        api.Enroll.importEnrolls(this.course.id, this.s3_file_path)
        .then( res => {
          console.log(res)
          if (res.status === 'ok'){
            self.message = "Upload in progress... Puoi uscire da questa pagina. Riceverai una mail appena l'upload sarà completato!"
          }
        })
      } else {
        api.User.importUsers(this.s3_file_path)
        .then( res => {
          console.log(res)
          if (res.status === 'ok'){
            self.message = "Upload in progress... Puoi uscire da questa pagina. Riceverai una mail appena l'upload sarà completato!"
          }
        })  
      }
      
    }
  }
}
</script>
<template>
  <div>
    <div v-if="settings.registrationInfos && settings.registrationInfos.registration_type.value != 5" class="Form--Boxed  mb-4">
      <div v-for="setting in settings.registrationInfos" :key="setting.id">
        <label>{{t('settings.registration_method')}}</label>
        <div class="field" >
          <v-select
            :options="registrationOptions"
            :value="registrationType"
            @input="selection => updateSelected(setting, selection)"
          />
        </div>
        <div class="border-l-4 border-warning text-warning text-orange-700 px-4 py-1 mb-3" role="alert">
          <p class="font-bold">{{t('settings.apply.text1')}}</p>
          <p>{{t('settings.apply.text2')}}</p>
          <div class="field--inline flex items-center gap-3">
            <div class="flex items-center gap-1">
              <input type="radio" id="one" value="true" v-model="inherit"  />
              <label for="one" class="mb-0">{{ t('global.positive') }}</label>
            </div>
            <div class="flex items-center gap-1">
              <input type="radio" id="two" value="false" v-model="inherit"  />
                <label for="two" class="mb-0">{{ t('global.negative') }}</label>
            </div>
          </div>
          <p class="text-danger" v-if="selectOne && inherit === ''">{{t('settings.apply.text3')}}</p>
        </div>
        <button  class="Btn Btn--Save mb-4" @click="confirmApply(
         setting,
          'registration'
        )">{{t('actions.save')}}</button>
        <!-- <button @click="saveSetting(setting, 'basicInfo', false, true)" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>-->
      </div>
    </div>

    <div class="Form--Boxed  mb-4">
      <div class="">
        <settingWithImage :setting="settings.cover_login" settingClass="academySetting" settingType="cover_login" :name="t('settings.cover_login')" />
      </div>
    </div>

    <!-- 
      <div class="Form--Boxed  mb-4">
        <label for="">{{t('settings.invitation_link')}}</label>
        <p class=" text-grayScale-middle mb-4">{{t('settings.invitation_link_hint')}}</p>
        <div class="relative">
          <input type="text" disabled="true" id="invite-link"  v-model="invite_link">
          <button class="js-clip" :data-clipboard-text="invite_link">
            <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-clipboard"></use>
              </svg>
          </button>
        </div>
      </div>
    -->

    

    <div class="Form--Boxed  mb-4" >
      <div v-for="setting in settings.loginInfos" :key="setting.id">
        <div class="field" >
          <label>{{ t(`settings.${setting.name}`)}}
            <span :class="['font-normal text-sm ml-2', {'text-danger': setting.value.replace(/<[^>]*>?/gm, '').length > textLoginMax }]">
            ({{setting.value ? setting.value.length : 0}}/{{textLoginMax}})
          </span>
          </label>
          <p  class="text-danger text-sm mb-2" v-if="setting.value.replace(/<[^>]*>?/gm, '').length > textLoginMax">{{t('settings.too_long')}}</p>
          <vue-editor 
            :id="`editor${setting.id}`"
            v-model="setting.value" 
            :editorToolbar="customToolbar"
            class="mb-4 Editor--Short"
          >
          </vue-editor>
        </div>
        <button @click="saveSetting(setting, 'basicInfo')" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus'; 
import ClipBoardJS from 'clipboard'
import api from '../../../api'
import settingWithImage from '../../commons/settingWithImage'
import { mapState, mapActions, mapGetters } from 'vuex'



export default {
  name: 'Login',
  props: {
   settings: Object,
   features: Array
  },
  components: {
    settingWithImage,
    VueEditor
  },
  data() {
    return {
      registrationOptions: [
        {label: 'Join by invitation', value: '1'}, 
        {label: 'Open Registration', value: '2'},
        {label: 'Approve/Decline', value: '3'}
      ],
      registrationValue: Number,
      customToolbar: [["bold", "italic", "underline"], ["link"], ["clean"]],
      textLoginMax: 400,
      invite_link: `${window.location.origin}/users/set-subscription`,
      inherit: "",
      selectOne: false
    }
  },

  mounted() {    
    setTimeout(() => {
      this.registrationValue = this.settings.registrationInfos?.registration_type?.value
    }, 100);
  },
  computed: {
    registrationType(){
      let currentRegistrationType = this.settings.registrationInfos.registration_type.value
      return this.registrationOptions.filter(option => option.value === currentRegistrationType)
    },
  },
  methods: {
    saveSetting(setting, settingType, reload, apply) {
      this.$emit('saveSet', '', setting, settingType, reload, apply);
    },
    updateSelected (setting, selection) {
      setting.value = selection.value;
    },
    async saveFeature(setting) {
      try {
        const res = await api.Feature.create(setting)
        location.reload()
      } catch (error) {
        console.error(error)
      }
     
    },

    confirmApply(setting) {
      if (this.inherit === "") {
        this.selectOne = true
      } else {
        this.saveSetting(setting, "basicInfo", false, this.inherit)
      }
    },
    
  }
}
</script>


<template>
  <div class="field">
    <p class="font-bold mb-2">{{label}}</p>
    <slot name="preview"></slot>
    <file-upload
      ref="uploadImage"
      v-model="files"
      :post-action="postAction"
      :accept="accept"
      :drop="true"
      @input-file="inputFile"
      :input-id="id"
    >
    {{t('actions.upload')}}
  </file-upload>

  <span v-for="(file, index) in files" :key="index">
    <div class="progress" v-if="file.active">
      <progressBar :progress="1" :indeterminate="true" />
    </div>
  </span>

  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
import progressBar from '../commons/progressBar'


export default {
  props: {
    id:String,
    label:String,
    postAction:String,
    accept:String,
    postData:Object,
  },
  data(){
    return {
      files: []
    }
  },
  components: {
    progressBar
  },
  methods: {
    
    inputFile(newFile, oldFile) {
      let self = this
      this.$refs.uploadImage.active = true
        if (newFile) { 
          if (!newFile.active && newFile.success && newFile.response) {
            location.reload();
          }
        }   
    },
  }


}
</script>
<template>
  <div class="Settings">
    <div class="flex mb-4">
      <BackButton :href="`/admin/academy_settings`" :cssClass="'mr-4'" />
      <h2 class="text-xl mb-2">{{t('global.pages')}}</h2>
     </div>
    <ul class="ListLined ListLined--Thin">
      <li v-for="page in pages" :key="page.id" class="Form--Boxed flex items-center justify-between mb-4">
        <a :href="`pages/${page.id}/edit`">{{ page.name.replace(/_/g, ' ') }}</a>
        <a :href="`pages/${page.id}/edit`" class="Btn Btn--Primary Btn--Icon">
          <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-arrow-right"></use>
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import BackButton from '../../commons/backButton'

export default {
  props: {
    pages: Array
  },
   components: {
    BackButton
  },
}
</script>
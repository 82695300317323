//list of components I want in the page
const components = [
  "hamburger",
  "cookie-helper",
  "loader",
  "tooltip",
  "vimeo",
  "modal",
  "disable-turbolinks",
  "links",
  "clipboard",
  "check-multiple-sign-in",
];

document.addEventListener("DOMContentLoaded", () => {
  //cycle the array for dynamically display them;
  for (let component of components) {
    import(
      /* webpackChunkName: `components-[request]` */ `./components/${component}`
    )
      .then((module) => {
        let DynamicComponent = module.default; // we except that the component is the the default attribute of the module
        let dynamicComponent = new DynamicComponent(component);
        dynamicComponent.render();
      })
      .catch((error) => {
        console.error("Failed to load the component", error.stack);
      });
  }
});

<template>
  <div class="LectureList__Wrapper">
   <h2 class="mb-2 text-lg">Guarda le statistiche relative ad ogni lezione:</h2>
   <ul class="List--Boxed mt-4" v-if="course.path">
      <li v-for="lecture in course.related_courses" :key="lecture.id" class="LectureItem" :id="lecture.id" :class="[(lecture.header ? 'SectionItem bg-grayScale-light' : 'LectureItem'), 'flex items-center justify-between mb-1 p-1']" >
        <div>
          <span class="Tag" v-if="!lecture.header">{{t(`global.${type(lecture)}.s`)}}</span>
           <span>{{ lecture.name }}</span>
        </div>
        <div class="flex">
          <!-- <span v-if="!lecture.header">Completed by <button @click="selectLecture(lecture)" class="Btn Btn--Primary Btn--Icon">{{ lecture.stats_tot_completed_size}} users</button></span> -->
          <div class="text-right ml-8" v-if="!lecture.header">
            <a :href="type(lecture) === 'lectures' ? `lectures/${lecture.id}/stats` : `../../courses/${lecture.id}/stats`" class="Btn Btn--Primary Btn--Icon">
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
          </div>
        </div>
        
      </li>
    </ul>
    <ul class="List--Boxed mt-4" v-else >
      <li v-for="lecture in course.lectures" :key="lecture.id" class="LectureItem" :id="lecture.id" :class="[(lecture.header ? 'SectionItem bg-grayScale-light' : 'LectureItem'), 'flex items-center justify-between mb-1 p-1']" >
        <div>
          <span>{{ lecture.name }}</span>
        </div>
        <div class="flex">
          <!-- <span v-if="!lecture.header">Completed by <button @click="selectLecture(lecture)" class="Btn Btn--Primary Btn--Icon">{{ lecture.stats_tot_completed_size}} users</button></span> -->
          <div class="text-right ml-8" v-if="!lecture.header">
            <a :href="`lectures/${lecture.id}/stats`" class="Btn Btn--Primary Btn--Icon">
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
          </div>
        </div>
      </li>
    </ul>

    <!-- <modal name="confirm-modal" height="auto">
      <div class="v--modal--header p-4">
          <p class="mr-2">Users who completed <span class="text-xl font-bold">{{selectedLecture.name}}:</span></p> 
      </div>
      <div class="v--modal--body body--users p-4">
        <ul class="UsersList List--Lined">
          <li v-for="(completed, index) in selectedLecture.stats_tot_completed" :key="index" class="UsersList__Item UsersList__Item--5 ">
            <div class="UserList__Item__Name">
              {{ completed.user.full_name }}
            </div>
            <div> 
              {{ completed.user.email }}
            </div>
            <div class="text-right">
              <a :href="`/admin/users/${completed.user.id}`" class="Btn Btn--Primary Btn--Icon">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide('confirm-modal'), selectedLecture = {}">Chiudi</button>
      </div>
    </modal> -->

  </div>
</template>

<script>
export default {
  props: {
    course: Object,
    totEnrolls: Number
  },
  data(){
    return {
      selectedLecture: {}
    }
  },
  methods: {
    selectLecture(lecture) {
      this.selectedLecture = lecture;
      this.$modal.show('confirm-modal');
    },
    type(el) {
      return `${el.course ? 'courses' : 'lectures'}`
    },
  }
}
</script>
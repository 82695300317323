import api from "./instance";

/**
 * add area
 */
const create = (params) =>
  api.post(`/admin/areas`, params).then((res) => res.data);

const getAreasApi = () => api.get(`/admin/areas`).then((res) => res.data);

export default {
  create,
  getAreasApi,
};

import api from '../../api'

const defaultState = {
  contents: [],
  lectureQuiz: null
}

export const actions = {
  fillContents({ commit }, contents) {
    commit('fillContents', contents)
  },

  addContent({ commit }, content) {
    commit('contentAdded', content)
  },

  deleteContent({ commit }, content) {
    api.Content.destroy(content)
    .then(() => {
      commit('contentDeleted', content)
    })
  },

  createLectureQuiz({commit}, quiz) {
    commit('quizCreated', quiz)
  },
  renderQuiz({ commit }, quiz) {
    commit('renderQuiz', quiz)
  },


}

export const mutations = {
  fillContents(state, contents) {
    state.contents = contents
  },

  contentAdded(state, content) {
    state.contents.push(content)
  },

  contentDeleted(state, content) {
    state.contents = state.contents.filter(item => item.id !== content.id)
  },
  quizCreated(state, quiz) {
    state.lectureQuiz = quiz
  },

  renderQuiz(state, quiz) {
    state.lectureQuiz = quiz
  }

}


const getters = {
  lectureQuiz(state) {
    return state.lectureQuiz
  }
}

export default {
  state: defaultState,
  actions,
  mutations,
  getters
}
<template>
  <div>
    <div class="Form--Boxed mb-4">
        <h3 class="mb-2">{{t('settings.edit_pages')}}</h3>
        <p class="text-og mb-4">{{ t('settings.edit_pages_hint') }}</p>
        <a  class="Btn Btn--Save" href="/admin/pages">{{t('settings.go_to_pages')}}</a>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  props: {
   settings: Object
  },
  methods: {
   
  }
}
</script>
<template>
  <div>

    <div v-if="timeToStart < -(60*60*3) " class="text-center">
      <h4 class="text-2xl mb-4">Il meeting è terminato</h4>
    </div>

    <div v-else>

      <countdown 
        v-if="timeToStart > 0"
      :time="new Date(lecture.start_date).getTime() - new Date().getTime()">
      <template slot-scope="props">
        <div class="CountDown">
        <div id="timer">
          <div class="days"> <div class="numbers">{{ props.days }}</div>D</div> 
          <div class="hours"> <div class="numbers">{{ props.hours }}</div>H</div> 
          <div class="minutes"><div class="numbers"> {{ props.minutes }}</div>M</div> 
          <div class="seconds"><div class="numbers">{{ props.seconds }}</div>S</div> </div>
          <h3 class="text-2xl mt-8">LIVE: {{lecture.start_date | moment("D/M/YY k:mm") }}</h3>
        </div>
      </template>
      </countdown>

      <div v-if="timeToStart < 300" class="text-center">
        <h4 v-if="timeToStart < 0" class="text-2xl mb-4">Meeting in corso</h4>
        <h4 v-else class="text-2xl mb-4">Il Meeting sta per iniziare</h4>
        <p class="mb-4">Per partecipare clicca il bottone di seguito</p>
        <a :href="`https://us02web.zoom.us/j/${content.content_code}`" class="Btn Btn--Primary" target="_blank">Partecipa</a>
      </div>

    </div>




  </div>
</template>

<script>
export default {
  props: {
    lecture: Object,
    content: Object
  },
  data() {
    return {
      timeToStart: Number
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
  },
  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
  }
}
</script>
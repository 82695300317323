import api from "./instance";

const getBadges = (userId, params) =>
  api
    .get(`/users/${userId}/badges.json`, { params })
    .then((response) => response.data);

const updateBadge = (id, params) =>
  api
    .put(`/admin/gamification/badges/${id}`, { badge: params })
    .then((response) => response.data);

const updateTranslation = (id, params) =>
  api
    .put(`/admin/gamification/badges/${id}/update_translation`, params)
    .then((response) => response.data);

const getCourses = (params) =>
  api
    .get("/admin/badges/courses", { params })
    .then((response) => response.data);

export default {
  getBadges,
  updateBadge,
  updateTranslation,
  getCourses
};
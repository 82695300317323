<template>
  <div>
    <div class="Form--Boxed">
      <div class="field mb-10" v-if="settings.banner_text">
        <label for="banner_text">{{t('settings.hero_text')}}</label>
        <input type="text" id="banner_text"  class="mb-2" v-model="settings.banner_text.value">
        <button @click="saveSetting(settings.banner_text, 'banner_text')" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
      </div>
      <div class="field mb-10" v-if="settings.banner_text2">
        <label for="banner_text2">{{t('settings.hero_text2')}}</label>
        <vue-editor 
          id="banner_text2"
          v-model="settings.banner_text2.value" 
          :editorToolbar="customToolbar"
          class="mb-4 Editor--Short"
        >
        </vue-editor>
        <button @click="saveSetting(settings.banner_text2, 'banner_text2')" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
      </div>
      <div class="">
        <settingWithImage :setting="settings.cover_image" settingClass="academySetting" settingType="cover_image" :name="t('settings.hero_image')" />
      </div>
    </div> 
  </div>
</template>

<script>
import settingWithImage from '../../commons/settingWithImage'
import { VueEditor } from "vue2-editor";



export default {
  name: 'Home',
  props: {
   settings: Object
  },
  components: {
    settingWithImage,
    VueEditor
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        ["link"], ["clean"]
      ]
    }
  },
  methods: {
    saveSetting(setting, settingType, reload) {
      this.$emit('saveSet', '', setting, settingType, reload);
    }
  }
}
</script>
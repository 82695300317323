<template>
  <div>
    <!--
    <div role="alert" class="border-l-4 border-warning text-warning px-4 my-4">
      <p class="font-bold">La lezione è stata creata dall'account "{{ content.user }}"</p> 
        <p class="mb-2">Assicurati che l'account zoom con il quale hai creato la lezione, sia stato autenticato da "{{ content.user }}" nelle impostazioni.</p>
    </div>
     --> 
    <p class="mb-4">
      <strong>Zoom meeting id:</strong>
      <a :href="`https://zoom.us/meeting/${content.content_code}`" target="_blank">
        {{ content.content_code }}
      </a>
    </p>
    <a :href="`https://zoom.us/s/${content.content_code}`" class="Btn Btn--Primary" target="_blank">Start Meeting</a>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  props: {
    lecture: Object,
    content: Object
  },
  data() {
    return {
      timeToStart: Number
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
  },
  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
  }
}
</script>
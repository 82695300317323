import api from "./instance";

/**
 * index comments
 */
const index = (course, lecture) =>
  lecture
    ? api
        .get(`/comments?lecture_id=${lecture.id}`)
        .then((response) => response.data)
    : api
        .get(`/comments?course_id=${course.id}`)
        .then((response) => response.data);

/**
 * create comment
 */
const create = (comment) =>
  api.post(`/comments`, comment).then((response) => response.data);

/**
 * show comment
 */
const show = (comment) =>
  api.get(`/comments/${comment.id}`).then((response) => response.data);

/**
 * update comment
 */
const update = (id, comment) =>
  api.put(`/comments/${id}`, comment).then((response) => response.data);

/**
 * destroy comment
 */
const destroy = (comment) =>
  api.delete(`/comments/${comment.id}`).then((response) => response.data);

export default {
  index,
  create,
  show,
  update,
  destroy,
};

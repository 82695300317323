var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"Box Box--Clear mb-2"},[_c('h4',{staticClass:"text-xl mb-4 flex items-center justify-between"},[_vm._v("Recap")]),_vm._v(" "),_c('div',{staticClass:"sm:grid grid-cols-2 gap-3"},[_c('div',[_vm._m(0),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.courses))])]),_vm._v(" "),_c('div',[_vm._m(1),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.users))])]),_vm._v(" "),_c('div',[_vm._m(2),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.last_activity || 'Nessuna attività'))])]),_vm._v(" "),_c('div',[_vm._m(3),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.expiry_date || 'Non presente'))])]),_vm._v(" "),_c('div',[_vm._m(4),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.catalog_subscription || 'No'))])]),_vm._v(" "),_c('div',[_vm._m(5),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.live_subscription || 'No'))])]),_vm._v(" "),_c('div',[_vm._m(6),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.free_courses_subscription || 'No'))])]),_vm._v(" "),_c('div',[_vm._m(7),_vm._v(" "),_c('p',{},[_vm._v(_vm._s(_vm.academy.tos_needed || 'No'))])])]),_vm._v(" "),_c('div',{staticClass:"text-right mt-4"},[_c('a',{staticClass:"Btn Btn--Edit",attrs:{"href":`/admin/${_vm.academy.name}/edit`}},[_vm._v(_vm._s(_vm.t('actions.edit')))])])]),_vm._v(" "),_c('CourseList',{attrs:{"tenant":_vm.tenant,"type":"courses"}}),_vm._v(" "),_c('CourseList',{attrs:{"tenant":_vm.tenant,"type":"paths"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Corsi:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Utenti:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Ultima attività:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Scadenza contratto:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Abbonamento al catalogo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Abbonamento ai live:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Abbonamento ai corsi gratuiti:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Schermata accettazione TOS abilitata:")])])
}]

export { render, staticRenderFns }
import api from './instance'

/**
* Create
*/
const update = (setting) => (
  api.put(`/user_settings/${setting.id}`, setting )
  .then(response => response.data)
)

/**
* Create
*/
const remove = (setting) => (
  api.delete(`/user_settings/${setting.id}` )
  .then(response => response.data)
)

export default {
  update,
  remove
}
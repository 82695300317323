<template>
  <div>
      <loading-progress
        :progress="progress"
				:indeterminate="indeterminate"
				:hide-background="false"
				shape="line"
				size="200"
				width="200"
				height="6"
        :fill-duration="5"
      />

  </div>
</template>

<script>
export default {
  props: {
    progress: Number,
    indeterminate: Boolean
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div v-if="hasPaths || hasCourses" >
      <div class="Tabs mb-8 mt-8">
        <div class="Tabs__Underlined mb-2">
          <template v-if="divideByArea">
            <button v-for="area in homeAreas" :class="['sm:text-xl ', {'selected': activeArea === area.code}]" @click="activeArea = area.code"  >
            {{ area.name }}
            </button>
          </template>
          <template v-else>
            <button :class="['sm:text-2xl ', {'selected': activeType === 'path'}]" @click="activeType = 'path'" v-if="hasPaths" >
            {{ t('pages.home.your_paths') }}
            </button>
            <button :class="['sm:text-2xl ', {'selected': activeType === 'course'}]" @click="activeType = 'course'" v-if="hasCourses">
              {{ t('pages.home.your_courses') }}
              </button>
          </template>
        </div>
        <div class="md:flex mb-4 justify-between gap-4">
          <div class="flex flex-1">
            <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_undefined`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
            <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
          </div>
          <div class="Dropdown mt-2 md:mt-0">
            <button :class="['Dropdown__Trigger w-full', {open: orderByOpen}]" @click="toggleOrder">
              {{t('filter.order')}}
              <svg height="18" width="18" class="fill-current ml-4">
                  <use :xlink:href="`#icon-cheveron-${orderByOpen ? 'up' : 'down'}`"></use>
                </svg>
            </button>
            <transition name="fade">
              <div class="Dropdown__Body orderBy" v-if="orderByOpen">
                <button v-for="order in orderOptions" :key="order.value" :class="['Btn Btn--Filter w-full mb-2', {active: selectedOrder === order.value}]" @click="selectOrderBy(order.value)" >
                  {{t(`filter.${order.label}`)}}
                </button>
                <div class="text-right">
                  <button class="Btn Btn--Save" @click="saveOrder">{{t('actions.save')}}</button>
                </div>
              </div>
            </transition>
          </div>
          <div class="Dropdown mt-2 md:mt-0">
            <button :class="['Dropdown__Trigger w-full', {open: filtersOpen}]" @click="toggleFilter">
              {{t('filter.title')}}
              <svg height="18" width="18" class="fill-current ml-4"> 
                  <use :xlink:href="`#icon-cheveron-${filtersOpen ? 'up' : 'down'}`"></use>
                </svg>
            </button>
            <transition name="fade">
              <div class="Dropdown__Body filters" v-if="filtersOpen"> 
                <template   v-if="subjects.length > 0">
                  <label class="text-grayScale-dark text-lg mb-2 block font-semibold">{{t('filter.by_subject')}}</label>
                  <div class="flex flex-wrap gap-2 mb-4">
                    <button v-for="area in subjects" :key="area.id" @click="addArea(area)" :class="['Btn Btn--Filter', {active: selectedAreas.includes(area.id)}]">
                          {{area.name}}
                    </button>
                  </div>
                </template>
                <label class="text-grayScale-dark text-lg mb-2 block font-semibold">{{t('filter.progress')}}</label>
                  <div class="flex flex-wrap gap-2 mb-4">
                    <button v-for="progress in statusOptions" :key="progress.id" @click="status = progress.value" :class="['Btn Btn--Filter', {active: status === progress.value}]">
                      {{t(`pages.home.${progress.label}`)}}
                    </button>
                  </div>
                <div class="text-right">
                  <button class="Btn--Text text-danger mr-2" @click="removeFilters">{{t('filter.remove')}}</button>
                  <button class="Btn Btn--Save" @click="saveFilters()">{{t('actions.save')}}</button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div v-if="loading" class="LoadingBox mt-4 text-center">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
      <div v-else-if="error != ''" class="Box Box--Clear">
        <p class="text-danger">{{error}}</p>
      </div>
      <div v-else-if="divideByArea" >
        <div  v-for="courseArea in courseAreas" v-if="courseArea.area.code == activeArea">
          <ul class="Courses__List grid sm:grid-cols-2 gap-4 mb-6">
            <li v-for="course in courseArea.courses" :key="course.id">
              <courseThumbnail :course="course"  :user="user" /> 
            </li>
           </ul>
        </div>
    
      </div>
      <div v-else>
        <template v-if="activeType === 'course'">
          <div v-if="onlyCourses.length > 0">
            <ul class="Courses__List grid sm:grid-cols-2 gap-4 mb-6">
              <li v-for="course in onlyCourses" :key="course.id">
                <courseThumbnail :course="course"  :user="user" /> 
              </li>
            </ul>
            <div class="text-center mt-2">
              <paginate
                v-model="ocPpage"
                :page-count="ocPagy.pages"
                :click-handler="ocPageCallback"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'PaginationList'">
              </paginate>
            </div>
          </div>
          <div v-else class="mt-4 text-center">
            <p>{{t('pages.courses.no_courses')}}</p>
          </div>
        </template>
        <template v-if="activeType === 'path'">
          <div v-if="onlyPaths.length > 0">
            <ul class="Courses__List grid sm:grid-cols-2 gap-4 mb-6">
              <li v-for="course in onlyPaths" :key="course.id">
                <courseThumbnail :course="course"  :user="user" /> 
              </li>
            </ul>
            <div class="text-center mt-2">
              <paginate
                v-model="opPpage"
                :page-count="opPagy.pages"
                :click-handler="opPageCallback"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'PaginationList'">
              </paginate>
            </div>
          </div>
          <div v-else class="mt-4 text-center">
            <p>{{t('pages.courses.no_paths')}}</p>
          </div>
          </template>
      </div>
    </div>
    <div class="Courses__Empty Box Box--Clear text-center mt-8" v-else>
      <p class="font-bold text-xl">{{t('pages.home.courses_empty')}}</p>
      <img src="../../images/illustrations/books.png" class="w-full"/>
    </div>
  </div>
 
</template>

<script>

import courseThumbnail from '../commons/courseThumbnail'
import { mapState, mapActions } from 'vuex'
import api from '../../api'

  export default {
    name: 'CourseList',

    props: {
      areas: Array,
      hasPaths: Boolean,
      hasCourses: Boolean,
      lang: String,
      divideByArea: Boolean,
    }, 
  
    components: {
      courseThumbnail
    },

    data() {
      return {
        activeType: this.hasPaths ? 'path' : 'course',
        activeArea: '',
        courseList: this.courses,
        courseAreas: [],
        ocPage: 1,
        opPage: 1,
        courses: [],
        loading: false,
        ocPagy: {},
        opPagy: {},
        search: '',
        error: '',
        filtersOpen: false,
        orderByOpen: false,
        selectedTypes: [],
        selectedAreas: [],
        selectedOrder: 'az',
        orderOptions: [
          {
            label: "a_z",
            value: "az"
          },
          {
            label: "z_a",
            value: "za"
          }
        ],
        statusOptions: [
          {
            label: "not_started",
            value: "not_started"
          },
          {
            label: "in_progress",
            value: "in_progress"
          },
          {
            label: "completed_pl",
            value: "completed"
          }
        ],
        courseCompleted: [],
        courseNotStarted: [],
        courseInProgress: [],
        pathCompleted: [],
        pathNotStarted: [],
        pathInProgress: [],
        status: []
      }
    },
    
    mounted() {
      this.getCourses()
      this.getPaths()
      this.getAllCoursesByArea()
      this.setActiveArea()
    },

    computed: {
      ...mapState({ 
        allCourses: state => state.course.allCourses,
        onlyCourses: state => state.course.onlyCourses,
        onlyPaths: state => state.course.onlyPaths
      }),

      subjects() {
        return this.areas.filter(el => el.area_type === 'subject' || el.area_type === 'home')
      },

      pathPresent() {
        return this.courses.filter(course => {
          return course.path
        }).length > 0
      },

      coursePresent() {
        return this.courses.filter(course => {
          return !course.path
        }).length > 0
      },

      allCoursesByArea() {
        return this.allCourses.filter(course => {
          //return true if this.activeArea is in array course areas
          return course.areas.map(area => area.code).includes(this.activeArea)
        })
      },

      homeAreas() {
        return this.areas.filter(area => {
          //get area.area_type === 'home' and order by updated_ar
          return area.area_type === 'home'
        }).sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at)
        
        })
      },



    },

    methods: {

      ...mapActions({
        getAllCourses: 'getAllCourses',
        getOnlyCourses: 'getOnlyCourses',
        getOnlyPaths: 'getOnlyPaths'
      }),

      setActiveArea() {
        if (this.homeAreas.length > 0) {
          this.activeArea = this.homeAreas[0].code
        }
      },

      getAllCoursesByArea() {
        this.homeAreas.forEach(area => {
          api.Course.getUserCourses({
            page: 1,
            search: this.search, 
            order: this.selectedOrder,
            areas: [area.id],
            status: this.status,
            noPaginate: true,
            lang: this.lang
          } ).then(res => {
             this.courseAreas.push({area: area, courses: res.courses})
          }).catch( err => {
            this.error = err.message
          })
        })
      },

      getCoursesFromSearch(){
        this.ocPage = 1
        this.getCourses()
        this.getPaths()
      },

      
      getCourses() {
        console.log(this.selectedAreas)
        this.loading = true
        api.Course.getUserCourses({
          page: this.ocPage,
          search: this.search, 
          order: this.selectedOrder,
          areas: this.selectedAreas,
          type: 'courses',
          status: this.status,
          lang: this.lang
        } ).then(res => {
            this.getOnlyCourses(res.courses)
          this.ocPagy = res.pagy
          this.loading = false
          this.filtersOpen = false
          this.orderByOpen = false
        }).catch( err => {
          this.error = err.message
          this.loading = false
        })
      },

      getPaths() {
        console.log(this.selectedAreas)
        this.loading = true
        api.Course.getUserCourses({
          page: this.opPage,
          search: this.search, 
          order: this.selectedOrder,
          areas: this.selectedAreas,
          type: 'paths',
          status: this.status,
          lang: this.lang
        } ).then(res => {
          this.getOnlyPaths(res.courses)
          this.opPagy = res.pagy
          this.loading = false
          this.filtersOpen = false
          this.orderByOpen = false
        }).catch( err => {
          this.error = err.message
          this.loading = false
        })
      },

      toggleOrder() {
      this.orderByOpen = !this.orderByOpen
      this.filtersOpen = false
      },

      toggleFilter() {
        this.filtersOpen = !this.filtersOpen
        this.orderByOpen = false
      },
       ocPageCallback(pageNum){
        if (pageNum != undefined) {
          this.ocPage = pageNum
        }
        this.getCourses()
      },
      opPageCallback(pageNum){
        if (pageNum != undefined) {
          this.opPage = pageNum
        }
        this.getPaths()
      },
      addArea(area) {
        if (this.selectedAreas.indexOf(area.id) === -1 ) {
          this.selectedAreas.push(area.id)
        } else {
          this.selectedAreas.splice(this.selectedAreas.indexOf(area.id), 1)
        }
      },

      selectOrderBy(value) {
        this.selectedOrder = value
      },

      saveFilters() {
        this.getPaths()
        this.getCourses()
      },
      
      saveOrder() {
        this.getPaths()
        this.getCourses()
      },

      removeFilters() {
        this.selectedAreas = [],
        this.status = ""
      }

    }


  }

</script>

<style lang="scss" scoped>
  
</style>

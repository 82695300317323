import api from './instance'

/**
* update style
*/
const update = (style) => (
  api.put(`/admin/styles/${style.id}`, { 'style': { value: style.value.hex } })
    .then(response => response.data)
)

export default {
  update
}